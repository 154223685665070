<template>
  <el-table-column
    :prop="col.value"
    :label="col.name"
    align="center"
    show-overflow-tooltip
  >
    <template v-if="col.children != null" #default>
      <tableColumn
        v-for="(item, key) in col.children"
        :key="key"
        :col="item"
        :tableData="tableData"
      ></tableColumn>
    </template>
    <!-- <template
      v-if="col.children == null || col.children.length == 0"
      slot-scope="scope"
    >
      <div v-if="col.name != '正态图分析'">
        {{ scope.row[col.value] }}
      </div>
      <div v-if="col.name == '正态图分析'">
        <bar-line-chart
          :chartInfo="scope.row[col.value]"
          :ref="'barLineChart' + scope.$index"
        ></bar-line-chart>
      </div>
    </template> -->
  </el-table-column>
</template>

<script>
// import BarLineChart from "../chart/stateChart.vue";
export default {
  name: "tableColumn",
  props: {
    col: {
      type: Object
    },
    tableData: {
      tableColumns: {
        type: Array
      },
      tableTitles: {
        type: Array
      }
    }
  },
  components: {
    // BarLineChart
  },
  data() {
    return {};
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.tableData.tableColumns.forEach((val, index) => {
    //     let ref = "barLineChart" + index;
    //     if (this.$refs[ref]) {
    //       this.$refs[ref].initChartView();
    //     }
    //   });
    // });
  }
};
</script>

<style scoped lang="scss">
.el-pagination {
  text-align: center;
}
</style>
