<!-- 文本库树形选择弹窗 只负责提供选中操作-->
<template>
  <el-dialog
    class="dialog"
    v-model="DLGVisible"
    :title="DLGTitle"
    :before-close="closeDLG"
  >
    <el-tree
      ref="treeRef"
      :data="fileTree"
      :props="treeProps"
      node-key="id"
      :check-strictly="true"
      :default-expanded-keys="[0]"
      highlight-current
      accordion
      draggable
      show-checkbox
      @check-change="handleChangeCheck"
      v-loading="loadingVisible"
    >
      <template #default="{ node, data }">
        <div v-if="data.ifEdit">
          <el-input
            placeholder="请输入文件名"
            v-model="data.name"
            size="small"
            :autofocus="true"
            @keyup.enter="handleClickConfirmFolderName(node, data)"
          ></el-input>
          <el-icon
            class="icon-check"
            @click.stop="handleClickConfirmFolderName(node, data)"
          >
            <Check />
          </el-icon>
          <el-icon
            class="icon-close"
            @click.stop="handleCancelConfirmFolderName(node, data)"
          >
            <Close />
          </el-icon>
        </div>
        <div v-else>
          <i class="iconfont icon-fill-folder list-item-icon"></i>
          <span>{{ node.label }}</span>
        </div>
      </template>
    </el-tree>
    <template #footer>
      <span class="dialog-footer">
        <div class="left-btn-box">
          <el-button @click="handleClickAddFolder">新建文件夹</el-button>
        </div>
        <div class="right-btn-box">
          <el-button @click="closeDLG">取消</el-button>
          <el-button
            type="primary"
            @click="handleClickConfirm"
            :disabled="!ifSelectFolder"
          >
            确定
          </el-button>
        </div>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  defineExpose,
  onMounted,
  computed
} from "vue";
import { httpPost } from "@/api/httpService.js";
import { ElLoading, ElMessage } from "element-plus";
import { Debounce } from "@/utils/utils";
import { CONTENT_TYPE } from "../../../constant/text_library";
import Folder from "@/class/Folder.js";
import {
  ERROR_CODE_NAME_DUPLICATE,
  PARAM_VALID_ERROR
} from "@/constant/error_code.js";
const emits = defineEmits(["refresh"]);
// 弹窗相关
const DLGVisible = ref(false);
const DLGTitle = computed(() => {
  let result = "";
  switch (type.value) {
    case "COPY":
      result = "复制到";
      break;
    case "MOVE":
      result = "移动到";
      break;
    default:
      result = "";
  }
  return result;
});

// 弹窗loading
const loadingVisible = ref(false);
const showLoading = () => {
  loadingVisible.value = true;
};
const hideLoading = () => {
  loadingVisible.value = false;
};

const type = ref(""); //类型
const copiedLibrary = ref({}); // 要复制的文本库的信息
const movedItemList = ref([]); // 被移动的文本库/文件夹列表
// 初始化
const init = async (params) => {
  showLoading();
  type.value = params.type;
  copiedLibrary.value = params.copiedLibrary;
  movedItemList.value = params.movedItemList;
  await getFileTree();
  hideLoading();
};
const openDLG = (params) => {
  console.log("open tree", params);
  DLGVisible.value = true;
  init(params);
};
const closeDLG = () => {
  DLGVisible.value = false;
};

const refreshParent = () => {
  emits("refresh");
};

// 点击确认
const handleClickConfirm = Debounce(() => {
  if (!ifSelectFolder.value) {
    ElMessage.warning("请先选择文件夹");
    return;
  }
  const loadingInstance = ElLoading.service({
    text: "请稍等……",
    background: "rgba(248, 248, 250, 0.6)"
  });
  switch (type.value) {
    case "COPY":
      copyToFolder();
      break;
    case "MOVE":
      moveToFolder();
      break;
    default:
  }
  loadingInstance.close();
});
// 复制到文件夹
const copyToFolder = async () => {
  let url = "/view/analysis/duplicate/analysis";
  let params = {
    id: copiedLibrary.value.id,
    name: copiedLibrary.value.name,
    parentFolderId: selectedFolderId.value,
    ifChildFolder:
      copiedLibrary.value.contentType == CONTENT_TYPE.FOLDER ? 1 : 0
  };
  let res = await httpPost(url, params);
  if (res.code == 0) {
    refreshParent();
    ElMessage.success(res.message);
    closeDLG();
  } else if (res.code == ERROR_CODE_NAME_DUPLICATE) {
    ElMessage.warning(res.message);
  }
};
// 移动到文件夹
const moveToFolder = async () => {
  let url = "/view/analysis/update/relation";
  let params = {
    parentFolderId: selectedFolderId.value,
    childIdList: movedItemList.value.map((item) => item.id),
    ifChildFolderList: movedItemList.value.map((item) =>
      item.contentType == CONTENT_TYPE.FOLDER ? 1 : 0
    )
  };
  let res = await httpPost(url, params);
  if (res.code == 0) {
    refreshParent();
    ElMessage.success(res.message);
    closeDLG();
  } else if (res.code == ERROR_CODE_NAME_DUPLICATE) {
    ElMessage.warning(res.message);
  }
};

// 文件夹树形结构
const treeRef = ref(null);
const fileTree = ref(null); //数据
const ROOT_FOLDER_ID = 0;
const getFileTree = async () => {
  let url = "/view/analysis/get/structure";
  let res = await httpPost(url);
  fileTree.value = [res.data];
  return res;
};
const treeProps = ref({
  children: "children",
  label: "name"
}); // 树形组件所需props，用于映射属性
const selectedFolderId = ref(null); //选中文件夹的id
const ifSelectFolder = computed(() => {
  if (!isNaN(selectedFolderId.value)) {
    return true;
  } else {
    return false;
  }
});
const handleChangeCheck = (data, checked, node) => {
  console.log("handlecheck", data, checked, treeRef.value.getCheckedNodes());
  if (treeRef.value.getCheckedNodes().length == 0) {
    selectedFolderId.value = null;
    return;
  }
  if (checked) {
    if (data.name == "公共文本库") {
      ElMessage.warning(`不支持${DLGTitle.value}公共文本库，请重新选择。`);
      treeRef.value.setCheckedNodes([]);
      return;
    }
    treeRef.value.setCheckedNodes([data]);
    selectedFolderId.value = data.id;
    // }
  }
};
//点击新建文件夹
const handleClickAddFolder = () => {
  let currentNode =
    treeRef.value.getCurrentNode() ||
    treeRef.value.getNode(ROOT_FOLDER_ID).data; // 获取当前高亮的文件夹，默认为根文件夹
  console.log("click add folder", currentNode, treeRef.value);
  expandNode(currentNode.id);
  currentNode.children.push(
    new Folder({ parentFolderId: currentNode.id, ifEdit: true })
  );
};
// 点击确认新建文件夹
const handleClickConfirmFolderName = async (node, data) => {
  console.log("click confirm", node, data);
  let params = {
    ...data,
    ifChildFolder: 1
  };
  await saveTextLibraryOrFolder(params);
  await getFileTree();
  expandNode(data.parentFolderId);
};
// 保存文本库/文件夹
const saveTextLibraryOrFolder = async (params) => {
  let url = "/view/analysis/save";
  let res = await httpPost(url, params);
  if (res.code == 0) {
    ElMessage.success("保存成功");
  }
  return res;
};
// 点击取消新建文件夹
const handleCancelConfirmFolderName = (node, data) => {
  console.log("click cancel", node, data);
};
//展开指定id的节点
const expandNode = (id) => {
  treeRef.value.store.nodesMap[id].expanded = true;
};
onMounted(() => {
  getFileTree();
});
defineExpose({ openDLG, closeDLG });
</script>
<style lang="scss" scoped>
.dialog {
  .dialog-footer {
    display: flex;
    justify-content: space-between;
  }

  .icon {
    margin-left: 8px;
    font-size: 18px;
    vertical-align: middle;
    @include pointer();
  }

  .icon-check {
    @extend .icon;
    color: $success-color;
  }

  .icon-close {
    @extend .icon;
    color: $primary-color;
  }

  .icon-fill-folder {
    color: $folder-icon-color;
  }
}
</style>
