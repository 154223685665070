<template>
  <!-- <div v-if="tableDataResult.tableType == 3"></div> -->
  <!-- 共线表 -->
  <div class="searchBar">
    <el-form
      v-if="tableDataResult.tableType == CO_OCCURRENCE_PAGE_TABLE"
      :inline="true"
      :model="cooccurrenceForm"
      class="demo-form-inline"
    >
      <el-form-item label="基准词语" size="medium" style="width: 380px">
        <el-input
          v-model="cooccurrenceForm.firstKeyword"
          placeholder="同时检索多个关键词，请用逗号或空格分隔"
        ></el-input>
      </el-form-item>
      <el-form-item label="比对词语" size="medium" style="width: 380px">
        <el-input
          v-model="cooccurrenceForm.secondKeyword"
          placeholder="同时检索多个关键词，请用逗号或空格分隔"
        ></el-input>
      </el-form-item>

      <el-form-item size="medium" style="width: 68px">
        <el-button type="primary" @click="handleCurrentChange(1)">
          查询
        </el-button>
      </el-form-item>
    </el-form>
  </div>
  <!-- {{ tableDataResult.tableType }} -->
  <!-- 常规表 -->
  <table-component
    v-if="
      tableDataResult.tableType == PAGE_TABLE ||
      tableDataResult.tableType == CO_OCCURRENCE_PAGE_TABLE
    "
    :tableData="tableDataResult"
    :hasPagination="true"
    @handleCurrentChange="handleCurrentChange"
  ></table-component>
  <!-- 主题概率表 -->
  <topic-probability-table
    v-if="tableDataResult.tableType == TOPIC_PROBABILITY_PAGE_TABLE"
    :tableData="tableDataResult"
    :modelInfo="modelInfo"
  ></topic-probability-table>
  <!-- 记录表 -->
  <record-table
    v-if="tableDataResult.tableType == RECORD_TABLE"
    :tableData="tableDataResult"
    :modelInfo="modelInfo"
    :formatter="formatter"
  ></record-table>
</template>

<script>
import { onMounted, toRefs, reactive, ref, nextTick, watch } from "vue";
import tableComponent from "@/components/table/tableComponent.vue";
import TopicProbabilityTable from "@/components/table/topicProbabilityTable.vue";
import RecordTable from "@/components/table/RecordTable.vue";
import { httpPost } from "@/api/httpService";
import { export2Excel } from "@/utils/excel";
import { ElMessage } from "element-plus";

export default {
  props: {
    tableData: Object,
    modelInfo: Object
  },
  components: { tableComponent, TopicProbabilityTable, RecordTable },
  setup(props) {
    // console.log("result table:props.tableData", props.tableData);
    // 表格类型常量
    const PAGE_TABLE = 1; //分页表格
    const TOPIC_PROBABILITY_PAGE_TABLE = 2; //主题概率表格
    const CO_OCCURRENCE_PAGE_TABLE = 3; //共线分析表格
    const RECORD_TABLE = 4; // 记录表格
    let _libraryFieldMap = reactive(props.tableData.libraryFieldMap);
    const STATIC_FIELD_MAP = {
      ____encoding_result____: "编码结果",
      ____clustering_belong_to_id____: "所属簇ID",
      ____clustering_belong_to____: "所属簇标签",
      ____categorization_result____: "分类结果",
      ____events_num____: "事件数目",
      ____events____: "事件列表",
      ____best_topic____: "概率最高主题",
      ____topic_probability_list____: "主题概率列表"
    };

    const TABLE_TYPE = reactive({
      PAGE_TABLE,
      TOPIC_PROBABILITY_PAGE_TABLE,
      CO_OCCURRENCE_PAGE_TABLE,
      RECORD_TABLE
    });
    let tHeader = [];
    let filterVal = [];

    const tableDataResult = reactive(props.tableData);
    function initTable() {
      // console.log("init table", tableDataResult.notFinish);
      if (tableDataResult.notFinish) {
        // console.log("inittable");
        let defaultTableDataResult = {
          currentPageNumber: 1,
          moduleIndex: 5,
          moduleType: 401,
          tableColumns: [
            {
              summary: "示例数据1",
              author: "示例数据1",
              publish_time: "2020-02-17 00:00:00",
              source: "示例数据1",
              title: "示例数据1",
              content: "示例数据1"
            },
            {
              summary: "示例数据2",
              author: "示例数据2",
              publish_time: "2020-02-17 00:00:00",
              source: "示例数据2",
              title: "示例数据2",
              content: "示例数据2"
            },
            {
              summary: "示例数据3",
              author: "示例数据3",
              publish_time: "2020-02-17 00:00:00",
              source: "示例数据3",
              title: "示例数据3",
              content: "示例数据3"
            },
            {
              summary: "示例数据4",
              author: "示例数据4",
              publish_time: "2020-02-17 00:00:00",
              source: "示例数据4",
              title: "示例数据4",
              content: "示例数据4"
            }
          ],
          tableTitles: [
            {
              children: [
                {
                  name: "summary",
                  value: "summary"
                },
                {
                  name: "author",
                  value: "author"
                },
                {
                  name: "publish_time",
                  value: "publish_time"
                },
                {
                  name: "source",
                  value: "source"
                },
                {
                  name: "title",
                  value: "title"
                },
                {
                  name: "content",
                  value: "content"
                }
              ],
              name: "示例表格",
              value: ""
            }
          ],
          tableType: 1,
          title: "示例表格",
          totalNumber: 5,
          totalPageNumber: 11
        };
        tableDataResult.tableType = defaultTableDataResult.tableType;
        tableDataResult.tableColumns = defaultTableDataResult.tableColumns;
        tableDataResult.tableTitles = defaultTableDataResult.tableTitles;
        tableDataResult.currentPageNumber = defaultTableDataResult;
        tableDataResult.totalNumber = defaultTableDataResult.totalNumber;
        tableDataResult.title = defaultTableDataResult.title;
        // console.log("init finish", tableDataResult);
      } else {
        tableDataResult.tableTitles.forEach((title) => {
          // 如果存在字段映射关系，就修改表头

          if (_libraryFieldMap) {
            // 分词
            if (title.value.indexOf("____tokenize") == 0) {
              // console.log(111);
              let field = title.name.slice("____tokenize_".length, -4);
              console.log("field", field);
              title.name = _libraryFieldMap[field] + "_分词结果";
            } else if (title.value.indexOf("____tendency_") == 0) {
              //情感
              // console.log(222);
              let field = title.name.slice("____tokenize_".length, -4);
              title.name = "情感倾向：" + field;
            } else {
              // console.log(333);
              title.name =
                _libraryFieldMap[title.value] ||
                STATIC_FIELD_MAP[title.value] ||
                title.value;
            }
          }
          // 初始化导出表格的表头
          if (tableDataResult.tableType == TOPIC_PROBABILITY_PAGE_TABLE) {
            tableDataResult.tableTitles.forEach((title, index) => {
              tHeader.push(title);
              filterVal.push(index);
            });
          } else {
            tHeader.push(title.name);
            filterVal.push(title.value);
          }
        });
        if (tableDataResult.tableType == RECORD_TABLE) {
          // processRecordTableData();
        }
      }
    }
    // 处理i常规表格中特殊列的数据
    function processRecordTableData() {
      tableDataResult.tableColumns.forEach((row) => {
        // 遇到__events后做特殊处理
        if (row.____events____) {
          // 存储原始格式数据
          row.____events____raw = JSON.parse(
            JSON.stringify(row.____events____)
          );
          // console.log(row.____events____);

          // row.____events____ = parseEventsToText(row.____events____raw);
        }
      });
    }
    // 将____events____字段的数据转成文本
    function parseEventsToText(events) {
      let resultStr = "";
      events.forEach((event) => {
        // console.log("event", events, event);

        let encodingStr = `【${event.encoding.join("、")}】`;
        let actorStr =
          event.actor.name.length > 0
            ? "[" + event.actor.name.join("、") + "]"
            : "";
        let actStr =
          event.act.name.length > 0
            ? "[" + event.act.name.join("、") + "]"
            : "";
        let suffererStr =
          event.sufferer.name.length > 0
            ? "[" + event.sufferer.name.join("、") + "]"
            : "";
        resultStr +=
          `${encodingStr} ${actorStr} ${actStr} ${suffererStr}` + " ";
      });
      // console.log("events", resultStr);
      return resultStr;
    }
    function handleCurrentChange(currentPage) {
      // console.log("send change currentpate", currentPage);
      let firstKeywordList = cooccurrenceForm.firstKeyword.split(" ");
      let secondKeywordList = cooccurrenceForm.secondKeyword.split(" ");
      const postData = {
        analysisId: props.modelInfo.analysisId,
        moduleIndex: props.tableData.moduleIndex,
        pageNum: currentPage,
        tableType: props.tableData.tableType,
        firstKeywordList:
          firstKeywordList.length == 0 ? secondKeywordList : firstKeywordList,
        secondKeywordList: cooccurrenceForm.secondKeyword.split(" ")
      };
      postData.firstKeywordList =
        props.tableData.tableType == CO_OCCURRENCE_PAGE_TABLE
          ? postData.firstKeywordList
          : null;
      postData.secondKeywordList =
        props.tableData.tableType == CO_OCCURRENCE_PAGE_TABLE
          ? postData.secondKeywordList
          : null;
      httpPost("/module/get/result", postData).then((res) => {
        // console.log("get page result", postData, res.data);
        let result = res.data.result;
        tableDataResult.tableColumns = result.tableContentList;
        tableDataResult.currentPageNumber = currentPage;
        tableDataResult.totalNumber = result.totalNumber;
        // console.log("tableDataResutl", tableDataResult);
      });
    }
    const cooccurrenceForm = reactive({
      firstKeyword: "",
      secondKeyword: ""
    });
    function formatter(row, column, cellValue, index) {
      if (column.property.indexOf("____tokenize_") == 0) {
        let result = cellValue.map((item) => {
          return item.name;
        });
        return result.toString();
      }
      if (column.property == "____best_topic____") {
        return "主题" + (cellValue + 1);
      }
      if (column.property == "____topic_probability_list____") {
        // console.log(column, cellValue);
        return cellValue.map((item) => Number(item).toFixed(6)).toString();
      }
      return cellValue;
    }
    async function exportResult(type) {

      // console.log("export table", type, props);
      let firstKeywordList = cooccurrenceForm.firstKeyword.split(" ");
      let secondKeywordList = cooccurrenceForm.secondKeyword.split(" ");
      const postData = {
        analysisId: props.modelInfo.analysisId,
        moduleIndex: props.tableData.moduleIndex,
        tableType: props.tableData.tableType,
        firstKeywordList:
          firstKeywordList.length == 0 ? secondKeywordList : firstKeywordList,
        secondKeywordList: cooccurrenceForm.secondKeyword.split(" ")
      };
      postData.firstKeywordList =
        props.tableData.tableType == CO_OCCURRENCE_PAGE_TABLE
          ? postData.firstKeywordList
          : null;
      postData.secondKeywordList =
        props.tableData.tableType == CO_OCCURRENCE_PAGE_TABLE
          ? postData.secondKeywordList
          : null;
      ElMessage.success({ message: "正在导出请稍后", duration: 1000 });
      let res = await httpPost("/module/get/all/result", postData);
      // console.log("get all export", res);
      if (res.code == 0) {
        // let temp = _getTempData(
        //   postData.tableType,
        //   res.data,
        //   tableDataResult.tableTitles
        // );
        // console.log("len", res.data.length);
        let wrongNum = 0;

        let temp_result = res.data;
        temp_result.forEach((item, index) => {
          // console.log(item);
          for (let field in item) {
            if (item[field] && item[field].length > 32000) {
              // console.log(index, item[field].length);
              wrongNum++;
              item[field] = "超出EXCEL单元格上限";
              // return true;
            }
          }
          // if (item.content.length > 32000) {
          //   console.log(index, item.content.length);

          //   return false;
          // }
        });
        // console.log(wrongNum);
        if (wrongNum > 0) {
          ElMessage.warning({
            message: `存在${wrongNum}条数据文本长度超出excel单元格上限，已将文本替换为 “超出EXCEL单元格上限”`,
            duration: 1500,
            showClose:true
          });
        }
        let temp = _getTempData(
          postData.tableType,
          temp_result,
          tableDataResult.tableTitles
        );

        // let temp = res.data.map((row) => {
        //   let resultRow = {};
        //   for (let colKey in row) {
        //     // 遍历列
        //     let value = row[colKey];
        //     if (colKey.indexOf("____tokenize_") == 0) {
        //       value = row[colKey]
        //         .map((item) => {
        //           return item.name;
        //         })
        //         .toString();
        //     } else if (colKey == "____best_topic____") {
        //       value = "主题" + (value + 1);
        //     }
        //     resultRow[colKey] = value;
        //   }
        //   return resultRow;
        // });
        // console.log("theader and filterval", tHeader, filterVal, temp);

        export2Excel(tHeader, filterVal, temp, props.tableData.title);
      }
    }
    // 获取导出的表格数据
    // const PAGE_TABLE = 1; //分页表格
    // const TOPIC_PROBABILITY_PAGE_TABLE = 2; //主题概率表格
    // const CO_OCCURRENCE_PAGE_TABLE = 3; //共线分析表格
    // const RECORD_TABLE = 4; // 记录表格
    function _getTempData(tableType, rawData, tableTitles) {
      switch (tableType) {
        case PAGE_TABLE:
          return _getTempPageTableData(rawData);
        case CO_OCCURRENCE_PAGE_TABLE:
          return _getTempCoOccurrencePageTableData(rawData);

        case RECORD_TABLE:
          return _getTempRecordTableData(rawData);
        case TOPIC_PROBABILITY_PAGE_TABLE: {
          tHeader = null;
          filterVal = [0];
          let l = 0;
          for (let k in rawData[0]) {
            l++;
            filterVal.push(l);
          }
          return _getTempTopicProbabilityPageTableData(tableTitles, rawData);
        }

        default:
          break;
      }
    }
    function _getTempPageTableData(rawData) {
      return rawData.map((row) => {
        let resultRow = {};
        for (let colKey in row) {
          // 遍历列
          let value = row[colKey];
          if (colKey.indexOf("____tokenize_") == 0) {
            value = row[colKey]
              .map((item) => {
                return item.name;
              })
              .toString();
          } else if (colKey == "____best_topic____") {
            value = "主题" + (value + 1);
          }
          resultRow[colKey] = value;
        }
        return resultRow;
      });
    }
    function _getTempRecordTableData(rawData) {
      // console.log("raw data", rawData);
      return rawData.map((row) => {
        let resultRow = {};
        for (let colKey in row) {
          // 遍历列
          let value = row[colKey];
          if (colKey.indexOf("____tokenize_") == 0) {
            value = row[colKey]
              .map((item) => {
                return item.name;
              })
              .toString();
          } else if (colKey == "____best_topic____") {
            value = "主题" + (value + 1);
          } else if (colKey == "____events____") {
            // value = parseEventsToText(row[colKey]);
          }
          resultRow[colKey] = value;
        }
        return resultRow;
      });
    }
    function _getTempCoOccurrencePageTableData(rawData) {
      return rawData.map((row) => {
        let resultRow = {};
        for (let colKey in row) {
          // 遍历列
          let value = row[colKey];
          if (colKey.indexOf("____tokenize_") == 0) {
            value = row[colKey]
              .map((item) => {
                return item.name;
              })
              .toString();
          } else if (colKey == "____best_topic____") {
            value = "主题" + (value + 1);
          }
          resultRow[colKey] = value;
        }
        return resultRow;
      });
    }
    function _getTempTopicProbabilityPageTableData(tableTitles, rawData) {
      let resultData = [];
      rawData.forEach((item, index) => {
        let rowData = {};
        rowData[0] = tableTitles[index];
        let i = 1;
        for (let word in item) {
          rowData[i] = word + "(" + item[word] + ")";
          i++;
        }

        resultData.push(rowData);
      });
      return resultData;
    }
    onMounted(() => {
      initTable();
    });
    // watch(
    //   () => props.tableData,
    //   (newVal) => {
    //     console.log("watch table", newVal);
    //     // initTable();
    //   },
    //   {
    //     deep: true
    //   }
    // );
    return {
      tableDataResult,
      handleCurrentChange,
      ...TABLE_TYPE,
      cooccurrenceForm,
      formatter,
      exportResult
    };
  }
};
</script>

<style scoped lang="scss">
.searchBar {
  // float: right;
  display: flex;
  justify-content: flex-end;
  .right {
    float: right;
    margin-right: 10px;
    margin-bottom: 15px;
  }
}
</style>
