<template>
  <div class="table-wrapper list-table">
    <div class="option-bar">
      <div class="search-box">
        <el-input
          v-model="keywords"
          placeholder="输入分析名称进行检索"
          style="width: 200px; margin-right: 8px"
        ></el-input>
        <el-select
          v-model="libraryId"
          placeholder="输入文本库名称进行检索"
          style="width: 200px; margin-right: 8px"
          remote
          filterable
          clearable
          :remote-method="getLibraryList"
        >
          <el-option
            v-for="libraryItem in libraryOptions"
            :key="libraryItem.id"
            :value="libraryItem.id"
            :label="libraryItem.name"
          ></el-option>
        </el-select>
        <el-select
          v-model="status"
          placeholder="状态"
          clearable
          style="width: 90px"
        >
          <el-option
            v-for="statusItem in statusOptions"
            :key="statusItem.value"
            :value="statusItem.value"
            :label="statusItem.label"
          ></el-option>
        </el-select>
        <el-button type="primary" @click="getAnalysisList()">搜索</el-button>
      </div>

      <div class="button-group">
        <span>
          <el-dropdown @command="handleBatchCommand">
            <el-button>
              批量操作
              <el-icon class="el-icon--right"><arrow-down /></el-icon>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="batchDelete">
                  批量删除
                </el-dropdown-item>
                <el-dropdown-item command="batchMove">
                  批量移动
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </span>

        <el-button @click="handleClickCreateFolder">新建文件夹</el-button>

        <el-button type="primary" @click="handleClickCreateAnalysis">
          新建分析
        </el-button>
      </div>
    </div>
    <div class="title-bar">
      <div class="title"><span>分析列表</span></div>
    </div>
    <el-table
      :data="analysisList"
      v-loading="tableLoading"
      header-cell-class-name="list-table-header"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="45px" />
      <el-table-column prop="name" label="名称" min-width="200">
        <template #default="scope">
          <TextEditor
            :icon="listIcon(scope.row)"
            :text="scope.row.name"
            :editable="
              !ifPresentationFolder(scope.row) &&
              scope.row.userId != SYSTEM_USER_ID
            "
            v-model:edit="scope.row.ifEdit"
            v-model:loading="scope.row.ifShowLoading"
            @save="(text) => handleClickSaveListItemName(text, scope.row)"
            @go="handleClickName(scope.row)"
            :font-color="scope.row.status == 5 ? '#ec2411' : ''"
          ></TextEditor>
        </template>
      </el-table-column>
      <el-table-column
        prop="libraryNameList"
        label="文本库"
        show-overflow-tooltip
        width="180"
      />
      <el-table-column
        prop="username"
        label="创建人"
        show-overflow-tooltip
        width="120"
      />
      <el-table-column prop="createTime" label="创建时间" min-width="100">
        <template #default="scope">
          {{ timeFormatter(scope.row.createTime) }}
        </template>
      </el-table-column>
      <el-table-column prop="updateTime" label="更新时间" min-width="100">
        <template #default="scope">
          {{ timeFormatter(scope.row.updateTime) }}
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态" width="120">
        <template #default="scope">
          <StatusTag
            v-if="scope.row.status != null"
            :type="statusMap[scope.row.status].type"
            :label="statusMap[scope.row.status].label"
          ></StatusTag>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200">
        <template #default="scope">
          <div class="list-item-btn" v-if="!scope.row.ifEdit">
            <div v-if="scope.row.contentType == CONTENT_TYPE.FOLDER">
              <el-button
                v-if="!ifPresentationFolder(scope.row)"
                link
                @click="handleClickDeleteFolder(scope.row)"
              >
                删除
              </el-button>
              <el-button
                link
                @click="showEditor(scope.row)"
                v-if="!ifPresentationFolder(scope.row) && scope.row.status != 4"
              >
                重命名
              </el-button>
            </div>
            <div v-else-if="scope.row.contentType == CONTENT_TYPE.ANALYSIS">
              <el-button
                v-if="scope.row.status != 4"
                link
                @click="goToAnalysisModelPage(scope.row)"
              >
                模型
              </el-button>

              <el-button
                v-if="scope.row.status != 4"
                link
                @click="goToAnalysisModelResultPage(scope.row)"
              >
                结果
              </el-button>
              <el-button
                v-if="scope.row.status == 4"
                link
                @click="handleClickDeleteAnalysis(scope.row)"
              >
                删除
              </el-button>

              <span
                class="list-item-dropdown"
                v-if="
                  scope.row.contentType == CONTENT_TYPE.ANALYSIS &&
                  scope.row.status != 4
                "
              >
                <el-dropdown
                  @command="(command) => handleCommand(scope.row, command)"
                >
                  <el-button link>
                    更多
                    <el-icon class="el-icon--right"><arrow-down /></el-icon>
                  </el-button>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item
                        v-if="scope.row.userId != SYSTEM_USER_ID"
                        command="delete"
                      >
                        删除
                      </el-dropdown-item>
                      <el-dropdown-item command="copy">复制</el-dropdown-item>
                      <el-dropdown-item
                        v-if="scope.row.userId != SYSTEM_USER_ID"
                        command="move"
                      >
                        移动
                      </el-dropdown-item>
                      <!-- <el-dropdown-item command="move">导出</el-dropdown-item> -->
                      <el-dropdown-item
                        v-if="scope.row.userId != SYSTEM_USER_ID"
                        command="rename"
                      >
                        重命名
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </span>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-box">
      <el-pagination
        v-model:current-page="paginationState.pageNum"
        v-model:page-size="paginationState.pageSize"
        small
        background
        layout="total, prev, pager, next, sizes"
        :total="paginationState.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="paginationState.pageSizeList"
      />
    </div>
  </div>
  <!-- <AnalysisDialogComfirmDelete
    ref="analysisDLGConfirmDeleteRef"
    @confirm="getAnalysisList"
  /> -->
  <AnalysisDialogTreeSelect
    ref="analysisDLGTreeSelectRef"
    @refresh="getAnalysisList()"
  />
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  watch,
  onMounted,
  computed
} from "vue";
import { httpPost } from "@/api/httpService.js";

import AnalysisDialogComfirmDelete from "./AnalysisDialogComfirmDelete.vue";
import AnalysisDialogTreeSelect from "./AnalysisDialogTreeSelect.vue";
import TextEditor from "@/components/base/TextEditor.vue";
import StatusTag from "@/components/base/StatusTag.vue";

import { CONTENT_TYPE } from "@/constant/analysis";
import { useRouter, useRoute } from "vue-router";
import { timeFormatter } from "@/utils/time.js";
import { ElMessage, ElMessageBox } from "element-plus";
import { Debounce } from "@/utils/utils";
import { confirmDeleteBox } from "@/utils/messageBox";
import Folder from "@/class/Folder.js";
import {
  DELETE_LIBRARY_CHILD_ERROR,
  DELETE_LIBRARY_ANALYSIS_ERROR,
  DELETE_LIBRARY_CHILD_AND_ANALYSIS_ERROR
} from "@/constant/error_code.js";
import { SYSTEM_USER_ID } from "@/constant/system.js";
import {
  ERROR_CODE_NAME_DUPLICATE,
  PARAM_VALID_ERROR
} from "@/constant/error_code.js";
import { statusMap } from "@/constant/analysis/status";

const router = useRouter();
const route = useRoute();
const props = defineProps({
  folderLevelList: {
    type: Array
  }
});

// 分析列表数据
const keywords = ref("");

const libraryId = ref(null);
const libraryOptions = ref([]);
const getLibraryList = async (keywords, id) => {
  let res = await httpPost("/lib/name/get", {
    // parentFolderId: 0, //根目录
    keywords
  });
  if (res.code == 0) {
    libraryOptions.value = res.data.filter((item) =>
      item.name.includes(keywords)
    );
    if (id) {
      libraryId.value = id;
    }
  }
};
getLibraryList("", +route.query.libraryId);
const analysisList = ref([]); // 列表数据
// let statusMap = {
//   0: { type: "info", label: "已创建", value: 0 },
//   1: { type: "warning", label: "进行中", value: 1 },
//   2: { type: "success", label: "成功", value: 2 },
//   3: { type: "danger", label: "异常", value: 3 },
//   4: { type: "info", label: "已失效", value: 4 },
//   5: { type: "success", label: "成功", value: 5 }
// }; // 状态map
const statusOptions = ref([]);
const status = ref("");
for (let status in statusMap) {
  statusOptions.value.push(statusMap[status]);
}
//获取当前页面信息（列表数据、文件夹路径）
const getAnalysisList = async (textLibraryId) => {
  showTableLoading();
  let url = "/view/analysis/get";
  let params = {
    parentFolderId: currentFolderId.value,
    libraryId: libraryId.value ?? textLibraryId,
    keywords: keywords.value,
    status: status.value,
    pageNum: paginationState.pageNum,
    pageSize: paginationState.pageSize
  };
  let res = await httpPost(url, params);
  hideTableLoading();
  if (res.code == 0) {
    analysisList.value = res.data.pageList.data;
    // folderLevelList.value = res.data.folderLevelList;
    paginationState.pageNum = res.data.pageList.pageNum;
    paginationState.total = res.data.pageList.total;
    paginationState.pageSize = res.data.pageList.pageSize;
  }
};
//分析加载
const tableLoading = ref(false);
const showTableLoading = () => {
  tableLoading.value = true;
};
const hideTableLoading = () => {
  tableLoading.value = false;
};
// 分析列表操作
const ifPresentationFolder = computed(() => {
  return (row) => {
    return currentFolderId.value == ROOT_FOLDER.id && row.name == "演示文件夹";
  };
});
// 点击名称跳转
const handleClickName = (row) => {
  if (row.status == 4) {
    ElMessage.warning("无法跳转到已失效模型");
    return;
  }
  switch (Number(row.contentType)) {
    case CONTENT_TYPE.FOLDER:
      goToFolderPage(row);
      break;
    case CONTENT_TYPE.ANALYSIS:
      goToAnalysisModelPage(row);
      break;
  }
};
//跳转文件夹页面
const goToFolderPage = (row) => {
  router.push({
    name: "AnalysisIndexWithFolder",
    params: {
      folderId: row.id
    }
  });
};
//跳转分析模型页面
const goToAnalysisModelPage = (row) => {
  router.push({
    name: "AnalysisModel",
    query: {
      analysisId: row.id
    }
  });
};
//跳转分析模型结果页面
const goToAnalysisModelResultPage = (row) => {
  router.push({
    name: "AnalysisModel",
    query: {
      analysisId: row.id,
      type: "1"
    }
  });
};
// 显示重命名输入框
const showEditor = (row) => {
  row.newName = row.name;
  row.ifEdit = true;
};
const hideEditor = (row) => {
  row.newName = "";
  row.ifEdit = false;
};
const listIcon = computed(() => {
  return (row) => {
    let result = "";
    switch (Number(row.contentType)) {
      case CONTENT_TYPE.FOLDER:
        result = "icon-fill-folder";
        break;
      case CONTENT_TYPE.ANALYSIS:
        result = "icon-fenxi";
        break;
    }
    return result;
  };
});
// 保存重命名
const handleClickSaveListItemName = Debounce(async (text, row) => {
  if (!text) {
    ElMessage.warning("名称不能为空");
    row.ifShowLoading = false;
    return;
  }
  let params = {};
  if (row.contentType == CONTENT_TYPE.FOLDER) {
    params = {
      ...row,
      name: text,
      ifChildFolder: isFolder(row.contentType)
    };
  } else {
    params = {
      id: row.id,
      name: text,
      parentFolderId: row.parentFolderId,
      ifChildFolder: isFolder(row.contentType)
    };
  }

  let res = await saveAnalysisOrFolder(params);
  if (res.code == 0) {
    //保存成功,更新单行，并重新获取最新列表数据
    ElMessage.success(res.message);
    row.name = text;
    row.ifEdit = false;
    getAnalysisList();
  } else if (
    res.code == ERROR_CODE_NAME_DUPLICATE ||
    res.code == PARAM_VALID_ERROR
  ) {
    //系统已知错误,用户重新编辑
    ElMessage.warning(res.message);
    row.ifShowLoading = false;
  } else {
    //系统未知错误,由外部捕获错误，然后重新获取列表数据
    getAnalysisList();
  }
});
// 根据contentType判断是否吃文件夹
const isFolder = (contentType) => {
  if (contentType == CONTENT_TYPE.FOLDER) {
    return 1;
  } else {
    return 0;
  }
};
// 取消重命名
const handleClickCancelRename = (scope) => {
  let row = scope.row;
  let index = scope.index;
  if (row.id) {
    // 如果存在id，则直接隐藏
    hideEditor(row);
  } else {
    analysisList.value.splice(index, 1);
  }
};

// 保存分析/文件夹
const saveAnalysisOrFolder = async (params) => {
  let url = "/view/analysis/save";
  let res = await httpPost(url, params);
  // if (res.code == 0) {
  //   ElMessage.success("保存成功");
  // } else if (
  //   res.code == ERROR_CODE_NAME_DUPLICATE ||
  //   res.code == PARAM_VALID_ERROR
  // ) {
  //   ElMessage.warning(res.message);
  // }
  return res;
};

//跳转数据记录页面
const goToDataRecord = (row) => {
  router.push({
    name: "DataRecordIndex",
    params: {
      analysisId: row.id
    }
  });
};
// 点击更多
const handleCommand = (row, command) => {
  switch (command) {
    case "delete":
      handleClickDeleteAnalysis(row);
      break;
    case "copy":
      handleClickCopy(row);
      break;
    case "move":
      handleClickMove(row);
      break;
    case "rename":
      showEditor(row);
      break;
  }
};
// 点击复制（分析）
const handleClickCopy = (row) => {
  analysisDLGTreeSelectRef.value.openDLG({
    type: "COPY",
    copiedLibrary: row
  });
};
// 点击移动（分析）
const handleClickMove = (row) => {
  analysisDLGTreeSelectRef.value.openDLG({
    type: "MOVE",
    movedItemList: [row]
  });
};
// 多选批量操作
const selectedItemList = ref([]); //存储选中item
// 列表勾选变化时，将被选中的item存入selectedItemList
const handleSelectionChange = (val) => {
  selectedItemList.value = val;
};
// 点击批量操作

const handleBatchCommand = (command) => {
  if (selectedItemList.value.length == 0) {
    ElMessage.warning("请先勾选分析或文件夹！");
    return;
  }
  switch (command) {
    case "batchMove":
      handleClickBatchMove();
      break;
    case "batchDelete":
      handleClickBatchDelete();
      break;
  }
};
// 点击批量移动
const handleClickBatchMove = () => {
  analysisDLGTreeSelectRef.value.openDLG({
    type: "MOVE",
    movedItemList: selectedItemList.value
  });
};
// 点击批量删除
const handleClickBatchDelete = () => {
  confirmDeleteBox("删除后，分析将无法恢复，是否确认删除？").then(async () => {
    let params = {
      analysisIdList: selectedItemList.value
        .filter((item) => item.contentType == CONTENT_TYPE.ANALYSIS)
        .map((item) => item.id),
      folderIdList: selectedItemList.value
        .filter((item) => item.contentType == CONTENT_TYPE.FOLDER)
        .map((item) => item.id)
    };
    let res = await deleteAnalysisOrFolder(params);
    if (res.code == 0) {
      ElMessage.success(res.message);
      getAnalysisList();
    } else if (
      res.code == DELETE_LIBRARY_CHILD_ERROR ||
      res.code == DELETE_LIBRARY_ANALYSIS_ERROR ||
      res.code == DELETE_LIBRARY_CHILD_AND_ANALYSIS_ERROR
    ) {
      ElMessage.warning(res.message);
    }
  });
};
const deleteAnalysisOrFolder = async (params) => {
  let url = "/view/analysis/delete";
  let res = await httpPost(url, params);
  return res;
};

// 点击创建分析
const handleClickCreateAnalysis = () => {
  let params = {
    folderId: currentFolderId.value
  };
  router.push({
    name: "AnalysisCreate",
    query: params
  });
};

// 删除分析确认弹窗
// const analysisDLGConfirmDeleteRef = ref(null);
//点击删除分析
const handleClickDeleteAnalysis = (row) => {
  confirmDeleteBox("删除后，分析将无法恢复，是否确认删除？").then(async () => {
    let params = {
      folderIdList: [],
      analysisIdList: [row.id]
    };
    let res = await deleteAnalysisOrFolder(params);
    if (res.code == 0) {
      ElMessage.success(res.message);
      getAnalysisList();
    } else if (
      res.code == DELETE_LIBRARY_CHILD_ERROR ||
      res.code == DELETE_LIBRARY_ANALYSIS_ERROR ||
      res.code == DELETE_LIBRARY_CHILD_AND_ANALYSIS_ERROR
    ) {
      ElMessage.warning(res.message);
    }
  });
};
//点击删除文件夹
const handleClickDeleteFolder = async (row) => {
  confirmDeleteBox().then(async () => {
    let params = {
      folderIdList: [row.id],
      analysisIdList: []
    };
    let res = await deleteAnalysisOrFolder(params);
    if (res.code == 0) {
      ElMessage.success(res.message);
      getAnalysisList();
    } else if (
      res.code == DELETE_LIBRARY_CHILD_ERROR ||
      res.code == DELETE_LIBRARY_ANALYSIS_ERROR ||
      res.code == DELETE_LIBRARY_CHILD_AND_ANALYSIS_ERROR
    ) {
      ElMessage.warning(res.message);
    }
  });
};

// 树形文件夹弹窗
const analysisDLGTreeSelectRef = ref(null);

//分页
const paginationState = reactive({
  pageNum: 1,
  pageSize: 10,
  pageSizeList: [10, 20, 50],
  total: 0
});
const handleSizeChange = (val) => {
  paginationState.pageSize = val;
  getAnalysisList();
};
// 切换当前页码
const handleCurrentChange = (val) => {
  paginationState.pageNum = val;
  getAnalysisList();
};

// 当前文件夹路径
const folderLevelList = ref(null);
const ROOT_FOLDER = { id: 0, name: "根目录", parentFolderId: null }; //用于显示面包屑
// const currentFolder = computed(() => {
//   if (!folderLevelList.value) {
//     return ROOT_FOLDER;
//   } else {
//     return folderLevelList.value[folderLevelList.value.length - 1];
//   }
// }); //当前文件目录
const currentFolderId = ref(ROOT_FOLDER.id); //用于接受路由参数

//创建文件夹
const handleClickCreateFolder = () => {
  let newFolder = new Folder({
    parentFolderId: currentFolderId.value,
    ifEdit: true
  });
  analysisList.value.unshift(newFolder);
};

const init = () => {
  currentFolderId.value = route.params.folderId || ROOT_FOLDER.id;
};
onMounted(() => {
  init();
  getAnalysisList(route.query.libraryId);
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/list-table";
.table-wrapper {
}
</style>
