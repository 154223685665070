<template>
  <!-- 图表 -->
  <div
    class="chart"
    v-if="data.layoutDisplay == undefined"
    :class="{ horizontal: layout == 'horizontal' }"
  >
    <div class="title">
      <!-- <div class="blue"></div> -->
      <div class="text" v-if="data.title">{{ data.title }}</div>
      <div class="text" v-else>{{ titleMap[data.moduleType] }}</div>
      <div class="export">
        <el-select
          v-model="exportType"
          placeholder="请选择导出类型"
          size="small"
        >
          <el-option
            v-for="type in exportTypeMap[data.moduleType]"
            :label="type"
            :value="type"
            :key="type"
          ></el-option>
        </el-select>
        <el-button
          :disabled="ifExportDisabled"
          size="small"
          type="text"
          @click="handleClickExport()"
        >
          导出
        </el-button>
      </div>
    </div>

    <!-- 柱状图 -->
    <bar-chart
      ref="chartRef"
      :chartInfo="chartInfo"
      v-if="data.moduleType == BAR_CHART_MODULE"
    ></bar-chart>
    <!-- 饼状图 -->
    <pie-chart
      ref="chartRef"
      :chartInfo="chartInfo"
      v-if="data.moduleType == PIE_CHART_MODULE"
    ></pie-chart>
    <!-- 折线图 -->
    <!-- 年月日切换栏 -->

    <time-line-chart
      ref="chartRef"
      v-if="data.moduleType == LINE_CHART_MODULE && data.chartType == 11"
      :chartInfo="chartInfo"
    ></time-line-chart>
    <line-chart
      ref="chartRef"
      :chartInfo="chartInfo"
      v-if="data.moduleType == LINE_CHART_MODULE && data.chartType == 4"
    ></line-chart>
    <!-- 示例折线图 -->
    <line-chart
      ref="chartRef"
      :chartInfo="chartInfo"
      v-if="data.moduleType == LINE_CHART_MODULE && !data.chartType"
    ></line-chart>
    <!-- 词云图 -->
    <wordCloudChart
      ref="chartRef"
      :chartInfo="chartInfo"
      v-if="
        chartInfo.moduleType == WORD_CLOUD_MODULE &&
        chartInfo.wordCloudData != undefined
      "
      :modelInfo="modelInfo"
    ></wordCloudChart>
    <!-- 表格 -->
    <result-table
      ref="chartRef"
      v-if="data.moduleType == TABLE_MODULE"
      :tableData="data"
      :modelInfo="modelInfo"
    ></result-table>
    <!-- 力导向图 -->
    <force-chart
      ref="chartRef"
      v-if="data.moduleType == FORCE_DIRECTED_MODULE"
      :chartInfo="data"
      :modelInfo="modelInfo"
    ></force-chart>
  </div>
  <!-- 垂直布局 -->
  <div v-else-if="data.layoutDisplay == 1">
    <el-row
      v-for="(item, index) in data.childList"
      :key="index"
      style="display: block"
    >
      <result-layout
        :data="item"
        :modelInfo="modelInfo"
        :analysisId="analysisId"
      ></result-layout>
    </el-row>
  </div>
  <!-- 水平布局 -->
  <!-- <div v-else-if="data.layoutDisplay == 2"> -->
  <el-row v-else-if="data.layoutDisplay == 2">
    <el-col
      v-for="(item, index) in data.childList"
      :key="index"
      :span="item.width"
    >
      <!--{{ item.width }}-->
      <result-layout
        :data="item"
        :modelInfo="modelInfo"
        layout="horizontal"
      ></result-layout>
    </el-col>
  </el-row>
  <!-- </div> -->
</template>

<script>
import {
  ref,
  reactive,
  toRefs,
  getCurrentInstance,
  watch,
  computed,
  onMounted,
  provide,
  nextTick
} from "vue";
import { httpPost } from "@/api/httpService";
import WordCloudChart from "@/components/antvCharts/WordCloudChart.vue";
// import { clearSelection } from "@antv/x6/lib/util/dom/selection";
import BarChart from "@/components/antvCharts/BarChart.vue";
import LineChart from "@/components/antvCharts/LineChart.vue";
import TimeLineChart from "@/components/chart/TimeLineChart.vue";
import ResultTable from "../ResultTable.vue";
import ForceChart from "@/components/antvCharts/ForceChart.vue";
import PieChart from "@/components/antvCharts/PieChart.vue";
import { popconfirmProps } from "element-plus";
export default {
  name: "resultLayout",
  props: ["data", "modelInfo", "analysisId", "layout"],
  components: {
    BarChart,
    WordCloudChart,
    LineChart,
    ResultTable,
    ForceChart,
    PieChart,
    TimeLineChart
  },
  setup(props) {
    const TABLE_MODULE = 401; //表格
    const BAR_CHART_MODULE = 402; //柱状图
    const LINE_CHART_MODULE = 403; //折线图
    const PIE_CHART_MODULE = 404; //饼状图
    const WORD_CLOUD_MODULE = 405; //词云图
    const SCATTER_CHART_MODULE = 406; // 散点图
    const FORCE_DIRECTED_MODULE = 407; //力导向图
    const MAP_MODULE = 408; //地图
    const TEXT_MODULE = 409; //文本
    const LAYOUT_MODULE = 410; //布局
    const VERTICAL_LAYOUT_MODULE = 501; //垂直布局
    const HORIZONTAL_LAYOUT_MODULE = 502; //水平布局
    const constant = reactive({
      WORD_CLOUD_MODULE,
      BAR_CHART_MODULE,
      LINE_CHART_MODULE,
      TABLE_MODULE,
      FORCE_DIRECTED_MODULE,
      PIE_CHART_MODULE
    });
    const titleMap = reactive({
      405: "词云图示例",
      402: "柱状图示例",
      403: "折线图示例",
      401: "表格示例",
      407: "力导向图示例",
      404: "饼状图实例"
    });
    const exportTypeMap = reactive({
      405: ["PNG", "EXCEL"],
      402: ["PNG"],
      403: ["PNG"],
      401: ["EXCEL"],
      407: ["PNG"],
      404: ["PNG"]
    });
    const chartRef = ref(null);
    const d = ref(null);

    const exportType = ref(null);

    // console.log("export type", exportType.value);
    let state = reactive({
      showTimeLineTabs: false
    });
    let chartInfo = ref({});
    //判断图表类型
    function getChartType() {
      let moduleType = props.data.moduleType;
      // console.log("module type", moduleType);
      switch (moduleType) {
        //柱状图
        case BAR_CHART_MODULE:
          setBarChartInfo();
          break;
        //柱状图
        case PIE_CHART_MODULE:
          setPieChartInfo();
          break;
        //折线图
        case LINE_CHART_MODULE:
          setLineChartInfo();
          break;
        case WORD_CLOUD_MODULE:
          // console.log("405");
          setWordCloudInfo();
          break;
        default:
          break;
      }
      chartInfo.value.title = props.data.title;
    }
    // 饼状图数据初始化
    function setPieChartInfo() {
      if (props.data && props.data.categoryDataList) {
        chartInfo.value.data = props.data.categoryDataList;
      } else {
        chartInfo.value = {
          data: [
            { xAxisValue: "示例1", yAxisValue: 19 },
            { xAxisValue: "示例2", yAxisValue: 21 },
            { xAxisValue: "示例3", yAxisValue: 27 },
            { xAxisValue: "示例4", yAxisValue: 33 }
          ]
        };
      }
    }

    //设置柱状图chartInfo
    function setBarChartInfo() {
      console.log("set bar chart");
      if (props.data && props.data.categoryDataList) {
        if (props.data.categoryDataList.length > 0) {
          chartInfo.value.data = props.data.categoryDataList;
          //判断柱状图类型
          let flag = chartInfo.value.data[0].group;
          if (!flag) {
            //基础柱状图
            chartInfo.value.adjust = null;
            chartInfo.value.color = "xAxisValue";
            chartInfo.value.coordinate = { actions: [] };
          } else {
            //分组柱状图
            chartInfo.value.adjust = [
              {
                type: "dodge",
                marginRatio: 0
              }
            ];
            chartInfo.value.color = "group";
            chartInfo.value.coordinate = { actions: [] };
            chartInfo.value.coordinate.actions = [];
          }
        } else {
          chartInfo.value.data = props.data.categoryDataList;
          chartInfo.value.coordinate = { actions: [] };
        }
      } else {
        chartInfo.value = {
          data: [
            { xAxisValue: "示例1", yAxisValue: 38, group: "示例1" },
            { xAxisValue: "示例2", yAxisValue: 52, group: "示例2" },
            { xAxisValue: "示例3", yAxisValue: 61, group: "示例3" },
            { xAxisValue: "示例4", yAxisValue: 145, group: "示例4" },
            { xAxisValue: "示例5", yAxisValue: 48, group: "示例5" },
            { xAxisValue: "示例6", yAxisValue: 38, group: "示例6" },
            { xAxisValue: "示例7", yAxisValue: 38, group: "示例7" },
            { xAxisValue: "示例8", yAxisValue: 38, group: "示例8" }
          ],
          adjust: null,
          color: "xAxisValue",
          coordinate: { actions: [] }
        };
      }
    }
    //设置折线图chartInfo
    function setLineChartInfo() {
      if (props.data.notFinish) {
        // console.log("not finish");
      }
      if (props.data && (props.data.categoryDataList || props.data.year)) {
        //判断是否是时间折线图
        if (
          props.data.categoryDataList &&
          props.data.categoryDataList.length > 0
        ) {
          //普通折线图
          chartInfo.value.data = props.data.categoryDataList;
          //判断柱状图类型
          let flag = chartInfo.value.data[0].group;
          if (!flag) {
            //基础折线图
            chartInfo.value.color = "";
          } else {
            //分组折线图
            chartInfo.value.color = "group";
          }
        } else if (props.data.chartType == 11) {
          //时间折线图
          state.showTimeLineTabs = true;
          if (props.data.notFinish) {
            let defaultData = {
              year: [
                {
                  name: "示例数据1",
                  type: "line",
                  smooth: true,
                  data: [
                    ["1990", 400],
                    ["1991", 500],
                    ["1992", 600],
                    ["1993", 700],
                    ["1994", 800],
                    ["1995", 900],
                    ["1996", 1000],
                    ["1997", 100],
                    ["1998", 200],
                    ["1999", 300]
                  ]
                },
                {
                  name: "示例数据2",
                  type: "line",
                  smooth: true,
                  data: [
                    ["1990", 410],
                    ["1991", 520],
                    ["1992", 630],
                    ["1993", 740],
                    ["1994", 850],
                    ["1995", 960],
                    ["1996", 1700],
                    ["1997", 180],
                    ["1998", 290],
                    ["1999", 30]
                  ]
                }
              ],
              month: [
                {
                  name: "示例数据1",
                  type: "line",
                  smooth: true,
                  data: [
                    ["1990-1", 40],
                    ["1991-2", 50],
                    ["1992-3", 60],
                    ["1993-4", 70],
                    ["1994-5", 80],
                    ["1995-6", 90],
                    ["1996-7", 100],
                    ["1997-8", 10],
                    ["1998-9", 20],
                    ["1999-10", 30]
                  ]
                },
                {
                  name: "示例数据2",
                  type: "line",
                  smooth: true,
                  data: [
                    ["1990-1", 41],
                    ["1991-2", 52],
                    ["1992-3", 63],
                    ["1993-4", 70],
                    ["1994-5", 50],
                    ["1995-6", 96],
                    ["1996-7", 100],
                    ["1997-8", 80],
                    ["1998-9", 20],
                    ["1999-10", 3]
                  ]
                }
              ],
              day: {}
            };
            chartInfo.value.data = defaultData;
            chartInfo.value.title = "时间轴折线图示例";
            // console.log("!!!!!!", chartInfo);
          } else {
            let yearSeriesData = props.data["year"].map((group) => {
              return {
                name: group.name,
                type: "line",
                smooth: true,
                data: group.data
              };
            });
            let monthSeriesData = props.data["month"].map((group) => {
              return {
                name: group.name,
                type: "line",
                smooth: true,
                data: group.data
              };
            });
            let daySeriesData = props.data["day"].map((group) => {
              return {
                name: group.name,
                type: "line",
                smooth: true,
                data: group.data
              };
            });

            chartInfo.value.data = {
              year: yearSeriesData,
              month: monthSeriesData,
              day: daySeriesData
            };
            chartInfo.value.title = props.data.title;
          }

          chartRef.value.updateChartView();
          // //判断柱状图类型
          // let flag = chartInfo.value.data[0].group;
          // if (!flag) {
          //   //基础折线图
          //   chartInfo.value.color = "";
          // } else {
          //   //分组折线图
          //   chartInfo.value.color = "group";
          // }
        }
      } else {
        chartInfo.value = {
          data: [
            { xAxisValue: "示例1", yAxisValue: 38 },
            { xAxisValue: "示例2", yAxisValue: 52 },
            { xAxisValue: "示例3", yAxisValue: 61 },
            { xAxisValue: "示例4", yAxisValue: 145 },
            { xAxisValue: "示例5", yAxisValue: 48 },
            { xAxisValue: "示例6", yAxisValue: 38 },
            { xAxisValue: "示例7", yAxisValue: 38 },
            { xAxisValue: "示例8", yAxisValue: 38 }
          ],
          color: ""
        };
      }
    }
    //设置词云图默认图
    function setWordCloudInfo() {
      // console.log("word cloud data", props.data);

      if (!props.data.notFinish) {
        // console.log("finish");
        chartInfo.value = props.data;
      } else {
        // console.log("not finish");
        let defaultWordCloudData = [];
        for (let i = 0; i < 300; i++) {
          defaultWordCloudData.push({
            name: i,
            value: Math.round(Math.random() * 50)
          });
        }
        chartInfo.value = {
          moduleType: WORD_CLOUD_MODULE,
          shapePictureUrl: null,
          title: "词云图示例",
          viewStyle: 1,
          wordCloudData: defaultWordCloudData
        };
      }
    }
    //切换时间折线图tab栏

    const ifExportDisabled = ref(false);
    // 点击导出按钮
    async function handleClickExport() {
      ifExportDisabled.value = true;
      // console.log("handle click export", chartRef.value);
      await chartRef.value.exportResult(exportType.value);
      ifExportDisabled.value = false;
    }

    onMounted(() => {
      getChartType();
      if (props.data.layoutDisplay == undefined) {
        exportType.value = exportTypeMap[props.data.moduleType][0];
      }
    });
    return {
      d,
      ...constant,
      titleMap,
      chartRef,
      ...toRefs(state),
      chartInfo,
      getChartType,
      setBarChartInfo,
      setLineChartInfo,
      handleClickExport,
      exportType,
      exportTypeMap,
      ifExportDisabled
    };
  }
};
</script>

<style lang="scss" scoped>
.horizontal {
  height: 450px;
  overflow: scroll;
}
.chart {
  border: 1px solid #ccc;
  // border-color: #ccc;
  /* margin: 30px; */
  /* padding: 20px; */
}
.title {
  height: 38px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
  // cursor: pointer;
  & > div {
    float: left;
  }
  .export {
    float: right;
    .el-button {
      margin-left: 10px;
    }
    .el-select {
      width: 140px;
    }
  }

  .blue {
    width: 12px;
    height: 30px;
    background-color: $primary-color;
    margin-left: 3px;
  }
  .text {
    padding-left: 15px;
    line-height: 32px;
    font-size: 16px;
    text-align: left;
  }
  i {
    color: #3894ff;
    float: right;
  }
}
</style>
