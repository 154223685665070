<template>
  <div id="template-box-wrapper">
    <div class="search-bar">
      <el-input
        v-model="searchForm.keywords"
        placeholder="搜索模板"
        class="input-with-select search-input"
        clearable
        @keyup.enter="getTemplateList"
      >
        <template #append>
          <el-button @click="getTemplateList" :icon="Search" />
        </template>
      </el-input>
    </div>
    <div v-if="templateList && templateList.length == 0">
      <EmptyStatus></EmptyStatus>
    </div>
    <div v-else class="template-list" v-loading="templateLoading">
      <TemplateCard
        :cardInfo="templateItem"
        v-for="templateItem in templateList"
        :key="templateItem.name"
        @use="handleClickUse"
      />
    </div>
    <div v-if="ifShowPagination" class="pagination-box">
      <el-pagination
        v-model:current-page="paginationState.pageNum"
        v-model:page-size="paginationState.pageSize"
        small
        background
        layout="total, prev, pager, next"
        :total="paginationState.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="paginationState.pageSizeList"
      />
    </div>
  </div>
</template>

<script setup>
import { ElLoading } from "element-plus";
import { reactive, ref, defineProps, onMounted, computed } from "vue";
import TemplateCard from "../../../components/template./../../components/template/TemplateCard.vue";
import { Search } from "@element-plus/icons-vue";
import { httpPost } from "@/api/httpService";
import { SYSTEM_USER_ID } from "@/constant/system.js";
import { useRouter } from "vue-router";
import EmptyStatus from "@/components/base/EmptyStatus.vue";

const props = defineProps({
  templateType: {
    default: "system"
  },
  parentFolderId: [String, Number]
});
const router = useRouter();

// 模板加载效果
const templateLoading = ref(false);
// 分页信息
const paginationState = reactive({
  pageNum: 1,
  pageSize: 10,
  total: 0
});
// 搜索信息
const searchForm = ref({
  keywords: ""
});
// 模板列表
const templateList = ref([]);
const CUSTOM_TEMPLATE = {
  name: "自定义",
  templateAnalysisId: null,
  userId: SYSTEM_USER_ID
};
// 点击使用模板
const handleClickUse = async (analysisTemplate) => {
  const loadingInstance = ElLoading.service({
    text: "创建中，请稍等……",
    background: "rgba(248, 248, 250, 0.6)"
  });
  let res = await createAnalysis(
    analysisTemplate.templateAnalysisId,
    props.parentFolderId
  );
  loadingInstance.close();
  router.push({
    name: "AnalysisModel",
    query: {
      analysisId: res.data
    }
  });
};
const createAnalysis = async (templateId, parentFolderId) => {
  console.log(parentFolderId);
  let params = {
    templateId,
    parentFolderId,
    ifChildFolder: 0 //0：不是文件夹
  };
  let res = await httpPost("/view/analysis/save", params);
  return res;
};
// 判断当前是否为系统模板 获取对应模板列表信息
const getTemplateList = async () => {
  templateLoading.value = true;
  if (ifSystemTemplate.value) {
    let params = {
      ...searchForm.value
    };
    let res = await httpPost("analysis/template/listSystemTemplate", params);
    if (res.code == 0) {
      templateList.value = res.data;
      if (!searchForm.value.keywords) {
        templateList.value.unshift(CUSTOM_TEMPLATE);
      }

      templateLoading.value = false;
    }
  } else {
    let params = {
      ...searchForm,
      ...paginationState
    };
    let res = await httpPost("analysis/template/listUserTemplate", params);
    if (res.code == 0) {
      templateList.value = res.data.data;
      paginationState.total = res.data.total;
      paginationState.pageNum = res.data.pageNum;
      paginationState.pageSize = res.data.pageSize;
      templateLoading.value = false;
    }
  }
};
// 切换pageSize
const handleSizeChange = (val) => {
  paginationState.pageSize = val;
  getTemplateList();
};
// 切换pageNum
const handleCurrentChange = (val) => {
  paginationState.pageNum = val;
  getTemplateList();
};
// 是否为系统模板
const ifSystemTemplate = computed(() => {
  return props.templateType == "system";
});
// 是否显示新建模板按钮
const ifShowCreateButton = computed(() => {
  return props.templateType == "user";
});
// 是否显示分页
const ifShowPagination = computed(() => {
  return props.templateType == "user";
});
// 挂载之后获取模板列表信息
onMounted(() => {
  getTemplateList();
});
</script>
<style lang="scss" scoped>
#template-box-wrapper {
  .search-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px 0;
    .search-btn {
      margin-right: 20px;
    }
    .search-input {
      width: 300px;
      :deep(.el-input__suffix) {
        @include pointer();
      }
    }
  }
  .template-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 225px);
    grid-gap: 32px;
    grid-template-rows: repeat(auto-fill, 200px);
  }
  .pagination-box {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
