<template>
  <!-- <h1>词云图:{{ chartInfo.title }}</h1> -->
  <div style="margin-bottom: 10px">
    显示数量：
    <el-select
      v-model="wordCloudSize"
      class="m-2"
      size="small"
      @change="changeWordCloudSize"
    >
      <el-option
        v-for="size in sizeOptions"
        :key="size.value"
        :label="size.label"
        :value="size.value"
      ></el-option>
    </el-select>
    <el-select
      v-if="natureList.length"
      v-model="nature"
      class="m-2"
      size="small"
      style="margin-left: 16px"
      placeholder="请选择词性进行筛选"
      clearable
      @change="handleChangeNature"
    >
      <el-option
        v-for="nature in natureList"
        :key="nature"
        :label="nature"
        :value="nature"
      ></el-option>
    </el-select>
  </div>

  <div class="wordCloud-container">
    <div class="wordCloud" ref="wordCloudRef"></div>

    <div class="table">
      <el-table :data="tableData">
        <el-table-column label="序号">
          <template #default="scope">
            <span style="margin-left: 10px">
              {{ scope.$index + 1 + pageSize * (currentPage - 1) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="词语" min-width="120px">
          <template #default="scope">
            <span style="margin-left: 10px">{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="词性"
          min-width="120px"
          v-if="natureList.length"
        >
          <template #default="scope">
            <span style="margin-left: 10px">{{ scope.row.nature }}</span>
          </template>
        </el-table-column>
        <el-table-column label="词频">
          <template #default="scope">
            <span style="margin-left: 10px">{{ scope.row.value }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <img
              class="table-delete"
              @click="handleDelete(scope.$index, scope.row)"
              src="../../assets/imgs/delete.svg"
              alt="删除"
            />
          </template>
        </el-table-column>
      </el-table>
      <div class="demo-pagination-block">
        <el-pagination
          v-model:currentPage="currentPage"
          :page-size="10"
          :total="total"
          layout="total, prev, pager, next"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { httpPost } from "@/api/httpService";
import { onMounted, toRefs, reactive, ref, computed, nextTick } from "vue";

import DataSet from "@antv/data-set";
import { Chart, registerShape, Util } from "@antv/g2";
import { LIGHT_EFFECT } from "element-plus";
import { init } from "echarts";
import { toDataURL, downloadImage } from "@/utils/exportG2";
import { export2Excel } from "@/utils/excel";

export default {
  /**
   * @author: RenLishisan
   * @description: 词云图组件，该组件接受 modelInfo和chartInfo数据
   * @time: 2021/12/21 1:34 下午
   **/
  /**
   * @author: RenLishisan
   * @使用方法:  <wordCloudChart :chartInfo="data" :modelInfo="modelInfo"></wordCloudChart>
   * @time: 2021/12/21 1:36 下午
   **/
  // 接受参数 👇
  props: {
    chartInfo: {
      wordCloudData: [{ name: String, value: Number }], //词云列表
      title: String //标题
    },
    modelInfo: {
      id: Number //🆔
    }
  },

  setup(props) {
    const wordCloudRef = ref(null); // 词云图容器的ref
    const wordCloudSize = ref(100); // 用户选择的词语数量（不代表实际数量）
    const total = computed(() => {
      return Math.min(wordCloudSize.value, filteredData.value.length);
    }); //表格中显示的total总数
    const sizeOptions = reactive([
      {
        value: 30,
        label: "前30"
      },
      {
        value: 50,
        label: "前50"
      },
      {
        value: 100,
        label: "前100"
      },
      {
        value: 300,
        label: "前300"
      }
      // {
      //   value: 500,
      //   label: 500
      // }
    ]);
    const wordCloudData = ref(props.chartInfo.wordCloudData); //词云图数据来源，切换size时不变动，删除时才变动

    const natureList = ref([]); // 词性列表
    const nature = ref(null); // 当前选中的词性
    const getNatureList = () => {
      let set = new Set();
      wordCloudData.value.forEach((item) => {
        set.add(item.nature);
      });
      natureList.value = [...set];
    };
    if (
      Object.prototype.hasOwnProperty.call(
        wordCloudData?.value[0] || {},
        "nature"
      )
    ) {
      // 如果存在nature则显示词性筛选
      getNatureList();
    }

    let chart = null; // 词云图的实例对象
    const imageMask = new Image();

    imageMask.crossOrigin = "";
    let defaultShapePictureUrl =
      "https://img2.baidu.com/it/u=1392163626,1601366779&fm=253&fmt=auto&app=138&f=JPG?w=500&h=500";

    imageMask.src = props.chartInfo.shapePictureUrl || defaultShapePictureUrl;
    // 经过词性筛选后的词语
    const filteredData = computed(() => {
      if (nature.value) {
        return wordCloudData.value.filter(
          (item) => item.nature == nature.value
        );
      } else {
        return wordCloudData.value;
      }
    });
    const tableData = computed(() => {
      let startIndex = (currentPage.value - 1) * pageSize.value;
      let endIndex = currentPage.value * pageSize.value;
      // if (nature.value) {
      //   return wordCloudData.value
      //     .filter((item) => item.nature == nature.value)
      //     .slice(startIndex, endIndex);
      // }
      return filteredData.value.slice(startIndex, endIndex);
    }); // 显示在表格中的数据
    const currentPage = ref(1); // 当前页码
    const pageSize = ref(10); // 表格单页行数
    const state = {
      total
    };
    let dv = null;
    function initDataView() {
      dv = new DataSet.View().source(
        filteredData.value.slice(0, wordCloudSize.value)
        // wordCloudData.value.slice(0, wordCloudSize.value)
      );
      console.log(dv);
      const range = dv.range("value");
      const min = range[0];
      const max = range[1];
      dv.transform({
        type: "tag-cloud",
        fields: ["name", "value"],
        imageMask,
        font: "Verdana",
        // size: [600, 400], // 宽高设置最好根据 imageMask 做调整
        padding: 0,
        timeInterval: 5000, // max execute time
        rotate() {
          let random = ~~(Math.random() * 4) % 4;
          if (random === 2) {
            random = 0;
          }
          // return random * 90; // 0, 90, 270
          return 0;
        },
        fontSize(d) {
          const baseSize = {
            30: 28,
            50: 20,
            100: 14,
            300: 8
          };
          // return (
          //   Math.log(((d.value - min) / (max - min)) * (32 - 8)) +
          //   baseSize[wordCloudSize.value]
          // );
          return (
            ((d.value - min) / (max - min)) * 22 + baseSize[wordCloudSize.value]
          );
        }
      });
    }
    function changeWordCloudSize(val) {
      currentPage.value = 1;
      initGraph();
    }
    function handleChangeNature(val) {
      currentPage.value = 1;
      initGraph();
    }
    // 根据数据重新生成dataview 并更新到graph上
    function initGraph() {
      initDataView();
      chart.data(dv.rows);
      chart.render();
    }

    //🍓 删除表格中的数据
    function handleDelete(index, row) {
      let deleteIndex = wordCloudData.value.findIndex((item) => {
        return item.name == row.name && item.nature == row.nature;
      });
      wordCloudData.value.splice(deleteIndex, 1);
      // wordCloudData.value.splice(
      //   index + pageSize.value * (currentPage.value - 1),
      //   1
      // );
      initGraph();
    }
    //🍓 分页
    const handleCurrentChange = (val) => {
      //🍉 请求必要参数
    };

    onMounted(() => {
      /* antV2G词云图配置参数 start */
      function getTextAttrs(cfg) {
        return {
          ...cfg.style,
          fontSize: cfg.data.size,
          text: cfg.data.text,
          textAlign: "center",
          fontFamily: cfg.data.font,
          fill: cfg.color,
          textBaseline: "Alphabetic"
        };
      }

      registerShape("point", "cloud", {
        draw(cfg, container) {
          const attrs = getTextAttrs(cfg);
          const textShape = container.addShape("text", {
            attrs: {
              ...attrs,
              x: cfg.x,
              y: cfg.y
            }
          });
          if (cfg.data.rotate) {
            Util.rotate(textShape, (cfg.data.rotate * Math.PI) / 180);
          }
          return textShape;
        }
      });

      /**
       * @author: RenLishisan
       * @description: 🚧 imageMask方法为根据图片高亮区域渲染布局词云，仅需接受图片Url给 imageMask.src 和挂载至 imageMask.onload 方法即可。
       * @relevance: 详细示例产考 AndV-2G 4.1.32 `https://antv-g2.gitee.io/zh/examples/other/other#word-cloud-mask`
       * @time: 2021/12/21 1:26 下午
       **/

      //
      // };
      chart = new Chart({
        container: wordCloudRef.value,
        autoFit: true,
        // autoFitwidth: 600, // 宽高设置最好根据 imageMask 做调整
        // height: 400,
        padding: 0
      });
      imageMask.onload = () => {
        initDataView();
        chart.data(dv.rows);
        chart.scale({
          x: { nice: false },
          y: { nice: false }
        });
        chart.legend(false);
        chart.axis(false);
        chart.tooltip({
          showTitle: false,
          showMarkers: true,

          title: (title, datum) => datum["value"],
          customItems: (items) => {
            items.forEach((item) => {
              item.value = item.data.value;
            });
            return items;
          }
        });
        chart.coordinate().reflect();
        chart
          .point()
          .position("x*y")
          .color("text")
          .shape("cloud")
          .state({
            active: {
              style: {
                fillOpacity: 0.4
              }
            }
          });
        // chart.interaction("element-active");
        nextTick(() => {
          chart.render();
        });
      };

      /* antV2G词云图配置参数 end */
    });
    function exportExcel() {
      // console.log("export wordcloud excel", wordCloudData.value);
      let tHeader = ["词语", "词频"];
      let filterVal = ["name", "value"];
      let temp = wordCloudData.value.slice(0, wordCloudSize.value);
      let fileName = props.chartInfo.title;

      export2Excel(tHeader, filterVal, temp, fileName);
    }
    // 导出
    function exportResult(type) {
      switch (type) {
        case "PNG":
          downloadImage(chart, props.chartInfo.title);
          break;
        case "EXCEL":
          exportExcel();
          break;
        default:
          break;
      }
    }

    return {
      wordCloudRef,
      props,
      tableData,
      total,
      handleDelete,
      handleCurrentChange,
      sizeOptions,
      wordCloudSize,
      changeWordCloudSize,
      wordCloudData,
      pageSize,
      currentPage,
      exportResult,
      natureList,
      nature,
      initGraph,
      handleChangeNature
    };
  }
};
</script>

<style scoped lang="scss">
.wordCloud-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  //词图云
  .wordCloud {
    // width: 500px;
    width: 60%;

    height: 500px;
    margin-top: 36px;
  }
  .table {
    width: 40%;
  }

  .table-delete {
    width: 21px;
    height: 21px;
    cursor: pointer;
  }
}
</style>
