<template>
  <div ref="refLineChart"></div>
</template>

<script>
import { onMounted, toRefs, reactive, ref, nextTick } from "vue";
import { Chart } from "@antv/g2";
import { toDataURL, downloadImage } from "@/utils/exportG2";

export default {
  name: "LineChart",
  props: {
    chartInfo: Object
  },
  setup(props) {
    //数据
    let refLineChart = ref(null);
    let state = reactive({
      chart: null
    });
    //初始化图形
    function initChart() {
      const data = props.chartInfo.data;

      state.chart = new Chart({
        container: refLineChart.value,
        autoFit: true,
        height: 350,
        padding: [50, 20, 50, 20]
      });

      state.chart.data(data);

      state.chart.scale({
        month: {
          range: [0, 1]
        },
        temperature: {
          nice: true
        }
      });

      state.chart.tooltip({
        showCrosshairs: true,
        shared: true
      });

      state.chart
        .line()
        .position("xAxisValue*yAxisValue")
        .color(props.chartInfo.color)
        .shape("smooth");
      state.chart
        .point()
        .position("xAxisValue*yAxisValue")
        .color(props.chartInfo.color)
        .shape("circle");
      // .tooltip("xAxisValue*yAxisValue", (xAxisValue, yAxisValue) => {
      //   return {
      //     name: "生活费",
      //     value: yAxisValue
      //   };
      // })
      // 添加文本标注
      data.forEach((item) => {
        console.log(item);
        state.chart.annotation().text({
          position: [item.xAxisValue, item.yAxisValue],
          content: item.yAxisValue,
          style: {
            textAlign: "center"
          },
          offsetY: -12
        });
      });

      state.chart.render();
    }
    //更新数据
    function resizeChart() {
      const data = props.chartInfo.data;
      state.chart.changeData(data);
    }
    // 导出
    function exportResult(type) {
      switch (type) {
        case "PNG":
          downloadImage(state.chart, props.chartInfo.title);

          break;

        default:
          break;
      }
    }
    onMounted(() => {
      nextTick(() => {
        initChart();
      });
    });
    return {
      refLineChart,
      ...toRefs(state),
      initChart,
      resizeChart,
      exportResult
    };
  }
};
</script>

<style lang="scss" scoped></style>
