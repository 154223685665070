// const httpPost =
//   getCurrentInstance().appContext.config.globalProperties.$httpPost;
import { httpPost } from "@/api/httpService";
import { FIELD_TYPE_CONST } from "./moduleInfo.js";
import Vue from "vue";
// 向module的fields的data添加field，如果遇到同名的field则进行覆盖
function addField(node, field) {
  const fieldsData = node.data.fields.data || [];
  const originField = fieldsData.find((item) => {
    return item.fieldName == field.fieldName;
  });
  if (originField) {
    for (let key in originField) {
      delete originField[key];
    }

    for (let key in field) {
      originField[key] = field[key];
    }
  } else {
    fieldsData.push(field);
  }
}

const optionSourceFunctionMap = {
  // 获取文本库列表
  getTextLibraryList: async (params) => {
    var result = [];
    let res = await httpPost("/lib/name/get", {
      // analysisId: params.analysisId
    });
    res.data.forEach((item) => {
      result.push({
        value: item.id,
        label: item.name
      });
    });
    return result;
  },
  // 获取系统所有文本库名称列表
  getSystemTextLibraryList: async (params) => {
    var result = [];
    let res = await httpPost("/lib/name/get/sys", {
      // analysisId: params.analysisId
    });
    res.data.forEach((item) => {
      result.push({
        value: item.id,
        label: item.name
      });
    });
    return result;
  },
  // 获取是否覆盖的选项
  getIfCoverOptions: async (params) => {
    return [
      {
        value: true,
        label: "是"
      },
      {
        value: false,
        label: "否"
      }
    ];
  },
  // 获取类型为文本(中文）或长文本（中文）的field
  getSelectableAnalysisFields: async (params) => {
    // 从records输入获取fields
    const fields = params.formerNodes.records[0].data.fields.data || [];
    const options = [];
    // 在options加入选项
    fields.length > 0 &&
      fields.forEach((field) => {
        if (
          (field.type == FIELD_TYPE_CONST.FIELD_TYPE_TEXT_ZH ||
            field.type == FIELD_TYPE_CONST.FIELD_TYPE_LONG_TEXT_ZH) &&
          field.language == 0
        ) {
          options.push({
            value: field.fieldName,
            label: field.fieldLabel
          });
        }
      });
    return options;
  },
  // 获取类型为文本(英文）或长文本（英文）的field
  getSelectableAnalysisFieldsOfEn: async (params) => {
    // 从records输入获取fields
    const fields = params.formerNodes.records[0].data.fields.data || [];
    const options = [];
    // 在options加入选项
    fields.length > 0 &&
      fields.forEach((field) => {
        if (
          field.type == FIELD_TYPE_CONST.FIELD_TYPE_TEXT_EN ||
          field.type == FIELD_TYPE_CONST.FIELD_TYPE_LONG_TEXT_EN ||
          (field.type == FIELD_TYPE_CONST.FIELD_TYPE_TEXT_ZH &&
            field.language == 2) ||
          (field.type == FIELD_TYPE_CONST.FIELD_TYPE_LONG_TEXT_ZH &&
            field.language == 2)
        ) {
          options.push({
            value: field.fieldName,
            label: field.fieldLabel
          });
        }
      });
    return options;
  },
  // 获取中文分词选项
  getTokenizeConfigIdOfTokenizeOfZH: async (params) => {
    let res = await httpPost("/config/tokenize/get/list");
    const options = [];
    if (res.code == 0) {
      res.data.forEach((item) => {
        if (item.tokenizeLanguage == 0) {
          options.push({
            label: item.name,
            value: item.objectId
          });
        }
      });
    }
    return options;
  },
  // 获取分词选项
  getTokenizeConfigIdOfTokenizeOfEN: async (params) => {
    let res = await httpPost("/config/tokenize/get/list");
    const options = [];
    if (res.code == 0) {
      res.data.forEach((item) => {
        if (item.tokenizeLanguage == 2) {
          options.push({
            label: item.name,
            value: item.objectId
          });
        }
      });
    }
    return options;
  },
  // 获取类型为分词字段类型的fields
  getTokenizeTypeOfFields: async (params) => {
    console.log("get field params", params.node, params.formerNodes);
    // 从records输入获取fields
    const fields = params.formerNodes.records[0].data.fields.data || [];
    const options = [];
    // 在options加入选项
    fields.forEach((field) => {
      if (
        field.type == FIELD_TYPE_CONST.FIELD_TYPE_TOKENIZE_RESULT_ZH ||
        field.type == FIELD_TYPE_CONST.FIELD_TYPE_TOKENIZE_RESULT_EN
      ) {
        // console.log("field:::", field);

        options.push({
          value: field.fieldName,
          label: field.fieldLabel
        });
      }
    });
    return options;
  },
  // 获取类型为分词字段类型(中文）的fields
  getTokenizeTypeOfFieldsZH: async (params) => {
    // console.log("get field params", params.node, params.formerNodes);
    // 从records输入获取fields
    const fields = params.formerNodes.records[0].data.fields.data || [];
    const options = [];
    // 在options加入选项
    fields.forEach((field) => {
      if (field.type == FIELD_TYPE_CONST.FIELD_TYPE_TOKENIZE_RESULT_ZH) {
        // console.log("field:::", field);

        options.push({
          value: field.fieldName,
          label: field.fieldLabel
        });
      }
    });
    return options;
  },
  //
  // 获取分词方法
  getTokenizeMethodOptions: (params) => {
    return [
      {
        label: "基本分词",
        value: 1
      },
      {
        label: "精准分词",
        value: 2
      },
      {
        label: "nlp分词",
        value: 3
      },
      {
        label: "面向索引分词",
        value: 4
      }
    ];
  },
  // 获取分词粒度
  getTokenizeGranularityOptions: (params) => {
    return [
      {
        label: "包含单字",
        value: 1
      },
      {
        label: "至少双字",
        value: 2
      }
    ];
  },
  // 获取可选分词词典
  getSelectableTokenizeDictionaryList: async (params) => {
    let url = "/config/tokenize/dictionary/get/noPageList";
    let result = [];
    let res = await httpPost(url);
    if (res.code == 0) {
      res.data.forEach((item) => {
        result.push({
          value: item.objectId,
          label: item.name
        });
      });
    }
    return result;
  },
  // 获取事件类型的fields
  getDateTimeTypeOfFields: async (params) => {
    // console.log("get field params", params.node, params.formerNodes);
    // 从records输入获取fields
    const fields = params.formerNodes.records[0].data.fields.data || [];
    const options = [];
    // 在options加入选项
    fields.forEach((field) => {
      if (field.type == FIELD_TYPE_CONST.FIELD_TYPE_DATETIME) {
        options.push({
          value: field.fieldName,
          label: field.fieldLabel
        });
      }
    });
    return options;
  },
  // 获取用户可操作的原生的类型的field
  getOriginTypeOfFields: async (params) => {
    // 从records输入获取fields
    const fields = params.formerNodes.records[0].data.fields.data || [];
    const options = [];
    // 在options加入选项
    fields.forEach((field) => {
      if (
        field.type == FIELD_TYPE_CONST.FIELD_TYPE_TEXT_ZH ||
        field.type == FIELD_TYPE_CONST.FIELD_TYPE_LONG_TEXT_ZH ||
        field.type == FIELD_TYPE_CONST.FIELD_TYPE_CATEGORY_TEXT ||
        field.type == FIELD_TYPE_CONST.FIELD_TYPE_NUM ||
        field.type == FIELD_TYPE_CONST.FIELD_TYPE_DATETIME ||
        field.type == FIELD_TYPE_CONST.FIELD_TYPE_URL
      ) {
        options.push({
          value: field.fieldName,
          label: field.fieldLabel
        });
      }
    });
    return options;
  },
  // 获取可以筛选的依据字段
  getFilterableFields: async (params) => {
    // 从records输入获取fields
    const fields = params.formerNodes.records[0].data.fields.data || [];
    const options = [];
    // 在options加入选项
    fields.forEach((field) => {
      if (
        field.type == FIELD_TYPE_CONST.FIELD_TYPE_TEXT_ZH ||
        field.type == FIELD_TYPE_CONST.FIELD_TYPE_LONG_TEXT_ZH ||
        field.type == FIELD_TYPE_CONST.FIELD_TYPE_CATEGORY_TEXT ||
        field.type == FIELD_TYPE_CONST.FIELD_TYPE_NUM ||
        field.type == FIELD_TYPE_CONST.FIELD_TYPE_DATETIME ||
        field.type == FIELD_TYPE_CONST.FIELD_TYPE_URL ||
        field.type == FIELD_TYPE_CONST.FIELD_TYPE_ARRAY ||
        field.type == FIELD_TYPE_CONST.FIELD_TYPE_CAT_ARRAY
      ) {
        options.push({
          value: field.fieldName,
          label: field.fieldLabel,
          type: field.type
        });
      }
    });
    return options;
  },
  getViewStyleOfWordCloud: async (params) => {
    return [
      {
        value: 1,
        label: "左图右表"
      }
      //TODO
      // {
      //   value: 2,
      //   label: "左表右图"
      // },
      // {
      //   value: 3,
      //   label: "图"
      // },
      // {
      //   value: 4,
      //   label: "表"
      // }
    ];
  },
  getShapePictureOptions: async (params) => {
    let res = await httpPost("/module/list/picture");
    if (res.code == 0) {
      // console.log("/module/list/picture", res.data);
    }
    return res.data;
  },
  getWidthOptions: async (params) => {
    return [
      {
        value: 24,
        label: "1"
      },
      {
        value: 16,
        label: "2/3"
      },
      {
        value: 12,
        label: "1/2"
      },
      {
        value: 8,
        label: "1/3"
      },

      {
        value: 6,
        label: "1/4"
      },
      {
        value: 4,
        label: "1/6"
      }
    ];
  },
  getNotEmptyEncodingDictionaryList: async (params) => {
    let res = await httpPost("/config/encoding/dictionary/get/list", {
      ifOnlyGetNotNull: true,
      pageNum: 1,
      pageSize: 10000
    });
    // let res = await httpPost("/config/encoding/dictionary/get/list/not/null");
    let result = [];
    if (res.code == 0) {
      res.data.encodingDictionaryList.forEach((item) => {
        result.push({
          value: item.objectId,
          label: item.name
        });
      });
    }
    return result;
  },
  // 获取文本聚类模块中的聚类算法列表
  getMethodOptionOfCluster: async (params) => {
    let methodList = [
      { value: 1, label: "DBSCAN" },
      { value: 2, label: "层次聚类" },
      { value: 3, label: "BIRCH聚类" },
      { value: 4, label: "KMeans聚类" },
      { value: 5, label: "小批次KMeans聚类" },
      { value: 6, label: "均值偏移聚类" },
      { value: 7, label: "光谱聚类" },
      { value: 8, label: "高斯混合聚类" },
      { value: 9, label: "ISODATA聚类" }
    ];
    return methodList;
  },
  //获取文本分类模型
  getAvailableModelList: async (params) => {
    let res = await httpPost("/config/category/dataset/model/get/list", {
      ifOnlyGetFinishedModel: true,
      pageSize: 10000
    });
    // let res = await httpPost("/config/category/model/get/list/finish");
    let result = [];
    if (res.code == 0) {
      // console.log("get availableModellist", res.data);
      return res.data.categoryModelList.map((item) => {
        return {
          value: item.objectId,
          label: item.name,
          categoryLanguage: item.categoryLanguage
        };
      });
    }
  },
  // 获取可用的情感词典
  getAvailableSentimentDictionaryList: async (params) => {
    let res = await httpPost("/config/sentiment/dictionary/get/list", {
      ifOnlyGetNotNull: true,
      pageSize: 10000
    });
    if (res.code == 0) {
      // console.log("sentiment dic", res.data);
      return res.data.sentimentDictionaryList.map((item) => {
        return {
          value: item.objectId,
          label: item.name
        };
      });
    }
  },
  // 获取可用的事件词典
  getAvailableEventDictionaryList: async (params) => {
    let res = await httpPost("/config/event/dictionary/get/list", {
      ifOnlyGetNotNull: true,
      pageSize: 10000
    });
    if (res.code == 0) {
      // console.log("event dic", res.data);
      return res.data.eventDictionaryList.map((item) => {
        return {
          value: item.objectId,
          label: item.name
        };
      });
    }
  },
  getLevelOfEncoding: async (params) => {
    let options = [
      { label: "全文级", value: 0 },
      { label: "段落级", value: 1 },
      { label: "句子级", value: 2 }
    ];
    return options;
  },
  //获取父文件夹
  getParentFolderIdOfSaveAsTextLibraryModule: async () => {
    let res = await httpPost("/view/get/structure");
    if (res.code == 0) {
      // console.log("父文件夹id", res);
      return [res.data];
      // fileState.fileTree = [res.data];
    }
  },
  getCommonWordListOptions: async () => {
    // let res = await httpPost("/config/keyword/group/get/list/not/null");
    let res = await httpPost("/config/common/table/get/list", {
      ifOnlyGetNotNull: true,
      pageNum: 1,
      pageSize: 10000
    });
    if (res.code == 0) {
      // console.log("keywords group", res.data);
      return res.data.commonWordListList.map((item) => {
        return {
          value: item.objectId,
          label: item.name
        };
      });
    }
  },
  getIfReverseOptions: async () => {
    return [
      { label: "是", value: true },
      { label: "否", value: false }
    ];
  },
  getSortableFields: async (params) => {
    // 从records输入获取fields
    const fields = params.formerNodes.records[0].data.fields.data || [];
    const options = [];
    // 在options加入选项
    fields.length > 0 &&
      fields.forEach((field) => {
        if (
          field.type == FIELD_TYPE_CONST.FIELD_TYPE_CATEGORY_TEXT ||
          field.type == FIELD_TYPE_CONST.FIELD_TYPE_NUM
        ) {
          options.push({
            value: field.fieldName,
            label: field.fieldLabel
          });
        }
      });
    return options;
  },
  getFormerNodesList: async (params) => {
    let node = params.node;
    let formerNodes = params.formerNodes[node.data.baseInput];
    let result = formerNodes.map((node) => {
      return { id: node.data.moduleIndex, label: node.data.label };
    });
    // console.log("sortlist options", result);
    return result;
  }
};

const fieldsSourceFunctionMap = {
  // 继承前置节点的fields
  getFieldsOfFormerNode: async (params) => {
    const node = params.node;
    // if (!node.data.baseInput) {
    //   return;
    // }
    const formerNode = params.formerNodes[node.data.baseInput][0]; // 从records输入端口获得前置节点
    const formerFields = formerNode.data.fields.data; // 从前置节点中获取fields
    node.data.fields.data = JSON.parse(JSON.stringify(formerFields));
  },
  getFieldsOfTextLibrary: async (params) => {
    let libraryId = params.node.data.configs.textLibraryId.value;
    if (libraryId) {
      let res = await httpPost("/lib/field/list/" + libraryId);
      params.node.data.fields.data = res.data;
      // console.log("res", res.data);
    }
  },
  getFieldsOfTokenize: async (params) => {
    const node = params.node; // 当前节点
    const baseInput = node.data.baseInput;
    const formerNode = params.formerNodes[baseInput][0]; // 从records输入端口获得前置节点
    const formerFields = formerNode.data.fields.data; // 从前置节点中获取fields
    const newField = {}; // 存储新生城的field
    node.data.fields.data = JSON.parse(JSON.stringify(formerFields)); // 深拷贝formerNode的fields，防止对当前节点fields改变后影响前置节点的fields
    const fieldValue = node.data.configs.field.value; // 新field的value（options中）
    if (fieldValue) {
      const fieldLabel = node.data.configs.field.options.find((item) => {
        return item.value == fieldValue;
      }).label; // 新field的label（options中）
      newField.fieldName = "____tokenize_" + fieldValue + "____"; // 拼接获得新field的fieldName
      newField.fieldLabel = fieldLabel + "_分词结果"; // 拼接获得新field的fieldLabel
      newField.type = FIELD_TYPE_CONST.FIELD_TYPE_TOKENIZE_RESULT_ZH; // 设置新field的type
      addField(node, newField); // 向当前节点中加入new field或修改同名field
    }

    // node.fields.data.push(newField);
  },
  getFieldsOfTokenizeOfEn: async (params) => {
    const node = params.node; // 当前节点
    const baseInput = node.data.baseInput;
    const formerNode = params.formerNodes[baseInput][0]; // 从records输入端口获得前置节点
    const formerFields = formerNode.data.fields.data; // 从前置节点中获取fields
    const newField = {}; // 存储新生城的field
    node.data.fields.data = JSON.parse(JSON.stringify(formerFields)); // 深拷贝formerNode的fields，防止对当前节点fields改变后影响前置节点的fields
    const fieldValue = node.data.configs.field.value; // 新field的value（options中）
    if (fieldValue) {
      const fieldLabel = node.data.configs.field.options.find((item) => {
        return item.value == fieldValue;
      }).label; // 新field的label（options中）
      newField.fieldName = "____tokenize_" + fieldValue + "____"; // 拼接获得新field的fieldName
      newField.fieldLabel = fieldLabel + "_分词结果"; // 拼接获得新field的fieldLabel
      newField.type = FIELD_TYPE_CONST.FIELD_TYPE_TOKENIZE_RESULT_EN; // 设置新field的type
      addField(node, newField); // 向当前节点中加入new field或修改同名field
    }

    // node.fields.data.push(newField);
  },
  getFieldsOfsaveAsTextLibraryModule: async (params) => {
    const node = params.node; // 当前节点
    const formerNode = params.formerNodes[node.data.baseInput][0]; // 从records输入端口获得前置节点
    const formerFields = formerNode.data.fields.data; // 从前置节点中获取fields

    node.data.fields.data = JSON.parse(JSON.stringify(formerFields)); // 深拷贝formerNode的fields，防止对当前节点fields改变后影响前置节点的fields
    // node.data.fields.data.forEach(field => {

    // });
  },
  //将字段全部替换为changes of fields中的数据
  replaceWithChangesOfFields: async (params) => {
    const node = params.node;
    node.data.fields.data = node.data.fields.changesOfFields;
  },
  // 在fields中直接增加changesOfFields
  addChangesOfFields: async (params) => {
    const node = params.node;
    const formerNode = params.formerNodes[node.data.baseInput][0]; // 从records输入端口获得前置节点
    const formerFields = formerNode.data.fields.data; // 从前置节点中获取fields
    const changesOfFields = node.data.fields.changesOfFields;
    node.data.fields.data = JSON.parse(JSON.stringify(formerFields));
    changesOfFields.forEach((field) => {
      addField(node, field);
    });

    // node.data.fields.data = [...formerFields, ...changesOfFields];
  },
  // 获得情感分析的fields
  getFieldsOfSentimentAnalysis: async (params) => {
    // console.log("get fields of sentiment dic");
    const node = params.node;
    const formerNode = params.formerNodes[node.data.baseInput][0];
    const formerFields = formerNode.data.fields.data; // 从前置节点中获取fields
    let changesOfFields = [];
    if (node.data.configs.sentimentDictionary.value != null) {
      const res = await httpPost(
        "/config/sentiment/dictionary/word/type/get/list",
        { objectId: node.data.configs.sentimentDictionary.value }
      );
      // console.log("sentiment list", res.data);
      if (res.code == 0) {
        const resultData = res.data;
        changesOfFields = resultData.map((item) => {
          return {
            fieldName: "____tendency_" + item + "____",
            fieldLabel: "情感倾向：" + item,
            type: FIELD_TYPE_CONST.FIELD_TYPE_NUM
          };
        });
      }
    }
    node.data.fields.data = [...formerFields, ...changesOfFields];
  }
};
const configSourceFunctionMap = {
  getConfigsOfHorizontalLayout: (params) => {
    const node = params.node;
    const formerNodes = params.formerNodes.visual_module;
    const oldConfigs = node.data.configs;
    const newConfigs = { sortList: oldConfigs.sortList };
    const DEFAULT_WIDTH = 24;

    for (let i in formerNodes) {
      let formerNode = formerNodes[i];
      let key = formerNode.id + "_module_width";

      let moduleName = formerNode.data.moduleName;
      let oldConfig = oldConfigs[key];
      // console.log(
      //   "old config",
      //   oldConfigs,
      //   formerNode.id + "-" + moduleName,
      //   oldConfig
      // );
      // debugger;
      newConfigs[key] = {
        label: formerNode.data.label + "的宽度",
        name: key,
        value: oldConfig ? oldConfig.value : DEFAULT_WIDTH,
        source: "getWidthOptions",
        showOnDesignView: false,
        componentType: "singleSelect"
      };
    }
    // console.log("get config former nodes", formerNodes);
    return newConfigs;
  }
};
// const FIELD_TYPE_TEXT_ZH = 1; //文本
// const FIELD_TYPE_LONG_TEXT_ZH = 2; //长文本
// const FIELD_TYPE_CATEGORY_TEXT = 3; //分类文本
// const FIELD_TYPE_NUM = 4; //数值
// const FIELD_TYPE_DATETIME = 5; //日期时间
// const FIELD_TYPE_URL = 6; //链接
// const FIELD_TYPE_TOKENIZE_RESULT = 7; //分词结果
// const FIELD_TYPE_ARRAY = 8; //数组
// const FIELD_TYPE_CAT_ARRAY = 9; //类别数组
// const FIELD_TYPE_OBJECT = 10; //对象
// const FIELD_TYPE_VECTORIZE_RESULT = 11; //向量化结果
// ===========字段类型常量end=====================================

export {
  optionSourceFunctionMap,
  fieldsSourceFunctionMap,
  configSourceFunctionMap
};
