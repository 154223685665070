<template>
  <div class="main-box" style="position: relative">
    <!-- {{ node.data.label }} -->
    <el-form
      ref="configFormRef"
      :model="configForm"
      label-width="120px"
      :rules="rules"
      @submit.prevent
      :disabled="modelStatus == 1"
      :class="[{ ifHasHelpText: 'config-form' }]"
    >
      <el-form-item
        v-for="(item, index) in configFormInfo"
        :key="'config' + index"
        :prop="index"
        :label="item.label"
        v-show="
          configFormInfo[index].ifShow
            ? ifShowFunctionMap[configFormInfo[index].ifShow](configForm)
            : true
        "
      >
        <template v-slot:label>
          {{ item.label }}
          <el-tooltip
            v-if="hint[item.name]"
            effect="light"
            placement="bottom-start"
            :content="hint[item.name]"
          >
            <i class="el-icon-question"></i>
          </el-tooltip>
          <!-- <el-tooltip
            v-if="hint[item.name]"
            effect="light"
            placement="bottom-start"
            content="hint[item.name]"
          >
          
            <i class="el-icon-question"></i>
          </el-tooltip> -->
        </template>

        <!-- {{ configForm[index] }}{{ configFormInfo[index].options }} -->
        <!-- lineTextInput -->
        <div v-if="item.componentType == 'lineTextInput'">
          <el-input
            v-model="configForm[index]"
            @change="changeInput"
            style="width: 222px"
          ></el-input>
        </div>
        <!-- singleSelect -->
        <div v-if="item.componentType == 'singleSelect'">
          <!-- {{
            configFormInfo[index].ifShow
              ? ifShowFunctionMap[configFormInfo[index].ifShow](configForm)
              : true
          }}
          {{ configFormInfo[index].ifShow }} -->
          <el-select
            :clearable="configFormInfo[index].clearable"
            v-model="configForm[index]"
            @change="changeSelect"
          >
            <template v-if="!configFormInfo[index].languageField">
              <el-option
                v-for="(option, oIndex) in item.options"
                :key="oIndex"
                :value="option.value"
                :label="option.label"
              ></el-option>
            </template>
            <template v-else>
              <el-option
                v-for="(option, oIndex) in item.options"
                :key="oIndex"
                :value="option.value"
                :label="
                  getLanguage(option[configFormInfo[index].languageField]) +
                  option.label
                "
              ></el-option>
            </template>
          </el-select>
        </div>
        <!-- singleFieldSelect -->
        <div v-if="item.componentType == 'singleFieldSelect'">
          <el-select v-model="configForm[index]" @change="changeSelect">
            <el-option
              v-for="(option, index) in item.options"
              :key="index"
              :value="option.value"
              :label="option.label"
            ></el-option>
          </el-select>
        </div>
        <!-- multiSelect -->
        <div v-if="item.componentType == 'multiSelect'">
          <el-select
            v-model="configForm[index]"
            multiple
            @change="changeSelect"
          >
            <el-option
              v-for="(option, index) in item.options"
              :key="index"
              :value="option.value"
              :label="option.label"
            ></el-option>
          </el-select>
        </div>
        <!-- radio -->
        <div v-if="item.componentType == 'radio'">
          <el-radio-group v-model="configForm[index]" @change="changeSelect">
            <el-radio
              v-for="(option, index) in item.options"
              :key="index"
              :label="option.value"
            >
              {{ option.label }}
            </el-radio>
          </el-radio-group>
        </div>
        <!-- clusterNumInput -->
        <div v-if="item.componentType == 'clusterNumInput'">
          <el-input-number
            v-model="configForm[index]"
            @change="changeInput"
            :min="configFormInfo[index].minValue"
            :max="configFormInfo[index].maxValue"
            :precision="0"
          ></el-input-number>
        </div>
        <!-- numberInput -->
        <div v-if="item.componentType == 'numberInput'">
          <el-input-number
            v-model="configForm[index]"
            @change="changeInput"
            :min="configFormInfo[index].minValue"
            :max="configFormInfo[index].maxValue"
            :precision="configFormInfo[index].precision"
            :step="configFormInfo[index].step"
          ></el-input-number>
        </div>
        <!-- topicNumInput -->
        <div v-if="item.componentType == 'topicNumInput'">
          <el-radio-group
            v-model="configFormInfo[index].topicRadio"
            @change="(topicRadio) => changeTopicRadio(topicRadio, index)"
          >
            <el-radio label="auto">自动计算最优</el-radio>
            <el-radio label="customize">
              自定义
              <el-input-number
                v-if="configFormInfo[index].topicRadio == 'customize'"
                v-model="configForm[index]"
                :min="configFormInfo[index].minValue"
                :max="configFormInfo[index].maxValue"
                :precision="0"
                @change="changeInput"
              ></el-input-number>
            </el-radio>
          </el-radio-group>
        </div>
        <!-- sampleNumInput -->
        <div v-if="item.componentType == 'sampleNumInput'">
          <el-radio-group
            v-model="configFormInfo[index].sampleType"
            @change="(sampleType) => changeSampleType(sampleType, index)"
          >
            <el-radio label="amount">
              取样数量
              <el-input-number
                v-if="configFormInfo[index].sampleType == 'amount'"
                @change="changeInput"
                v-model="configForm[index]"
                :min="configFormInfo[index].minValue"
                :precision="0"
              ></el-input-number>
            </el-radio>
            <el-radio label="percent">
              取样比例
              <el-input-number
                v-if="configFormInfo[index].sampleType == 'percent'"
                @change="changeInput"
                v-model="configForm[index]"
                :min="configFormInfo[index].minValue"
                :max="configFormInfo[index].maxValue"
                :step="configFormInfo[index].step"
                :precision="configFormInfo[index].precision"
              ></el-input-number>
            </el-radio>
          </el-radio-group>
        </div>
        <!-- folderSelect -->
        <div v-if="item.componentType == 'folderSelect'">
          <el-tree
            ref="treeRef"
            :data="configFormInfo[index].options"
            :props="configFormInfo[index].defaultProps"
            node-key="id"
            :check-strictly="true"
            :default-expanded-keys="[0]"
            show-checkbox
            highlight-current
            accordion
            draggable
            @check-change="
              (data, checked, node) => {
                handleCheckChange(data, checked, node, index);
              }
            "
          />
        </div>
        <!-- filterGenerator -->

        <div v-if="item.componentType == 'filterGenerator'">
          <!-- <span v-for="(cond, index) in conditions" :key="index"> -->
          <span v-for="(expression, eIndex) in configForm[index]" :key="eIndex">
            <!-- {{ configForm[index][eIndex] }} -->
            <el-row>
              <el-select
                v-model="configForm[index][eIndex].field"
                @change="(field) => changeFilterField(field, index, eIndex)"
                placeholder="请选择字段"
                size="small"
                style="width: 250px; margin-left: 10px; margin-top: 10px"
              >
                <el-option
                  v-for="option in item.options"
                  :key="option.value"
                  :label="option.label"
                  :value="option.value"
                ></el-option>
              </el-select>
              <el-select
                v-if="configForm[index][eIndex].field"
                v-model="configForm[index][eIndex].op"
                @change="changeFilterOpt"
                placeholder="请选择条件"
                size="small"
                style="width: 150px; margin-left: 10px; margin-top: 10px"
              >
                <el-option
                  v-for="opOption in item.opOptions[
                    configForm[index][eIndex].type
                  ]"
                  :key="opOption.value"
                  :label="opOption.label"
                  :value="opOption.value"
                ></el-option>
              </el-select>

              <el-input
                v-if="
                  configForm[index][eIndex].op &&
                  configForm[index][eIndex].type != 5
                "
                :maxlength="255"
                v-model="configForm[index][eIndex].value"
                @change="(val) => changeFilterValue(val, index, eIndex)"
                placeholder="请输入值"
                size="small"
                style="width: 250px; margin-left: 10px; margin-top: 10px"
                clearable
              />
              <el-date-picker
                v-if="
                  configForm[index][eIndex].op &&
                  configForm[index][eIndex].type == 5
                "
                v-model="configForm[index][eIndex].value"
                format="YYYY-MM-DD HH:mm:ss"
                value-format="YYYY-MM-DD HH:mm:ss"
                type="datetime"
                style="width: 250px; margin-left: 10px; margin-top: 10px"
                size="small"
              ></el-date-picker>
              <el-select
                v-if="configForm[index][eIndex].value"
                v-model="configForm[index][eIndex].compound_op"
                @change="
                  (cmdOption) => changeCMDOption(cmdOption, index, eIndex)
                "
                placeholder="连接条件"
                size="small"
                style="width: 150px; margin-left: 10px; margin-top: 10px"
                clearable
              >
                <el-option
                  v-for="cmdOption in item.cmdOptions"
                  :key="cmdOption.value"
                  :label="cmdOption.label"
                  :value="cmdOption.value"
                ></el-option>
              </el-select>
              <span>
                <!-- <i
                      class="el-icon-circle-plus-outline"
                      @click="(e) => addSingleExpression(e, index, eIndex)"
                    ></i> -->
                <i
                  v-if="configForm[index].length != 1"
                  class="el-icon-remove-outline"
                  @click="(e) => removeSingleExpression(e, index, eIndex)"
                  style="position: relative; top: 4px; left: 10px"
                ></i>
              </span>
            </el-row>
          </span>
        </div>
        <!-- pictureSelect -->
        <div
          v-if="item.componentType == 'pictureSelect'"
          class="picture-select"
        >
          <el-upload
            v-if="showOptions"
            :action="uploadUrl"
            :on-error="uploadError"
            :on-success="
              (response, file, fileList) =>
                uploadSuccess(response, file, fileList, item)
            "
            :on-preview="handlePictureCardPreview"
            :headers="headers"
            list-type="picture-card"
            :file-list="item.options"
            :multiple="false"
          >
            <template #default>
              <i class="el-icon-plus"></i>
            </template>
            <template #file="{ file }">
              <div>
                <!-- <img
                  v-if="file.thumbnailBase64String"
                  class="el-upload-list__item-thumbnail"
                  :src="'data:image/jpg;base64,' + file.thumbnailBase64String"
                  :alt="file.name"
                /> -->
                <!-- {{ file.objectId == configForm[index] }} -->

                <img
                  class="el-upload-list__item-thumbnail"
                  :src="file.url"
                  :alt="file.name"
                />
                <label
                  class="el-upload-list__item-status-label"
                  v-if="file.objectId == configForm[index]"
                >
                  <i class="el-icon el-icon--upload-success el-icon--check">
                    <svg
                      class="icon"
                      width="200"
                      height="200"
                      viewBox="0 0 1024 1024"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill="white"
                        d="M406.656 706.944L195.84 496.256a32 32 0 10-45.248 45.248l256 256 512-512a32 32 0 00-45.248-45.248L406.592 706.944z"
                      ></path>
                    </svg>
                  </i>
                </label>
                <span
                  class="el-upload-list__item-actions"
                  @click="changePicture(file, index)"
                >
                  <span
                    class="el-upload-list__item-preview"
                    @click.stop="handlePictureCardPreview(file)"
                  >
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <!-- <span
                    v-if="!disabled"
                    class="el-upload-list__item-delete"
                    @click="handleDownload(file)"
                  >
                    <i class="el-icon-delete"></i>
                  </span> -->
                  <!-- <span
                    class="el-upload-list__item-delete"
                    @click.stop="handleRemove(file, item, index)"
                  >
                    <i class="el-icon-delete"></i>
                  </span> -->
                </span>
              </div>
            </template>
          </el-upload>
        </div>
        <!-- draggableList -->
        <div v-if="item.componentType == 'draggableList'">
          <el-tree
            ref="treeRef"
            node-key="id"
            class="filter-tree"
            :data="configForm[index]"
            default-expand-all
            draggable
            :allow-drop="checkAllowDrop"
            @node-drop="afterDropNode"
          />
        </div>
      </el-form-item>
    </el-form>
    <div class="help-text" v-if="ifHasHelpText">
      <div class="help-title">
        <div class="help-block"></div>
        分析帮助
      </div>
      <div class="help-content">
        {{ helpContent }}
      </div>
    </div>
  </div>
  <el-dialog width="500px" v-model="pictureDialogVisible">
    <img width="460" :src="dialogImageUrl" />
  </el-dialog>
</template>

<script>
// 引入后才可以使用vue3的响应式，以及在不同的生命周期执行操作（如onMounted）
import {
  ref,
  reactive,
  toRefs,
  getCurrentInstance,
  watch,
  onMounted,
  computed,
  nextTick,
  forceUpdate
} from "vue";
import {
  optionSourceFunctionMap,
  fieldsSourceFunctionMap,
  configSourceFunctionMap
} from "@/constant/analysis/sourceFunctionMap";
import { ElMessage } from "element-plus";
import { httpPost } from "@/api/httpService";
// import { duration } from "moment";
// import { async } from "@antv/x6/lib/registry/marker/async";
// import { clearSelection } from "@antv/x6/lib/util/dom/selection";
// import { call } from "@antv/x6/lib/util/function/function";
// import ConfigTemplate from "./ConfigTemplate.vue";
import { baseUrl } from "@/api/httpService";
import VueCookies from "vue-cookies";
import { tokenizeLanguageList } from "@/constant/analysis/tokenizeLanguage.js";
import { ifShowFunctionMap } from "@/constant/analysis/ifShowFunctionMap.js";
export default {
  props: ["node", "formerNodes", "modelInfo", "isChanged", "modelStatus"], // 在这里声明接收哪些prop，并从setup（props）的props中传入setup
  components: {},
  emits: ["saveInModuleNode", "setIsChange", "saveInModuleNodeAtOnce"],
  // 类似于created，但比created更早，并且没有this的概念，需要使用ref构造基础类型，使用active构造对象类型，并在setup中将响应式数据return出去
  setup(props, { emit }) {
    console.log("config template", props.node);
    let { ctx: that } = getCurrentInstance();
    const treeRef = ref(null);
    const configFormRef = ref(null);
    let isConfigChanged = ref(props.isChanged);
    // 指向父组件中module节点的configs，更新相关内容，如options,同时防止子组件将父组件的值改变
    let node = reactive(JSON.parse(JSON.stringify(props.node)));
    let formerNodes = reactive(JSON.parse(JSON.stringify(props.formerNodes)));
    let configForm = reactive({});
    const showOptions = ref(false); // 控制某些配置项的options在更新后才会加载
    // 生成configForm用于绑定value
    node.data.configs = _getConfigs();
    let configFormInfo = reactive(node.data.configs);
    const ifPersistent = ref(node.data.ifPersistent);

    let hasHelpTextType = [4];
    const ifHasHelpText = ref(hasHelpTextType.includes(node.data.moduleType));

    const hint = reactive({
      topic_theta: `主题权重是表明在本次分析中词组中包含的词语的主题含义显著程度对词组可能成为短语的概率的影响程度的参数。设置为0时，表示不考虑主题含义显著程度。在0和1之间，随着主题权重大小增加，对应的，词语的主题含义显著程度对结果的影响就越大，最大为1。`
    });
    const clusterHelpTextMap = {
      1: {
        label: "DBSCAN",
        text: "DBSCAN是一个比较有代表性的基于密度的聚类算法。它将若干个高密度区域的数据连接成同一类，不同的类被低密度区域划分开来。由于这个特性，它自其分析结果中会存在不属于任何类的离群点。通过控制密度阈值（当搜索区域内数据的浓度高于这个值时便被判定为高浓度区域）和搜索半径（控制搜索区域的大小），可以控制聚类结果的粒度。适用于能够准确把握文本数值化后在向量空间中的距离衡量的情况。"
      },
      2: { label: "层次聚类", text: "暂无说明" },
      3: {
        label: "BIRCH聚类",
        text: "BIRCH聚类算法运用聚类特征树进行快速的聚类，它是层次聚类和其他聚类算法结合的成果，适合于数据量大、类别数较多的情况。BIRCH聚类算法的运行速度很快，只需要单遍扫描数据就能进行聚类。"
      },
      4: {
        label: "KMeans聚类",
        text: "k-means聚类算法是一种将数据分为指定的k组的聚类分析算法。它在数据中寻找k个中心点，并按照其他数据到中心点的距离对数据进行分组。适用于类与类之间区别较为明确的数据的聚类。"
      },
      5: { label: "小批次KMeans聚类", text: "暂无说明" },
      6: { label: "均值偏移聚类", text: "暂无说明" },
      7: { label: "光谱聚类", text: "暂无说明" },
      8: { label: "高斯混合聚类", text: "暂无说明" },
      9: { label: "ISODATA聚类", text: "暂无说明" }
    };
    // const helpContent = computed(() => {
    //   let result = null;
    //   switch (props.node.data.moduleType) {
    //     case 4:
    //       console.log("configForm", configForm);
    //       console.log("configFormInfo", configFormInfo);

    //       // 聚类说明
    //       result = configForm;
    //       break;

    //     default:
    //       break;
    //   }
    //   return result;
    // });
    const helpContent = ref("暂无说明");
    const headers = ref({
      // Accept: "multipart/form-data",
      // "Content-Type": "multipart/form-data",
      Authorization: VueCookies.get("tokenWeb")
        ? "Bearer " + VueCookies.get("tokenWeb")
        : ""
    });
    const pictureDialogVisible = ref(false); //图片弹窗
    const dialogImageUrl = ref("");
    const handlePictureCardPreview = (file) => {
      // console.log("preview", file);
      dialogImageUrl.value = file.url;
      pictureDialogVisible.value = true;
    };
    const handleRemove = (file, item, index) => {
      // console.log("remove pic", file, item.options);
      let deleteIndex = item.options.findIndex((item) => {
        return item.objectId == file.objectId;
      });
      item.options.splice(deleteIndex, 1);
      // console.log("delete index", deleteIndex, item.options);

      if (configForm[index] == file.objectId) {
        // console.log(".//");
        configForm[index] = null;

        _handleChange();
      }

      httpPost("/module/delete/picture/" + file.objectId).then((res) => {
        // console.log("delete pic", res);
      });
    };

    const state = reactive({ treeRef });
    watch(configForm, (newValue, oldValue) => {
      console.log("watch props", newValue);
      switch (props.node.data.moduleType) {
        case 4:
          // console.log("11111");
          if (newValue.method) {
            helpContent.value = clusterHelpTextMap[newValue.method].text;
          }

          break;

        default:
          break;
      }
      // that.$forceUpdate();
      // console.log(that.$forceUpdate());
      // _getConfigForm();
      // nextTick(() => {
      //   init(newValue);
      // });
    });
    function init(_props) {
      isConfigChanged.value = _props.isChanged;
      node = reactive(JSON.parse(JSON.stringify(_props.node)));
      formerNodes = reactive(JSON.parse(JSON.stringify(_props.formerNodes)));
      node.data.configs = _getConfigs();
      configFormInfo = reactive(node.data.configs);
      // console.log("configFormINfo", configFormInfo);
      _getConfigForm();
      setTimeout(() => {
        getOptionsBySource();
      }, 0);
    }
    //选择el-tree节点
    function handleCheckChange(data, checked, node, index) {
      if (treeRef.value.getCheckedNodes().length == 0) {
        configForm[index] = null;
        return;
      }
      if (checked) {
        treeRef.value.setCheckedNodes([data]);
        configForm[index] = data.id;
      }
    }

    // 更新/获取最新的configs
    function _getConfigs() {
      const configSource = node.data.configSource;
      const configFunction = configSourceFunctionMap[configSource];
      if (!configSource) {
        //没有configsource则直接根据configs进行赋值
        return node.data.configs;
      } else {
        //有configsource则根据configs进行赋值
        return configFunction({
          node: node,
          formerNodes: formerNodes
        });
      }
    }

    function _getConfigForm() {
      for (var k in configFormInfo) {
        configForm[k] = configFormInfo[k].value;
      }
      // console.log("configForm", configFormInfo, configForm);
    }
    // 根据source获取options
    async function getOptionsBySource() {
      // console.log("get option", configFormInfo);
      for (var c in configFormInfo) {
        const sourceFunc = optionSourceFunctionMap[configFormInfo[c].source];
        if (sourceFunc) {
          configFormInfo[c].options = await sourceFunc({
            node: node,
            formerNodes: formerNodes,
            analysisId: props.modelInfo.analysisId
          });
          // console.log("configForm options", configFormInfo[c].options);
        }
      }
      showOptions.value = true;
    }

    const rules = reactive({
      textLibraryId: {
        required: true,
        message: "请选择读入文本库",
        trigger: "change"
      },
      eventDictionaryId: {
        required: true,
        message: "请选择事件词典",
        trigger: "change"
      },
      field: {
        required: true,
        message: "请选择待分词字段",
        trigger: "change"
      },
      tokenizeConfigId: {
        required: true,
        message: "请选择分词配置",
        trigger: "change"
      },
      textLibraryName: {
        required: true,
        message: "请填写文本库名称",
        trigger: "blur"
      },
      title: {
        required: false,
        message: "请填写标题",
        trigger: "blur"
      },
      viewStyle: {
        required: true,
        message: "请选择显示样式",
        trigger: "change"
      },
      analysisFields: {
        required: true,
        message: "请选择待分析字段",
        trigger: "change"
      },
      analysisField: {
        required: true,
        message: "请选择待分析字段",
        trigger: "change"
      },
      level: {
        required: true,
        message: "请选择编码层级",
        trigger: "change"
      },
      encodingRuleDictionaryId: {
        required: true,
        message: "请选择编码词典",
        trigger: "change"
      },
      modelId: {
        required: true,
        message: "请选择分类模型",
        trigger: "change"
      },
      topicTheta: {
        required: true,
        message: "请选择主题权重",
        trigger: "blur"
      },
      clusterNum: {
        required: true,
        message: "请选择聚类数目",
        trigger: "blur"
      },
      method: {
        required: true,
        message: "请选择聚类算法",
        trigger: "change"
      },
      sentimentDictionary: {
        required: true,
        message: "请选择情感词典",
        trigger: "change"
      },
      keywordsGroupId: {
        required: true,
        message: "请选择关键词词组",
        trigger: "change"
      },
      expressions: {
        validator: validateExpressions,
        trigger: blur
      },
      vectorSize: {
        required: true,
        message: "请输入向量维度",
        trigger: "blur"
      }
    });
    function validateExpressions(rule, value, callback) {
      // console.log("validateExpression", rule, value);
      value.forEach((expression, index) => {
        // console.log("eeeee", index, value.length);

        if (!expression.field) {
          // console.log("valid field", expression);
          callback(new Error("字段不能为空"));
        } else if (!expression.op) {
          callback(new Error("条件不能为空"));
        } else if (!expression.value) {
          callback(new Error("值不能为空"));
        } else if (!expression.compound_op && index != value.length - 1) {
          callback(new Error("连接条件不能为空"));
        }
        // callback();
      });
      callback();
    }

    function setValueOfAllConfig() {
      for (let k in configFormInfo) {
        // console.log("set value of config", configForm[k]);
        configFormInfo[k].value = configForm[k];
      }
      // console.log("set config", configFormInfo, node.data.configs);
    }
    async function validateConfigs() {
      // return 111;
      let result = null;
      result = await configFormRef.value.validate();
      return result;
    }

    // 点击应用按钮后所以调用的接口，对node进行改动后，将node传回给父组件
    function applyConfigs() {
      configFormRef.value.validate(async (valid) => {
        if (valid) {
          //更改config
          setValueOfAllConfig();
          //生成新的fields
          getFields(node).then((node) => {
            console.log("apply configs", node);

            node.data.isValid = true;
            node.data.ifPersistent = ifPersistent.value;

            emit("saveInModuleNode", node, isConfigChanged.value);
          });

          ElMessage.success({ message: "应用成功！", duration: 500 });
        } else {
          // ElMessage.error("非法表单");
        }
      });
    }

    function applyConfigsAtOnce() {
      configFormRef.value.validate(async (valid) => {
        if (valid) {
          //更改config
          setValueOfAllConfig();
          //生成新的fields
          getFields(node).then((node) => {
            // console.log("apply configs", node);

            node.data.isValid = true;
            node.data.ifPersistent = ifPersistent.value;
            // console.log("save in module", node);

            emit("saveInModuleNodeAtOnce", node, isConfigChanged.value);
            // console.log("///");
          });
        } else {
          // ElMessage.error("非法表单");
        }
      });
    }
    async function getFields(node) {
      let source = node.data.fields.source;
      if (!source) {
        return node;
      }
      let fieldsSourceFunc = fieldsSourceFunctionMap[node.data.fields.source];
      if (fieldsSourceFunc) {
        await fieldsSourceFunc({ node: node, formerNodes: formerNodes });
      }
      return node;
    }
    function getLanguage(val) {
      console.log(val);
      let lan = tokenizeLanguageList.find((lan) => {
        return lan.value == val;
      });
      return lan ? "[" + lan.label + "]" : "[未知]";
    }
    // 测试用，检测select组件有没有正常运作
    function changeSelect() {
      // console.log("change select", node, props.isChanged);

      _handleChange();
      setValueOfAllConfig();

      getFields(node).then((res) => {
        // console.log("get field:result node", res);
      });
      // console.log("after set change", isConfigChanged.value, node);
    }
    function changeInput() {
      // console.log("change input");
      _handleChange();
    }
    function changeTopicRadio(topicRadio, index) {
      // console.log("change topic", topicRadio, index);
      if (topicRadio == "auto") {
        configForm[index] = null;
      }
      _handleChange();
    }
    function changeSampleType(sampleType, index) {
      configForm[index] = 0;
      _handleChange();
    }
    function changeFilterField(val, index, eIndex) {
      // console.log("chagneFilterField", val, index, eIndex);
      let options = configFormInfo[index].options;
      let type = options.find((option) => {
        return option.value == val;
      }).type;
      configForm[index][eIndex].type = type;
      configForm[index][eIndex].op = null;
      configForm[index][eIndex].value = null;

      _handleChange();
    }
    function changeCMDOption(cmdOption, index, eIndex) {
      let expression = configForm[index];
      if (eIndex == expression.length - 1) {
        expression.push({
          field: null,
          type: null,
          op: null,
          value: null,
          compound_op: null
        });
      }

      _handleChange();
    }
    function changeFilterValue(val, index, eIndex) {
      if (!val) {
        configForm[index][eIndex].compound_op = null;
      }
      _handleChange();
    }
    function changeFilterOpt(val) {
      _handleChange();
    }
    function removeSingleExpression(e, index, eIndex) {
      // console.log("lenght", configForm[index].length, eIndex);
      if (1 == configForm[index].length) {
        return;
      }
      configForm[index].splice(eIndex, 1);
      if (eIndex > 0) {
        configForm[index][eIndex - 1].compound_op = null;
      }
      // console.log("remove", eIndex, configForm[index]);
    }
    function formatter(row, column, cellValue, index) {
      // console.log("formater", row, column, cellValue, index);
      if (column.property == "____tokenize_content____") {
        let result = cellValue.map((item) => {
          return item.name;
        });
        return result.toString();
      }
      return cellValue;
    }
    // 数据变化后的操作
    function _handleChange() {
      isConfigChanged.value = true;

      node.data.progress = 0;
      node.data.moduleStatus = 0;

      emit("setIsChange", true);
      applyConfigsAtOnce();
    }
    function changeIfPersistent(val) {
      ifPersistent.value = val;

      _handleChange();
    }
    function uploadError() {
      // console.log("upload error", baseUrl + "/module/upload/picture");
    }
    //上传图片成功
    function uploadSuccess(response, file, fileList, item) {
      // console.log("uploadsuccess", response, file, fileList);
      fileList[fileList.length - 1].objectId = response.data;
      item.options.push(fileList[fileList.length - 1]);
    }
    // 词云图切换背景图片
    function changePicture(file, index) {
      // console.log(
      //   "choose picture",
      //   file,
      //   index,
      //   configForm,
      //   configFormInfo[index]
      // );
      configForm[index] = file.objectId;
      // console.log("choose picture", file, index, configForm);
      _handleChange();
    }
    // 判断排序拖拽是否允许落下
    function checkAllowDrop(draggingNode, dropNode, type) {
      // console.log("check allow drop", type);
      if (type == "inner") {
        return false;
      } else {
        return true;
      }
    }
    function afterDropNode(node, endNode, pos, event) {
      // console.log("after drop node", node, endNode, pos, event, configForm);
      _handleChange();
    }
    const uploadUrl = ref(baseUrl + "/module/upload/picture");
    // function addSingleExpression(e, index, eIndex) {
    //   configForm[index].splice(eIndex + 1, 0, {
    //     field: null,
    //     type: null,
    //     op: null,
    //     value: null,
    //     compound_op: null
    //   });
    // }
    // ======初始化函数========
    _getConfigForm();
    getOptionsBySource();

    return {
      treeRef,
      ...toRefs(state),
      ifHasHelpText,
      handleCheckChange,
      configForm,
      configFormInfo,
      configFormRef,
      applyConfigs,
      rules,
      uploadUrl,
      changeSelect,
      changeInput,
      changeTopicRadio,
      changeSampleType,
      ifPersistent,
      isConfigChanged,
      changeFilterField,
      changeCMDOption,
      removeSingleExpression,
      // addSingleExpression,
      changeFilterValue,
      changeFilterOpt,
      formatter,
      changeIfPersistent,
      applyConfigsAtOnce,
      validateConfigs,
      hint,
      uploadError,
      uploadSuccess,
      headers,
      handlePictureCardPreview,
      pictureDialogVisible,
      dialogImageUrl,
      showOptions,
      changePicture,
      handleRemove,
      checkAllowDrop,
      afterDropNode,
      helpContent,
      tokenizeLanguageList,
      getLanguage,
      ifShowFunctionMap
    };
  }
};
</script>

<style lang="scss" scoped>
.main-box {
  display: flex;
  .config-form {
    width: 420px;
  }
  .help-text {
    flex: 1;
    margin-left: 60px;
    line-height: 35px;

    .help-title {
      color: #da4847;
      font-size: 16px;
      margin-bottom: 5px;
      .help-block {
        background-color: #da4847;
        width: 16px;
        height: 16px;
        float: left;
        margin: 9px 10px 0 0;
      }
    }
  }
}
.picture-select {
  ::v-deep .el-upload-list--picture-card .el-upload-list__item {
    width: 102px;
    height: 102px;
  }
  ::v-deep .el-upload-list__item.is-success .el-upload-list__item-status-label {
    display: block;
  }

  ::v-deep .el-upload--picture-card {
    width: 102px;
    height: 102px;
    line-height: 106px;
  }
}
</style>
