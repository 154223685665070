<template>
  <div>
    <PageTitle :title="title" />
    <AnalysisTable :folderLevelList="folderLevelList" />
  </div>
</template>

<script setup>
import { onMounted, reactive, ref, defineEmits } from "vue";
import { useRoute } from "vue-router";

import PageTitle from "@/components/base/PageTitle.vue";
import AnalysisTable from "./components/AnalysisTable.vue";

import { httpPost } from "@/api/httpService";
import {
  getFolderLevelListByFolderId,
  getFolderBreadcrumbList
} from "./api/getFolderLevelList";

const route = useRoute();
const emits = defineEmits(["setBreadcrumbList"]);

const title = "分析";

const folderId = route.params.folderId;
const folderLevelList = ref([]);
const getFolderLevelList = async () => {
  folderLevelList.value = await getFolderLevelListByFolderId(folderId);
};

onMounted(async () => {
  await getFolderLevelList();
  console.log("get folderlist", folderLevelList.value);

  let breadcrumblist = getFolderBreadcrumbList(folderLevelList.value);
  emits("setBreadcrumbList", breadcrumblist);
  console.log("get breadcrumblist", breadcrumblist);
});
</script>

<style lang="scss" scoped></style>
