<template>
  <div style="position: relative">
    <span
      v-if="node.data.persistent"
      style="position: absolute; top: 12px; right: 0px; z-index: 100"
    >
      缓存此模块结果
      <el-switch
        v-model="ifPersistent"
        @change="changeIfPersistent"
        active-color="#13ce66"
      ></el-switch>
    </span>
    <!-- <el-switch
      style="position: absolute; top: 12px; right: 0px; z-index: 100"
      v-model="ifPersistent"
    ></el-switch> -->

    <el-tabs v-model="activeTab" @tab-click="handleClickTab">
      <!-- 配置视图 -->
      <el-tab-pane label="配置" name="configTab">
        <config-template
          ref="configTemplateRef"
          :node="node"
          :formerNodes="formerNodes"
          :modelInfo="modelInfo"
          :isChanged="isChanged"
          @saveInModuleNode="saveInModuleNode"
          @setIsChange="setIsChange"
        ></config-template>
      </el-tab-pane>
      <!-- 预览视图 -->
      <el-tab-pane
        label="预览输出"
        name="persistentTab"
        :disabled="isConfigChanged"
        v-if="ifPersistent"
      >
        <!-- {{ tableTitle }}} -->

        <el-table
          :data="tableData"
          empty-text="暂无预览数据或本模块不支持预览数据"
        >
          <el-table-column
            v-for="(title, index) in tableTitle"
            :prop="title.name"
            :label="title.label"
            :key="index"
            align="center"
            show-overflow-tooltip
            :formatter="formatter"
          ></el-table-column>
          <el-table-column label="链接">
            <template #default="scope">
              <el-button
                v-if="scope.row.____object_id____"
                size="small"
                type="text"
                @click="goToDetail(scope.$index, scope.row)"
              >
                详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
// 引入后才可以使用vue3的响应式，以及在不同的生命周期执行操作（如onMounted）
import {
  ref,
  reactive,
  toRefs,
  getCurrentInstance,
  watch,
  onMounted,
  computed
} from "vue";
import {
  optionSourceFunctionMap,
  fieldsSourceFunctionMap,
  configSourceFunctionMap
} from "@/constant/analysis/sourceFunctionMap";
import { ElMessage } from "element-plus";
import { httpPost } from "@/api/httpService";
import { duration } from "moment";
// import { async } from "@antv/x6/lib/registry/marker/async";
// import { clearSelection } from "@antv/x6/lib/util/dom/selection";
// import { call } from "@antv/x6/lib/util/function/function";
import ConfigTemplate from "./ConfigTemplate.vue";
// import router from "../../../../router";

export default {
  props: ["node", "formerNodes", "modelInfo", "isChanged"], // 在这里声明接收哪些prop，并从setup（props）的props中传入setup
  components: { ConfigTemplate },
  emits: ["saveInModuleNode", "setIsChange"],
  // 类似于created，但比created更早，并且没有this的概念，需要使用ref构造基础类型，使用active构造对象类型，并在setup中将响应式数据return出去
  setup(props, { emit }) {
    const activeTab = ref("configTab");
    const configTemplateRef = ref(null);
    function handleClickTab(val) {
      // console.log("click tab", val, props.isChanged, isConfigChanged.value);
      if (val.props.name == "persistentTab") {
        if (node.data.moduleType == 101) {
          let libraryId = node.data.configs.textLibraryId.value;
          httpPost("/module/get/preview/input/library/" + libraryId).then(
            (res) => {
              if (res.code == 0) {
                tableData.value = res.data.previewResult
                  ? res.data.previewResult.records
                  : [];
              }
            }
          );
        } else if (!isConfigChanged.value) {
          httpPost("/module/get/preview", {
            analysisId: props.modelInfo.analysisId,
            moduleIndex: node.data.moduleIndex
          }).then((res) => {
            if (res.code == 0) {
              tableData.value = res.data.previewResult
                ? res.data.previewResult.records
                : [];
            }
          });
        }
      }
    }
    const tableData = ref([]);
    let isConfigChanged = ref(props.isChanged);

    const state = reactive({ activeTab, handleClickTab, tableData });

    // console.log("props", props);
    // 指向父组件中module节点的configs，更新相关内容，如options,同时防止子组件将父组件的值改变
    const node = reactive(JSON.parse(JSON.stringify(props.node)));
    const tableTitle = computed(() => {
      // console.log("get tableTitle");
      let fieldData = node.data.fields.data;
      if (fieldData) {
        return fieldData.map((field) => {
          return {
            name: field.fieldName,
            label: field.fieldLabel
          };
        });
      } else {
        return null;
      }
    });
    const formerNodes = reactive(JSON.parse(JSON.stringify(props.formerNodes)));
    const configForm = reactive({});
    // 更新/获取最新的configs

    // 生成configForm用于绑定value

    const ifPersistent = ref(node.data.ifPersistent);

    // 点击应用按钮后所以调用的接口，对node进行改动后，将node传回给父组件
    function applyConfigs() {
      configTemplateRef.value.applyConfigs();
    }

    function formatter(row, column, cellValue, index) {
      // console.log("formater", row, column, cellValue, index);
      // if (column.property == "____tokenize_title____") {
      if (column.property.indexOf("____tokenize_") == 0) {
        let result = cellValue.map((item) => {
          return item.name;
        });
        return result.toString();
      }
      return cellValue;
    }
    // 数据变化后的操作

    function saveInModuleNode(_node, _isConfigChanged) {
      emit("saveInModuleNode", _node, _isConfigChanged);
    }
    function setIsChange(val) {
      emit("setIsChange", val);
    }
    function changeIfPersistent(val) {
      configTemplateRef.value.changeIfPersistent(val);
    }
    function goToDetail(index, row) {
      window.open(
        "/textLibrary/record/edit?libraryId=" +
          props.modelInfo.libraryId +
          "&recordId=" +
          row.____object_id____ +
          "&type=3"
      );
    }

    return {
      ...toRefs(state),

      configForm,
      configTemplateRef,
      setIsChange,
      saveInModuleNode,
      changeIfPersistent,
      applyConfigs,

      tableTitle,

      ifPersistent,
      isConfigChanged,
      formatter,
      goToDetail
    };
  }
};
</script>

<style></style>
