<template>
  <div ref="refForceChart"></div>
</template>

<script>
import { onMounted, toRefs, reactive, ref } from "vue";
import G6 from "@antv/g6";

export default {
  name: "ForceChart",
  props: {
    chartInfo: Object
  },
  setup(props) {
    let refForceChart = ref(null);
    let state = reactive({
      chart: null
    });
    let graph = null;
    //初始化图形
    function initChart() {
      // console.log("force chart", props.chartInfo);
      let data = {};
      if (!props.chartInfo.nodes) {
        data = {
          // 点集
          nodes: [
            {
              id: "上海", // String，该节点存在则必须，节点的唯一标识
              label: "上海",
              num: 8
            },
            {
              id: "北京", // String，该节点存在则必须，节点的唯一标识
              label: "北京",
              num: 10000
            },
            {
              id: "广州", // String，该节点存在则必须，节点的唯一标识
              label: "广州",
              num: 100000000
            },
            {
              id: "深圳", // String，该节点存在则必须，节点的唯一标识
              label: "深圳",
              num: 77
            }
          ],
          // 边集
          edges: [
            {
              source: "北京", // String，必须，起始点 id
              target: "上海", // String，必须，目标点 id
              num: 1,
              label: ""
            },
            {
              source: "广州", // String，必须，起始点 id
              target: "深圳", // String，必须，目标点 id
              num: 130,
              label: ""
            },
            {
              source: "广州", // String，必须，起始点 id
              target: "上海", // String，必须，目标点 id
              num: 130,
              label: ""
            },
            {
              source: "广州", // String，必须，起始点 id
              target: "北京", // String，必须，目标点 id
              num: 13000,
              label: ""
            }
          ]
        };
        data.nodes = [];
        for (let i = 1; i < 11; i++) {
          data.nodes.push({
            id: i.toString(), // String，该节点存在则必须，节点的唯一标识
            label: "关键词" + i,
            num: i
          });
        }
        data.edges = [];
        for (let i = 0; i < 20; i++) {
          data.edges.push({
            source: Math.floor(Math.random() * 10 + 1).toString(), // String，必须，起始点 id
            target: Math.floor(Math.random() * 10 + 1).toString(), // String，必须，目标点 id
            num: Math.floor(Math.random() * 1000 + 1),
            label: ""
          });
        }
      } else {
        data = {
          nodes: props.chartInfo.nodes,
          edges: props.chartInfo.edges
        };
      }
      // console.log("data", data);
      const colors = [
        "#BDD2FD",
        "#BDEFDB",
        "#C2C8D5",
        "#FBE5A2",
        "#F6C3B7",
        "#B6E3F5",
        "#D3C6EA",
        "#FFD8B8",
        "#AAD8D8",
        "#FFD6E7" //
        // "#CCFF99",
        // "#FFFFCC",
        // "#CCCCFF",
        // "#FFCCCC",
        // "#CCFFCC",
        // "#CCFFFF",
        // "#FF6666",
        // "#FF9966"
      ];
      const strokes = [
        "#5B8FF9",
        "#5AD8A6",
        "#5D7092",
        "#F6BD16",
        "#E8684A",
        "#6DC8EC",
        "#9270CA",
        "#FF9D4D",
        "#269A99",
        "#FF99C3"
        // "#CCFF99",
        // "#FFFFCC",
        // "#CCCCFF",
        // "#FFCCCC",
        // "#CCFFCC",
        // "#CCFFFF",
        // "#FF6666",
        // "#FF9966"
      ];
      data.nodes.forEach((node, index) => {
        node.size = 8 * Math.log(node.num + 1) + 20;
        node.style = {
          fill: colors[index % colors.length],
          stroke: strokes[index % strokes.length]
          // color:
        };
      });
      data.edges.forEach((edge, index) => {
        edge.style = {
          lineWidth: 0.4 * Math.log(edge.num) + 1
        };
      });
      const nodes = data.nodes;
      // console.log("nodes", nodes);
      const clusterMap = new Map();
      let clusterId = 0;
      // nodes.forEach(function (node) {
      //   // cluster
      //   if (node.cluster && clusterMap.get(node.cluster) === undefined) {
      //     clusterMap.set(node.cluster, clusterId);
      //     clusterId++;
      //   }
      //   const cid = clusterMap.get(node.cluster);
      //   if (!node.style) {
      //     node.style = {};
      //   }
      //   node.style.fill = colors[cid % colors.length];
      //   node.style.stroke = strokes[cid % strokes.length];
      // });
      graph = new G6.Graph({
        container: refForceChart.value, // String | HTMLElement，必须，在 Step 1 中创建的容器 id 或容器本身
        width: 1200, // Number，必须，图的宽度
        height: 500, // Number，必须，图的高度
        fitview: true,
        fitCenter: true,

        layout: {
          type: "gForce",
          // center: [200, 200], // 可选，默认为图的中心
          gravity: 300,
          edgeStrength: 500,
          nodeStrength: 30000,
          preventOverlap: true, // 防止节点重叠
          nodeSpacing: 1000,
          nodeSize: 4000,
          linkDistance: 150, // 可选，边长
          maxIteration: 200
        },
        modes: {
          default: ["drag-canvas", "zoom-canvas", "drag-node"] // 允许拖拽画布、放缩画布、拖拽节点
        },
        defaultNode: {
          labelCfg: {
            style: {
              fontWeight: 300,
              fill: "black",
              fontFamily: "Microsoft YaHei"
            }
          }
        },
        defaultEdge: {
          labelCfg: {
            style: {
              fontWeight: 300,
              fill: "black",
              fontFamily: "Microsoft YaHei"
            }
          },
          style: {
            stroke: "#ababab"
          }
        }
      });
      graph.data(data); // 读取 Step 2 中的数据源到图上
      graph.render(); // 渲染图
      // 鼠标移入节点显示频数
      graph.on("node:mouseleave", function (evt) {
        const node = evt.item;
        const model = node.getModel();
        graph.updateItem(node, {
          label: model.oriLabel,
          labelCfg: {
            style: {
              fontWeight: 400
              // fill: "#555"
            }
          }
        });
      });

      graph.on("node:mouseenter", function (evt) {
        const node = evt.item;
        const model = node.getModel();
        model.oriLabel = model.label;
        graph.updateItem(node, {
          label: `${model.oriLabel} (${model.num})`,
          labelCfg: {
            style: {
              // fill: "#003a8c"
              fontFamily: "Microsoft YaHei",
              fontWeight: 600
            }
          }
        });
      });
      graph.on("edge:mouseleave", function (evt) {
        const node = evt.item;
        const model = node.getModel();
        graph.updateItem(node, {
          label: "",
          labelCfg: {
            style: {
              // fill: "#555"
              fontWeight: 1000
            }
          }
        });
      });

      graph.on("edge:mouseenter", function (evt) {
        const node = evt.item;
        const model = node.getModel();

        graph.updateItem(node, {
          label: model.num,
          labelCfg: {
            // style: {
            //   fill: "#003a8c"
            // }
          }
        });
      });
    }
    //更新数据
    function resizeChart() {}
    onMounted(() => {
      initChart();
    });
    function exportResult(type) {
      // let dataUrl = toDataURL(chart);
      switch (type) {
        case "PNG":
          graph.downloadImage(props.chartInfo.title, "image/");
          break;

        default:
          break;
      }

      // console.log("export wordcloud", dataUrl);
    }
    return {
      refForceChart,
      ...toRefs(state),
      initChart,
      resizeChart,
      exportResult
    };
  }
};
</script>

<style lang="scss" scoped></style>
