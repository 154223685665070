<template>
  <div>
    <el-form :model="tableColumn" label-width="120px">
      <template v-for="title in tableTitles" :key="title.value">
        <el-form-item
          v-if="title.value == '____events____'"
          :prop="title.value"
          :label="title.name"
        >
          <div style="width: 100%">
            <el-radio-group v-model="eventType">
              <el-radio :label="0">
                发生（{{
                  tableColumn[title.value].filter((_) => _.state == 0).length
                }}）
              </el-radio>
              <el-radio :label="1">
                未发生（{{
                  tableColumn[title.value].filter((_) => _.state == 1).length
                }}）
              </el-radio>
            </el-radio-group>
          </div>
          <div
            class="event-item"
            v-for="eventItem in tableColumn[title.value].filter(
              (_) => _.state == eventType
            )"
            :key="eventItem"
          >
            <span>
              <span v-if="eventItem.actor_names.toString()">
                {{ eventItem.actor_names.toString() }}
                <el-icon class="icon"><Right /></el-icon>
              </span>
              <span>
                {{ eventItem.act }}
              </span>
              <span v-if="eventItem.sufferer_names.toString()">
                <el-icon class="icon"><Right /></el-icon>
                {{ eventItem.sufferer_names.toString() }}
              </span>
            </span>
            <span v-if="eventTimeList(eventItem.act_time).toString()">
              ;{{ eventTimeList(eventItem.act_time).toString() }}
            </span>
            <span v-if="eventItem.act_location_list.toString()">
              ;{{ eventItem.act_location_list.toString() }}
            </span>

            <!-- {{ eventItem }}
            {{ eventItem }}
            {{ eventItem }} -->
          </div>
        </el-form-item>
        <el-form-item
          :prop="title.value"
          :label="title.name"
          v-else-if="title.value.indexOf('____tokenize_') == 0"
        >
          {{ tableColumn[title.value].map((_) => _.name).toString() }}
        </el-form-item>
        <el-form-item
          v-else-if="title.value != '____object_id____'"
          :prop="title.value"
          :label="title.name"
        >
          {{ tableColumn[title.value] }}
        </el-form-item>
      </template>
    </el-form>
  </div>
  <!-- <div>{{ tableColumn }}</div>
  <div>{{ tableTitles }}</div> -->
</template>

<script setup>
import { reactive, ref, defineProps, defineEmits, computed } from "vue";
import { httpPost } from "@/api/httpService.js";
import { useRoute } from "vue-router";
const emits = defineEmits(["setBreadcrumbList"]);
const route = useRoute();

const objectId = route.params.objectId;
const { modelInfo, tableColumn, tableTitles } = JSON.parse(
  window.sessionStorage.getItem("analysis_result_single_" + objectId)
);
const setBreadcrumbList = () => {
  let breadcrumblist = [
    {
      title: "分析",
      to: {
        name: "AnalysisIndex"
      }
    },
    {
      title: modelInfo.title,
      to: {
        name: "AnalysisModel",
        query: {
          analysisId: modelInfo.analysisId
        }
      }
    }
  ];
  emits("setBreadcrumbList", breadcrumblist);
};
setBreadcrumbList();

// 事件列表 事件类型
const eventType = ref(0);

const eventTimeList = (actTime) => {
  let result = [];
  actTime.time_list.forEach((item) => {
    if (item) {
      result.push(item);
    }
  });
  if (actTime.start_time) {
    result.push(actTime.start_time);
  }
  if (actTime.end_time) {
    result.push(actTime.end_time);
  }
  if (actTime.duration) {
    result.push(actTime.duration);
  }
  return result;
};
</script>

<style lang="scss" scoped>
.event-item {
  width: 100%;
  .icon {
    vertical-align: text-top;
    margin-right: 4px;
  }
}
</style>
