<template>
  <!-- 导航栏->我的文本库->分析 -->
  <div class="page-title-wrapper">
    <PageTitle
      :title="title"
      @save="handleClickSave"
      v-model:loading="pageTitleLoading"
      v-model:edit="ifPageTitleEdit"
      :editable="!ifSystem"
      :font-color="modelInfo.modelStatus == 5 ? '#ec2411' : ''"
    ></PageTitle>
    <StatusTag
      class="status-tag"
      v-if="modelInfo.modelStatus != null"
      :type="statusMap[modelInfo.modelStatus].type"
      :label="statusMap[modelInfo.modelStatus].label"
    ></StatusTag>
  </div>

  <!-- {{ modelInfo.modelStatus }} -->
  <div id="analysis">
    <componentTab :modelInfo="modelInfo" ref="componentTabRef" />
  </div>
</template>
<script setup>
import { ElMessage } from "element-plus";
import StatusTag from "@/components/base/StatusTag";
import { useRoute } from "vue-router";
import {
  ref,
  reactive,
  toRefs,
  getCurrentInstance,
  defineEmits,
  onMounted,
  computed
} from "vue";
import {
  ERROR_CODE_NAME_DUPLICATE,
  PARAM_VALID_ERROR
} from "@/constant/error_code.js";
// import ModuleNav from "../../../components/textLibrary/ModuleNav";
// import AnalysisIndex from "./AnalysisIndex";
import componentTab from "./components/ComponentTab";
import PageTitle from "@/components/base/PageTitle.vue";
import { httpPost } from "@/api/httpService";
import {
  getFolderLevelListByAnalysisId,
  getFolderBreadcrumbList
} from "./api/getFolderLevelList";
import bus from "@/utils/EventBus.js";
import { statusMap } from "@/constant/analysis/status";
import { SYSTEM_USER_ID } from "@/constant/system";

const route = useRoute();
const emits = defineEmits(["setBreadcrumbList"]);

const analysisId = route.query.analysisId;

const folderLevelList = ref([]);
const getFolderLevelList = async () => {
  folderLevelList.value = await getFolderLevelListByAnalysisId(analysisId);
};

const componentTabRef = ref(null);

const title = computed(() => {
  if (folderLevelList.value.length > 0) {
    console.log(folderLevelList.value);
    return folderLevelList.value[folderLevelList.value.length - 1].name;
  } else {
    return "";
  }
});
const modelInfo = ref({
  analysisId,
  title
});
// let statusMap = {
//   0: { type: "info", label: "已创建", value: 0 },
//   1: { type: "warning", label: "进行中", value: 1 },
//   2: { type: "success", label: "成功", value: 2 },
//   3: { type: "danger", label: "异常", value: 3 },
//   4: { type: "info", label: "已失效", value: 4 },
//   5: { type: "success", label: "成功", value: 5 }
// }; // 状态map
const parentFolderId = computed(() => {
  if (folderLevelList.value.length > 1) {
    console.log(folderLevelList.value);
    return folderLevelList.value[folderLevelList.value.length - 2].id;
  } else {
    return "";
  }
});

const pageTitleLoading = ref(false);
const ifPageTitleEdit = ref(false);
const handleClickSave = async (text) => {
  if (!text) {
    ElMessage.warning("名称不能为空");
    pageTitleLoading.value = false;
    return;
  }
  // let parentFolderId =
  let res = await renameAnalysis(analysisId, text, parentFolderId.value);
  if (res.code == 0) {
    //保存成功,更新单行，并重新获取最新列表数据
    ElMessage.success(res.message);
    ifPageTitleEdit.value = false;
    setBreadcrumbList();
  } else if (
    res.code == ERROR_CODE_NAME_DUPLICATE ||
    res.code == PARAM_VALID_ERROR
  ) {
    //系统已知错误,用户重新编辑
    ElMessage.warning(res.message);
    pageTitleLoading.value = false;
  } else {
    //系统未知错误,由外部捕获错误，然后重新获取列表数据
    setBreadcrumbList();
  }
};
const renameAnalysis = async (id, name, parentFolderId) => {
  let params = {
    id,
    name,
    parentFolderId,
    ifChildFolder: 0 //0：不是文件夹
  };
  let res = await httpPost("/view/analysis/save", params);

  return res;
};
const setBreadcrumbList = async () => {
  await getFolderLevelList();
  let breadcrumblist = getFolderBreadcrumbList(folderLevelList.value);
  emits("setBreadcrumbList", breadcrumblist);
};
onMounted(() => {
  setBreadcrumbList();
  bus.on("updateStatus", (status) => {
    modelInfo.value.modelStatus = status;
  });
});
// 获取模型信息
const getModelInfo = async () => {
  let url = "/model/get/";
  let res = await httpPost(url + analysisId);
  if (res.code == 0) {
    modelInfo.value = res.data;
    modelInfo.value.analysisId = analysisId;
  }
};
const ifSystem = computed(() => {
  return modelInfo.value.userId == SYSTEM_USER_ID;
});
getModelInfo();
</script>
<style lang="scss" scoped>
.page-title-wrapper {
  display: flex;
  .status-tag {
    position: relative;
    top: 2px;
    margin-left: 10px;
  }
}
#analysis {
  .main-wrapper {
    // 面包屑导航
    .el-breadcrumb {
      margin-bottom: 40px;
    }
    //主体内容
    .analysis-main-wrapper {
      padding: 0 20px;
      //tab栏
      ::v-deep .el-tabs__header {
        // margin-bottom: 40px;
      }
    }
  }
  .analysis-index {
    margin-top: 40px;
  }
}
</style>
