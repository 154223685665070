<template>
  <div class="AnalysisResult-header">
    <h3>分析结果</h3>
  </div>
  <!--<word-cloud-chart :modelInfo="props.modelInfo"></word-cloud-chart>-->
  <result-layout
    v-if="data"
    :data="data"
    :modelInfo="modelInfo"
  ></result-layout>
  <!-- <el-row :gutter="20">
    <el-col :span="12">
      <el-row>
        <el-col :span="6">
          <el-row>
            <div class="chart" id="chart2" style="width: 90%; height: 500px">
              2
            </div>
          </el-row>
          <el-row>
            <div class="chart" id="chart3" style="width: 90%; height: 500px">
              3
            </div>
          </el-row>
          <el-row>
            <div class="chart" id="chart4" style="width: 90%; height: 500px">
              3
            </div>
          </el-row>
          <el-row>
            <div class="chart" id="chart5" style="width: 90%; height: 500px">
              3
            </div>
          </el-row>
        </el-col>
        <el-col
          :span="18"
          style="display: flex; justify-content: center; align-items: center"
        >
          <div
            class="chart"
            id="chart1"
            style="width: 90%; height: 500px"
          ></div>
        </el-col>
      </el-row>
    </el-col>
    <el-col :span="12">
      <el-row>
        <el-col :span="8">
          <div class="chart" id="chart12" style="width: 100%; height: 500px">
            2
          </div>
        </el-col>
        <el-col :span="8">
          <div class="chart" id="chart13" style="width: 100%; height: 500px">
            2
          </div>
        </el-col>
        <el-col :span="8">
          <div class="chart" id="chart14" style="width: 100%; height: 500px">
            2
          </div>
        </el-col>
        <el-col :span="8">
          <div class="chart" id="chart15" style="width: 100%; height: 500px">
            2
          </div>
        </el-col>
        <el-col>
          <el-row>
            <el-col>
              <div
                class="chart"
                id="chart11"
                style="width: 100%; height: 500px"
              >
                2
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-col>
  </el-row> -->
</template>

<script>
import {
  ref,
  reactive,
  toRefs,
  getCurrentInstance,
  watch,
  computed,
  onMounted,
  provide,
  nextTick
} from "vue";
import { httpPost } from "@/api/httpService";
import * as echarts from "echarts";

import resultLayout from "./ResultLayout";
export default {
  props: ["modelInfo"],
  components: { resultLayout },
  setup(props) {
    let data = ref(null);
    function getResult() {
      httpPost("/model/get/result/" + props.modelInfo.analysisId).then(
        (res) => {
          data.value = res.data;
        }
      );
    }
    const testdata = reactive({
      childList: [
        {
          //图表
          viewStyle: 1,
          title: "猛男1",
          width: 8
        },
        {
          //布局
          childList: [
            {
              //图表
              viewStyle: 1,
              title: "猛男11",
              width: 8,
              childList: [{}, {}]
            },
            {
              //图表
              viewStyle: 1,
              title: "猛男22",
              width: 6
            }
          ],
          layoutDisplay: 2,
          width: 16
        }
      ],
      layoutDisplay: 1
    });
    getResult();
    onMounted(() => {
      // var chartDom1 = document.getElementById("chart1");
      // var chartDom2 = document.getElementById("chart2");
      // var chartDom3 = document.getElementById("chart3");
      // var chartDom4 = document.getElementById("chart4");
      // var chartDom5 = document.getElementById("chart5");
      // var chartDom11 = document.getElementById("chart11");
      // var chartDom12 = document.getElementById("chart12");
      // var chartDom13 = document.getElementById("chart13");
      // var chartDom14 = document.getElementById("chart14");
      // var chartDom15 = document.getElementById("chart15");
      // var myChart1 = echarts.init(chartDom1);
      // var myChart2 = echarts.init(chartDom2);
      // var myChart3 = echarts.init(chartDom3);
      // var myChart4 = echarts.init(chartDom4);
      // var myChart5 = echarts.init(chartDom5);
      // var myChart11 = echarts.init(chartDom11);
      // var myChart12 = echarts.init(chartDom12);
      // var myChart13 = echarts.init(chartDom13);
      // var myChart14 = echarts.init(chartDom14);
      // var myChart15 = echarts.init(chartDom15);
      // var option1 = {
      //   title: {
      //     text: "ECharts 入门示例"
      //   },
      //   tooltip: {},
      //   legend: {
      //     data: ["销量"]
      //   },
      //   xAxis: {
      //     data: ["衬衫", "羊毛衫", "雪纺衫", "裤子", "高跟鞋", "袜子"]
      //   },
      //   yAxis: {},
      //   series: [
      //     {
      //       name: "销量",
      //       type: "bar",
      //       data: [5, 20, 36, 10, 10, 20]
      //     }
      //   ]
      // };
      // var option = {
      //   tooltip: {
      //     trigger: "axis",
      //     axisPointer: {
      //       type: "shadow"
      //     }
      //   },
      //   legend: {},
      //   grid: {
      //     left: "3%",
      //     right: "4%",
      //     bottom: "3%",
      //     containLabel: true
      //   },
      //   xAxis: [
      //     {
      //       type: "category",
      //       data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
      //     }
      //   ],
      //   yAxis: [
      //     {
      //       type: "value"
      //     }
      //   ],
      //   series: [
      //     {
      //       name: "Direct",
      //       type: "bar",
      //       emphasis: {
      //         focus: "series"
      //       },
      //       data: [320, 332, 301, 334, 390, 330, 320]
      //     },
      //     {
      //       name: "Email",
      //       type: "bar",
      //       stack: "Ad",
      //       emphasis: {
      //         focus: "series"
      //       },
      //       data: [120, 132, 101, 134, 90, 230, 210]
      //     },
      //     {
      //       name: "Union Ads",
      //       type: "bar",
      //       stack: "Ad",
      //       emphasis: {
      //         focus: "series"
      //       },
      //       data: [220, 182, 191, 234, 290, 330, 310]
      //     },
      //     {
      //       name: "Video Ads",
      //       type: "bar",
      //       stack: "Ad",
      //       emphasis: {
      //         focus: "series"
      //       },
      //       data: [150, 232, 201, 154, 190, 330, 410]
      //     },
      //     {
      //       name: "Search Engine",
      //       type: "bar",
      //       data: [862, 1018, 964, 1026, 1679, 1600, 1570],
      //       emphasis: {
      //         focus: "series"
      //       },
      //       markLine: {
      //         lineStyle: {
      //           type: "dashed"
      //         },
      //         data: [[{ type: "min" }, { type: "max" }]]
      //       }
      //     },
      //     {
      //       name: "Baidu",
      //       type: "bar",
      //       barWidth: 5,
      //       stack: "Search Engine",
      //       emphasis: {
      //         focus: "series"
      //       },
      //       data: [620, 732, 701, 734, 1090, 1130, 1120]
      //     },
      //     {
      //       name: "Google",
      //       type: "bar",
      //       stack: "Search Engine",
      //       emphasis: {
      //         focus: "series"
      //       },
      //       data: [120, 132, 101, 134, 290, 230, 220]
      //     },
      //     {
      //       name: "Bing",
      //       type: "bar",
      //       stack: "Search Engine",
      //       emphasis: {
      //         focus: "series"
      //       },
      //       data: [60, 72, 71, 74, 190, 130, 110]
      //     },
      //     {
      //       name: "Others",
      //       type: "bar",
      //       stack: "Search Engine",
      //       emphasis: {
      //         focus: "series"
      //       },
      //       data: [62, 82, 91, 84, 109, 110, 120]
      //     }
      //   ]
      // };
      // myChart1.setOption(option);
      // myChart2.setOption(option);
      // myChart3.setOption(option);
      // myChart4.setOption(option);
      // myChart5.setOption(option);
      // myChart11.setOption(option);
      // myChart12.setOption(option);
      // myChart13.setOption(option);
      // myChart14.setOption(option);
      // myChart15.setOption(option);
    });

    // const data = reactive({
    //   child1: {
    //     child1: { title: "猛男", viewStyle: 1 },
    //     child2: { title: "猛男", viewStyle: 1 },
    //     layoutDisplay: 0
    //   },
    //   child2: { title: "猛男", viewStyle: 1 },
    //   layoutDisplay: 0
    // });
    return { props, data, testdata };
  }
};
</script>

<style lang="scss">
.chart {
  border: 1px solid;
  width: 100%;
  /* margin: 30px; */
  padding: 20px;
  margin-top: 20px;
}
.AnalysisResult-header {
  width: 100%;
  h3 {
    text-align: center;
  }
}
</style>
