<template>
  <div>
    <!-- tableTitle表头数据, prop 表格字段，label表格字段名 -->
    <el-table
      :data="tableData.tableColumns"
      v-if="tableData != null && tableData != undefined && tableData != {}"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        v-if="hasSelection"
        type="selection"
        width="55"
        align="center"
      />
      <!--为了适配多级表头，定义一个tableColumn，方便无限嵌套-->
      <table-column
        v-for="(item, key) in tableData.tableTitles.filter((v) => {
          return v.ifVisualizable != 0;
        })"
        :key="key"
        :col="item"
        :tableData="tableData"
      ></table-column>

      <!-- <el-table-column
        :prop="item.fieldName"
        :label="item.fieldLabel"
        align="center"
        v-for="(item, key) in tableData.tableTitles"
        :key="key"
      /> -->
      <el-table-column
        v-if="hasOperation && operation"
        label="操作"
        align="center"
        fixed="right"
      >
        <template #default="scope">
          <el-button
            v-for="(button, index) in operation"
            :key="index"
            type="text"
            class="button"
            @click="button.fun(scope.$index, scope.row)"
            :disabled="button.disabled"
          >
            {{ button.name }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div
      v-if="
        hasPagination &&
        tableData != null &&
        tableData != undefined &&
        tableData != {}
      "
    >
      <el-pagination
        class="demo-pagination-block"
        :value="tableData.currentPageNumber"
        :page-size="10"
        :total="tableData.totalNumber"
        layout="total, prev, pager, next"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import tableColumn from "./tableColumn";
import { ref, reactive, toRefs, getCurrentInstance, onMounted } from "vue";
export default {
  name: "tableComponent",
  components: {
    tableColumn
  },
  props: {
    tableData: {
      tableColumns: {
        type: Array
      },
      tableTitles: {
        type: Array
      }
    },
    // 是否有多选框
    hasSelection: {
      type: Boolean,
      default: false
    },
    // 是否有操作列
    hasOperation: {
      type: Boolean,
      default: false
    },
    // 是否有分页
    hasPagination: {
      type: Boolean,
      default: false
    },
    // 操作列按钮配置
    operation: Array
  },
  setup(props, context) {
    // console.log("table component data", props.tableData);
    let show = ref(false);
    // setTimeout(() => {
    //   show = true;
    // }, 3000);
    function handleSelectionChange(val) {
      context.emit("handleSelectionChange", val);
      // this.$store.dispatch('table/multiple', val)
    }
    function handleCurrentChange(val) {
      // console.log("change currentpage", val);
      context.emit("handleCurrentChange", val);
    }
    return { handleSelectionChange, show, handleCurrentChange };
  }
};
</script>
<style lang="scss" scoped>
/* .el-table th {
  user-select: initial;
} */
.el-table {
  ::v-deep th {
    background-color: #f9f9f9;
  }
  .el-select {
    width: 87px;
    position: relative;
    top: -3px;
    ::v-deep .el-input__inner {
      border: none;
      background-color: #f9f9f9;
      color: #101010;
    }
    ::v-deep .el-select__caret {
      color: #101010;
    }
  }
  ::v-deep .el-button--text {
    color: $primary-color;
  }
  .el-tag {
    border: none;
    color: #fff;
  }
  .valid {
    background-color: #7da450;
  }
  .forbid {
    background-color: #919191;
  }
}
</style>
