<template>
  <el-table
    :data="tableDataResult.tableColumns"
    empty-text="暂无预览数据或本模块不支持预览数据"
  >
    <template
      v-for="(title, index) in tableDataResult.tableTitles"
      :key="index"
    >
      <el-table-column
        :prop="title.value"
        :label="title.name"
        v-if="title.value == '____events____'"
        align="center"
        :width="columnWidth(title)"
        min-width="150"
      >
        <template #default="scope">
          <div>
            <div class="dot" style="background-color: #08b809"></div>
            已发生（{{
              scope.row.____events____.filter((_) => _.state == 1).length
            }}）
          </div>
          <div>
            <div class="dot" style="background-color: #f48f42"></div>
            未发生（{{
              scope.row.____events____.filter((_) => _.state == 0).length
            }}）
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :prop="title.value"
        :label="title.name"
        v-else-if="title.name != '____object_id____'"
        align="center"
        :width="columnWidth(title)"
        min-width="150"
        show-overflow-tooltip
        :formatter="formatter"
      ></el-table-column>
    </template>
    <el-table-column v-if="ifDetail" label="链接" width="100">
      <template #default="scope">
        <el-button
          v-if="scope.row.____object_id____"
          size="small"
          link
          type="primary"
          @click="goToDetail(scope.$index, scope.row)"
        >
          详情
        </el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    @current-change="toChangePage"
    :value="tableData.currentPageNumber"
    :page-size="pageSize"
    layout="total, prev, pager, next"
    style="margin-top: 10px"
    :total="tableData.totalNumber"
  ></el-pagination>
</template>

<script>
import { ref, reactive, onMounted, computed } from "vue";
import { httpPost } from "@/api/httpService";
export default {
  name: "RecordTable",
  props: ["tableData", "modelInfo", "formatter"],
  setup(props) {
    // console.log("record table", props);
    let tableDataResult = reactive(props.tableData);
    const pageSize = ref(10);

    function toChangePage(currentPage) {
      // console.log("tochangepage", currentPage);
      const postData = {
        analysisId: props.modelInfo.analysisId,
        moduleIndex: props.tableData.moduleIndex,
        pageNum: currentPage,
        pageSize: pageSize.value,
        tableType: props.tableData.tableType
      };
      httpPost("/module/get/result", postData).then((res) => {
        tableDataResult.tableColumns = reactive(
          res.data.result.tableContentList
        );
      });
    }
    function goToDetail(index, row) {
      let data = JSON.stringify({
        modelInfo: props.modelInfo,
        tableTitles: tableDataResult.tableTitles,
        tableColumn: row
      });
      window.sessionStorage.setItem(
        "analysis_result_single_" + row.____object_id____,
        data
      );
      window.open("/analysis/result/single/" + row.____object_id____);
    }
    const ifDetail = computed(() => {
      if (
        tableDataResult.tableTitles.find((title) => {
          if (title.name == "____object_id____") {
            return true;
          }
        })
      ) {
        return true;
      } else {
        return false;
      }
    });
    const columnWidth = computed(() => {
      return function (title) {
        if (title.value == "____events____") {
          return 300;
        } else {
          return "";
        }
      };
    });
    return {
      tableDataResult,
      toChangePage,
      pageSize,
      goToDetail,
      ifDetail,
      columnWidth
    };
  }
};
</script>

<style scoped lang="scss">
.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  position: relative;
  // top: -3px;
}
</style>
