import { httpPost } from "@/api/httpService";

const ROOT_FOLDER = { id: 0, name: "根目录", parentFolderId: null }; //用于显示面包屑

// 通过文件夹id获取该文件夹路径
export const getFolderLevelListByFolderId = async (folderId) => {
  let params = {
    ifChildFolder: 1,
    contentId: folderId || ROOT_FOLDER.id
  };
  let res = await getFolderLevelList(params);
  return res;
};
// 通过分析id获取该分析所在文件夹路径
export const getFolderLevelListByAnalysisId = async (analysisId) => {
  let params = {
    ifChildFolder: 0,
    contentId: analysisId
  };
  let res = await getFolderLevelList(params);
  return res;
};
// 获取文件夹/分析路径
const getFolderLevelList = async (params) => {
  let res = await httpPost("/view/analysis/get/folderLevel", params);
  return res.data;
};

// 根据文件夹路径列表生成对应的面包屑
// 根据文件夹路径列表生成对应的面包屑
export const getFolderBreadcrumbList = (FolderLevelList) => {
  let breadcrumbList = FolderLevelList.map((level) => {
    if (level.id == 0) {
      return {
        title: "分析",
        to: {
          name: "AnalysisIndex"
        }
      };
    } else {
      if (level.ifChildFolder == 1) {
        return {
          title: level.name,
          to: {
            name: "AnalysisIndexWithFolder",
            params: {
              folderId: level.id
            }
          }
        };
      } else {
        return {
          title: level.name
        };
      }
    }
  });
  return breadcrumbList;
};
