<template>
  <div class="title">
    <!-- <div class="blue"></div> -->
    <div class="text">分析日志</div>

    <!-- <el-select v-model="value" placeholder="请选择" size="small">
        <el-option label="导出" value="导出"></el-option>
      </el-select> -->
  </div>
  <div>
    <el-table
      :data="tableData"
      style="width: 100%"
      :cell-style="{ 'border-bottom': 0 }"
      :header-cell-style="{ 'border-bottom': 0 }"
      v-if="tableData"
    >
      <el-table-column type="index" label="序号" width="100" />
      <el-table-column prop="label" label="任务名称" width="180" />
      <el-table-column prop="moduleTypeString" label="任务类型" width="180" />
      <el-table-column
        prop="analysisStatusString"
        label="任务状态"
        width="180"
      />
      <el-table-column
        prop="calculateTime"
        label="计算时间"
        :formatter="formatter"
      />
    </el-table>
  </div>
</template>

<script>
import { ref, reactive } from "vue";
import { httpPost } from "@/api/httpService";
import { moduleInfo, MODULE_LIST } from "@/constant/analysis/moduleInfo.js";
import { ElMessage } from "element-plus";
export default {
  name: "AnalysisLog",
  props: ["modelInfo"],
  setup(props) {
    // console.log("analysis log:props", props);
    const tableData = ref(null);
    function getTableData() {
      // console.log("???");
      httpPost("/model/get/log/" + props.modelInfo.analysisId).then((res) => {
        // console.log("get table data", res.data);
        if (res.code == 0) {
          tableData.value = res.data;
        } else {
          // ElMessage.warning(res.message);
        }
      });
    }
    function formatter(row, column, cellValue, index) {
      // console.log("formatter", row, column, cellValue);

      return cellValue == "-" ? cellValue : cellValue + "s";
    }
    getTableData();
    return { tableData, formatter };
  }
};
</script>

<style lang="scss" scoped>
.title {
  height: 38px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
  margin-top: 20px;
  cursor: pointer;
  & > div {
    float: left;
  }
  .el-select {
    float: right;
  }
  .blue {
    width: 12px;
    height: 30px;
    background-color: $primary-color;
    margin-left: 3px;
  }
  .text {
    padding-left: 15px;
    line-height: 32px;
    font-size: 16px;
    text-align: left;
  }
  i {
    color: #3894ff;
    float: right;
  }
}
</style>
