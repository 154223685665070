<template>
  <!-- <div @click="addIndex">序号：{{ nodeData.moduleIndex }}</div>
  <div>名称：{{ nodeData.label }}</div> -->
  <div class="status">
    <span :class="{ valid: nodeData.isValid, invalid: !nodeData.isValid }">
      {{ nodeData.isValid ? "已配置" : "未配置" }}
    </span>
    <span class="id">ID:{{ nodeData.moduleIndex }}</span>
  </div>
  <el-progress
    type="line"
    :percentage="nodeData.progress"
    :show-text="false"
    color="#52c41a"
    style="top: 43px"
    :stroke-width="3"
  ></el-progress>
  <el-card
    :class="[
      'box-card',
      { valid: nodeData.isValid, invalid: !nodeData.isValid }
    ]"
    body-style="padding:10px;line-height:20px"
  >
    <template #header>
      <div class="card-header">
        <span
          class="label"
          v-if="!isShowTitleInput"
          @dblclick.stop="showTitleInput"
        >
          <i :class="['iconfont', 'icon-module', nodeData.icon]"></i>
          {{ nodeData.label }}
          <!-- {{ nodeData.label }} -->
        </span>

        <span
          class="label"
          v-if="!isShowTitleInput && nodeData.moduleStatus == 1"
        >
          {{ (nodeData.progress || 0) + `%` }}
          <i class="el-icon-loading"></i>
        </span>
        <span
          class="label"
          v-if="!isShowTitleInput && nodeData.moduleStatus == 2"
        >
          {{ (nodeData.progress || 0) + `%` }}
          <i
            v-if="!nodeData.ifPersistent"
            class="el-icon-circle-check"
            style="color: #52c41a"
          ></i>
          <el-popover
            placement="top-start"
            trigger="hover"
            content="该模块结果已缓存"
            v-if="
              !isShowTitleInput &&
              nodeData.moduleStatus == 2 &&
              nodeData.ifPersistent
            "
          >
            <template #reference>
              <i
                v-if="nodeData.ifPersistent"
                class="iconfont icon-yunhuancun"
                style="color: #52c41a"
              ></i>
            </template>
          </el-popover>
        </span>
        <el-popover
          placement="top-start"
          title="错误信息"
          :width="200"
          trigger="hover"
          :content="nodeData.errorMessage"
          v-if="!isShowTitleInput && nodeData.moduleStatus == 3"
        >
          <template #reference>
            <i class="el-icon-warning-outline" style="color: red">分析异常</i>
          </template>
        </el-popover>

        <!-- <span
            class="label"
            v-if="!isShowTitleInput && nodeData.moduleStatus == 3"
          >
            <i class="el-icon-warning-outline" style="color: red"></i>
          </span> -->

        <!-- <span v-else class="label">输入标题</span> -->
        <span v-if="isShowTitleInput">
          <el-input
            v-model="nodeData.label"
            placeholder="请输入模块标题"
            @change="changeLabel"
            size="small"
          ></el-input>
          <el-button
            @click="cancelChangeLabel"
            type="text"
            style="margin-left: 5px"
          >
            确 认
          </el-button>
        </span>
      </div>
    </template>

    <div
      class="card-body"
      v-for="(config, index) in nodeData.configs"
      :key="index"
    >
      <span v-if="config.showOnDesignView" classs="grey text">
        {{ config.label }}：
      </span>
      <span v-if="config.showOnDesignView" classs="grey text">
        {{ getConfigValue(config) }}
      </span>
    </div>
  </el-card>
</template>

<script>
import { computed, inject, onMounted, reactive, ref } from "vue";
import { ElCard, ElInput, ElButton, ElProgress, ElPopover } from "element-plus";
import moduleInfo from "@/constant/analysis/moduleInfo";
export default {
  name: "nodeComponent",
  components: { ElCard, ElInput, ElButton, ElProgress, ElPopover },
  emits: ["updateModuleNode"],
  setup(props, context) {
    const injectGraph = inject("getGraph");
    const injectNode = inject("getNode");
    const graph = injectGraph();
    const node = injectNode();
    const isShowTitleInput = ref(false);

    function addIndex() {
      const { moduleIndex } = node.getData();
      node.setData({
        moduleIndex: moduleIndex + 1
      });
    }

    function showTitleInput() {
      // console.log("showTitleInput", nodeData);
      isShowTitleInput.value = true;
    }

    function changeLabel(value) {
      isShowTitleInput.value = false;
      node.setData({ label: value });

      context.emit("updateModuleNode", node);
    }

    function cancelChangeLabel() {
      isShowTitleInput.value = false;
      // console.log("cancel", isShowTitleInput, node.id);
      context.emit("updateModuleNode", node.id);
    }
    //获取config中的value的显示值
    function getConfigValue(config) {
      let result = "未知类型数据";
      switch (config.componentType) {
        case "singleSelect":
          result = _getSelectValue(config);
          break;
        case "lineTextInput":
          result = _getLineInputValue(config);
          break;
        case "folderSelect":
          result = _getFolderSelectValue(config);
          break;
        case "multiSelect":
          result = _getMultiSelectValue(config);
          break;
        case "radio":
          result = _getRadioValue(config);
          break;
        case "clusterNumInput":
          result = _getClusterNumInputValue(config);
          break;
        case "topicNumInput":
          result = _getTopicNumInputValue(config);
          break;
        case "numberInput":
          result = _getNumberInputValue(config);
          break;
        case "sampleNumInput":
          result = _getNumberInputValue(config);
          break;
        case "filterGenerator":
          result = _getFilterGenerator(config);
          break;

        default:
          result = "未知类型数据";
          break;
      }
      return result;
    }
    function _getFilterGenerator(config) {
      //如果第一个筛选条件的字段未选择则认为是未填写
      // console.log("_getFilterGenerator", config);
      if (!config.value[0].field) {
        return "未填写";
      } else {
        return "请进入配置页面查看";
      }
    }
    function _getNumberInputValue(config) {
      if (!config.value) {
        return "未填写";
      } else {
        return config.value;
      }
    }
    function _getTopicNumInputValue(config) {
      if (config.topicRadio == "auto") {
        return "自动计算最优";
      } else if (!config.value) {
        return "未填写";
      } else {
        return config.value;
      }
    }
    function _getClusterNumInputValue(config) {
      if (config.value) {
        return config.value;
      } else {
        return "未填写";
      }
    }
    function _getSelectValue(config) {
      // console.log(
      //   "cop select",
      //   config.options,
      //   !config.options,
      //   !config.value,
      //   Array.isArray(config.options)
      // );
      if (
        !config.value ||
        !config.options ||
        (Array.isArray(config.options) && config.options.length == 0)
      ) {
        return "未填写";
      } else {
        let resultOption = config.options.find((option) => {
          return option.value == config.value;
        });
        if (resultOption) {
          // console.log("resultOption", resultOption);
          return resultOption.label;
        } else {
          return "数据错误";
        }
      }
    }
    function _getLineInputValue(config) {
      if (!config.value) {
        return "未填写";
      } else {
        return config.value;
      }
    }
    function _getRadioValue(config) {
      // console.log("cop radio", config.options, !config.options, !config.value);
      if (
        (!config.value && config.value != 0) ||
        !config.options ||
        (Array.isArray(config.options) && config.options.length == 0)
      ) {
        return "未填写";
      } else {
        let resultOption = config.options.find((option) => {
          return option.value == config.value;
        });
        if (resultOption) {
          // console.log("resultOption", resultOption);
          return resultOption.label;
        } else {
          return "数据错误";
        }
      }
    }
    function _getFolderSelectValue(config) {
      // console.log("folderSelect", config);
    }
    function _getMultiSelectValue(config) {
      // console.log("multi", config);

      if (!config.value || config.value.length == 0) {
        return "未填写";
      } else {
        let options = JSON.parse(JSON.stringify(config.options));
        let result = "";
        config.value.forEach((val) => {
          // console.log("val", val);
          let i = options.findIndex((option) => {
            return option.value == val;
          });
          result += options[i].label + "、";
        });
        // console.log("result", result);
        return result.slice(0, -1);
      }
    }
    // 实现子组件响应式展示父组件数据
    const nodeData = reactive({
      ...node.getData()
    });
    // console.log("node component data", nodeData);

    onMounted(() => {
      // 通过监听node的data是否改变，动态改变子组件中的响应式对象nodeData
      node.on("change:data", ({ current }) => {
        for (let k in current) {
          nodeData[k] = current[k];
        }
      });
      // node.id = 2323;
    });
    return {
      addIndex,
      nodeData,
      showTitleInput,
      changeLabel,
      isShowTitleInput,
      cancelChangeLabel,
      getConfigValue
    };
  }
};
</script>

<style lang="scss" scoped>
span {
  font-family: "Microsoft YaHei", "PingFangSC", "MicrosoftJhengHei", 华文细黑,
    STHeiti;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  .label {
    font-size: 14px;
    line-height: 40px;
  }
  .el-input {
    width: 130px;
  }
}

.card-body {
  color: #777777;

  .text {
    font-size: 12px;
  }
}

.text {
  font-size: 14px;
  line-height: 16px;
}

.item {
  margin-bottom: 18px;
}

.box-card {
  width: 210px;
  height: 130px;
}
.valid {
  border-color: #52c41a;
}
.invalid {
  border-color: #ff4d4f;
}
::v-deep .el-card__header {
  padding: 0px 10px !important;
}
:deep(.el-card__body) {
  height: 90px;
  overflow: scroll;
}
.status {
  .id {
    float: right;
    color: #acacac;
  }
  .valid {
    color: #52c41a;
  }
  .invalid {
    color: #ff4d4f;
  }
}
.grey {
  color: #acacac;
}
</style>
