<template>
  <div id="analysisDesign" v-show="viewTab == 1">
    <!-- 左侧module选项列表 -->

    <div class="module-collapse">
      <div class="title">节点列表</div>
      <div class="search-bar">
        <el-input v-model="moduleKeyword" placeholder="搜索节点">
          <template #suffix>
            <el-icon style="cursor: pointer">
              <search />
            </el-icon>
          </template>
        </el-input>
      </div>
      <div
        class="module-group"
        v-for="(moduleType, index) in moduleList"
        :key="'moduleType' + index"
      >
        <div class="module-group-title" @click="openModuleGroup(index)">
          <span :class="['iconfont ', 'icon-type ', moduleType.icon]"></span>
          <span>{{ moduleType.label }}</span>
          <span class="arrow-icon">
            <el-icon v-show="!activeModuleTypes[index]">
              <ArrowRight />
            </el-icon>
            <el-icon v-show="activeModuleTypes[index]">
              <ArrowDown />
            </el-icon>
          </span>
        </div>
        <transition name="moveCartoon" appear>
          <el-row
            :gutter="8"
            justify="space-between"
            v-show="activeModuleTypes[index]"
          >
            <el-col
              v-for="m in moduleType.children"
              :key="'module' + m.value"
              :span="m.span"
              v-show="!moduleKeyword || m.label.includes(moduleKeyword)"
            >
              <div
                class="module-tag"
                @click="addModule(m)"
                @mousedown="startDrag(m, $event)"
              >
                <span :class="['iconfont', 'icon-module', m.icon]"></span>
                <span>{{ m.label }}</span>
              </div>
            </el-col>
          </el-row>
        </transition>
      </div>
      <!-- <el-collapse
        accordion
        v-model="activeModuleTypes"
        v-for="(moduleType, index) in moduleList"
        :key="'moduleType' + index"
      >
        <el-collapse-item :name="index">
          <template #title>
            <div>
              <span
                :class="['iconfont ', 'icon-type ', moduleType.icon]"
              ></span>
              <span class="module-type-title">{{ moduleType.label }}</span>
            </div>
          </template>
          <div
            :class="[
              {
                'cursor-disable':
                  ifDisabled || modelStatus == 1 || modelInfo.readOnly
              },
              { hand: !ifDisabled && modelStatus != 1 && !modelInfo.readOnly },
              'module-title'
            ]"
            v-for="m in moduleType.children"
            :key="'module' + m.value"
            @click="addModule(m)"
          >
            <span :class="['iconfont', 'icon-module', m.icon]"></span>
            <span>{{ m.label }}</span>
          </div>
        </el-collapse-item>
      </el-collapse> -->
    </div>
    <!-- 右侧DAG设计视图 -->
    <div class="design-view">
      <div
        class="top-bar"
        v-if="modelStatus != null && modelStatus != 1 && !modelInfo.readOnly"
      >
        <el-button
          class="button"
          type="text"
          @click="restoreGraphData"
          :disabled="ifDisabled"
          v-show="modelStatus == 5"
        >
          <i class="el-icon-upload"></i>
          还原
        </el-button>
        <el-button
          class="button"
          type="text"
          @click="saveGraphData"
          :disabled="ifDisabled"
          v-if="!ifSystem"
        >
          <i class="el-icon-upload"></i>
          保存
        </el-button>
        <el-button
          class="button"
          type="text"
          @click="clearGraphData"
          :disabled="ifDisabled"
          v-if="!ifSystem"
        >
          <i class="el-icon-delete"></i>
          清空
        </el-button>
        <el-button
          class="button"
          type="primary"
          size="small"
          @click="runModel"
          :disabled="ifDisabled"
          v-if="!ifSystem"
        >
          <i class="iconfont icon-yunhang"></i>
          <span v-show="modelStatus == 2">重新运行</span>
          <span v-show="modelStatus != 2">开始运行</span>
        </el-button>
      </div>
      <div
        class="top-bar"
        v-if="modelStatus && modelStatus == 1 && !modelInfo.readOnly"
      >
        <el-button
          class="button"
          type="primary"
          size="small"
          @click="terminateModel"
          :disabled="ifDisabled"
        >
          <i class="iconfont icon-stop-circle"></i>

          停止运行
        </el-button>
      </div>
      <div v-loading="ifDisabled">
        <div
          class="module-graph-container"
          :id="'moduleGraph' + props.modelInfo.id"
        ></div>
      </div>

      <!-- <div class="module-graph-container" :id="'moduleGraph'"></div> -->
    </div>
    <!-- 配置/预览弹窗 -->
    <div class="dialog">
      <el-dialog
        v-model="dialogState.visible"
        :title="dialogState.title"
        width="60%"
        destroy-on-close
      >
        <!-- <component
          :is="dialogState.selectedConfig"
          :node="dialogState.node"
          :formerNodes="dialogState.formerNodes"
          :modelInfo="modelInfo"
          ref="configAndReviewRef"
          :isChanged="isChanged"
          @saveInModuleNode="saveInModuleNode"
          @setIsChange="setIsChange"
        ></component> -->
        <config-and-review
          :node="dialogState.node"
          :formerNodes="dialogState.formerNodes"
          :modelInfo="modelInfo"
          ref="configAndReviewRef"
          :isChanged="isChanged"
          @saveInModuleNode="saveInModuleNode"
          @setIsChange="setIsChange"
        ></config-and-review>

        <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogState.visible = false">取消</el-button>
            <el-button
              type="primary"
              :disabled="modelStatus == 1 || modelInfo.readOnly"
              @click="clickApplyConfigs"
            >
              应用
            </el-button>
            <!-- <el-button type="primary" disabled @click="clickApplyConfigs">
              应用
            </el-button> -->
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
  <div id="analysisConfig" v-if="viewTab == 0">
    <div class="moduleNav">
      <div class="title">模块列表</div>
      <div class="content">
        <li v-for="(node, index) in moduleListOrderByTopology" :key="index">
          <div>
            <span :class="['iconfont ', 'icon-type', node.data.icon]"></span>
            <span class="module-type-title">{{ node.data.label }}</span>
          </div>
        </li>
      </div>
    </div>
    <div class="moduleConfigList">
      <div
        class="top-bar"
        v-if="modelStatus != null && modelStatus != 1 && !modelInfo.readOnly"
      >
        <el-button
          class="button"
          type="text"
          @click="saveGraphDataOnConfigView"
          :disabled="ifDisabled"
          v-if="!ifSystem"
        >
          <i class="el-icon-upload"></i>
          保存
        </el-button>
        <!-- <el-button
          class="button"
          type="text"
          @click="clearGraphData"
          :disabled="ifDisabled"
        >
          <i class="el-icon-delete"></i>
          清空
        </el-button> -->
        <el-button
          class="button"
          type="primary"
          size="small"
          @click="runModel"
          :disabled="ifDisabled"
          v-if="!ifSystem"
        >
          <i class="iconfont icon-yunhang"></i>
          <span v-show="modelStatus == 2">重新运行</span>
          <span v-show="modelStatus != 2">开始运行</span>
        </el-button>
      </div>
      <div
        class="top-bar"
        v-if="modelStatus && modelStatus == 1 && !modelInfo.readOnly"
      >
        <el-button
          class="button"
          type="primary"
          size="small"
          @click="terminateModel"
          :disabled="ifDisabled"
        >
          <i class="iconfont icon-stop-circle"></i>

          停止运行
        </el-button>
      </div>
      <div>
        <div
          v-for="(singleConfig, index) in moduleConfigList"
          :key="singleConfig"
          class="config"
        >
          <div class="config-bar">
            <span class="label">{{ singleConfig.node.data.label }}</span>
            <span class="switch" v-if="singleConfig.node.data.persistent">
              缓存此模块结果
              <el-switch
                v-model="singleConfig.node.data.ifPersistent"
                @change="(val) => changeIfPersistent(val, index, singleConfig)"
              ></el-switch>
            </span>
          </div>
          <div class="config-body">
            <config-template
              :ref="(el) => (configTemplateRef[index] = el)"
              :node="singleConfig.node"
              :formerNodes="singleConfig.formerNodes"
              :modelInfo="modelInfo"
              :isChanged="isChanged"
              :modelStatus="modelStatus"
              @saveInModuleNodeAtOnce="saveInModuleNodeAtOnce"
              @setIsChange="setIsChange"
            ></config-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  toRefs,
  getCurrentInstance,
  watch,
  computed,
  onMounted,
  onUnmounted,
  provide,
  nextTick
} from "vue";
import { Addon, Graph, Shape } from "@antv/x6";
import { register } from "@antv/x6-vue-shape";
import nodeComponent from "./NodeComponent";
import ConfigTemplate from "./config/ConfigTemplate.vue";
import {
  optionSourceFunctionMap,
  fieldsSourceFunctionMap,
  configSourceFunctionMap
} from "@/constant/analysis/sourceFunctionMap";
// "../../../../constant/textLibrary/analysis/sourceFunctionMap";
import {
  moduleInfo,
  MODULE_LIST,
  moduleInfoVersion
} from "@/constant/analysis/moduleInfo.js";
import ConfigAndReview from "./config/ConfigAndReview";
import { httpPost } from "@/api/httpService";
import { ElMessage } from "element-plus";
import bus from "@/utils/EventBus.js";
import { Search } from "@element-plus/icons-vue";
import { Debounce } from "@/utils/utils";
import { confirmDeleteBox } from "@/utils/messageBox";
import { SYSTEM_USER_ID } from "@/constant/system";
import { ifShowFunctionMap } from "@/constant/analysis/ifShowFunctionMap.js";

// import { boundary } from "@antv/x6/lib/registry/connection-point/boundary";
// import { async } from "@antv/x6/lib/registry/marker/async";

const NODE_SHAPE = "vue-shape";
const SINGLE_INPUT_GROUP = "singleInputGroup";
const MULTI_INPUT_GROUP = "multiInputGroup";
const MULTI_OUTPUT_GROUP = "multiOutputGroup";
const GROUPS_OF_PORTS = {
  singleInputGroup: {
    position: "topOffset",
    // label: {
    //   position: "top" // 标签位置
    // },

    attrs: {
      circle: {
        r: 6,
        magnet: true,
        // stroke: "#31d0c6",
        stroke: "#C2C8D5",
        // stroke: "#e94743",
        strokeWidth: 2,
        fill: "#fff"
      }
    }
  },
  multiInputGroup: {
    position: "topOffset",
    // label: {
    //   position: "top" // 标签位置
    // },
    markup: [
      {
        tagName: "rect",
        selector: "rect"
      }
    ],
    attrs: {
      // ellipse: {
      //   rx: 8,
      //   ry: 6,
      //   magnet: true,
      //   // stroke: "#31d0c6",
      //   stroke: "#C2C8D5",
      //   // stroke: "#e94743",
      //   strokeWidth: 2,
      //   fill: "#fff"
      // }
      rect: {
        magnet: true,
        stroke: "#C2C8D5",
        fill: "#fff",
        strokeWidth: 2,
        width: 24,
        height: 12,
        x: -12,
        y: -6
      }
    }
  },
  multiOutputGroup: {
    position: "bottomOffset",
    // label: {
    //   position: "bottom" // 标签位置
    // },
    markup: [
      {
        tagName: "rect",
        selector: "rect"
      }
    ],
    attrs: {
      rect: {
        magnet: true,
        stroke: "#C2C8D5",
        fill: "#fff",
        strokeWidth: 2,
        width: 24,
        height: 12,
        x: -12,
        y: -6
      }
    }
  }
};
export default {
  props: ["modelInfo"],
  components: { ConfigAndReview, ConfigTemplate },
  setup(props) {
    // console.log("analysis model design:props.modelinfo", props.modelInfo);
    const currentAnalysisId = ref(props.modelInfo.analysisId);
    // const bus = getCurrentInstance().appContext.config.globalProperties.$bus;
    // console.log("analysis id", currentAnalysisId);
    let ifDisabled = ref(true);
    let ifSystem = computed(() => {
      let result = props.modelInfo.userId == SYSTEM_USER_ID;
      if (result) {
        // ifDisabled.value = true;
      } else {
        // ifDisabled.value = false;
      }
      return result;
    });
    let isChanged = ref(false);
    let modelStatus = ref(null); // 模型状态：0已创建，1进行中，2已完成，3异常
    let viewTab = ref(1);
    let configTemplateRef = ref([]);
    const moduleKeyword = ref(""); //左侧菜单栏关键字搜索
    // 配置视图数据
    const configViewState = reactive({
      moduleListOrderByTopology: null,
      moduleConfigList: null
    });

    function changeModelView(_viewTab) {
      viewTab.value = _viewTab;
      if (viewTab.value == 0) {
        // console.log("change modelview to config");
        initConfigView();
      }
    }
    // 初始化配置界面
    function initConfigView() {
      let nodes = graph.getNodes();
      let nodesIdOrderByTopoloy = getTopologyOrderOfGraph(nodes); // 拓扑排序计算后的节点id
      let nodesOrderByTopoloy = nodesIdOrderByTopoloy.map((id) => {
        return graph.getCellById(id);
      });
      // 左侧模块列表显示内容
      configViewState.moduleListOrderByTopology = nodesOrderByTopoloy;
      // console.log("nodesOrderByTopoloy", nodesOrderByTopoloy);
      // 右侧配置列表
      configViewState.moduleConfigList = nodesOrderByTopoloy.map((node) => {
        return {
          node: node,
          formerNodes: getFormerNeighborsMap(node)
        };
      });
      // console.log(
      //   "configViewState.moduleConfigList",
      //   configViewState.moduleConfigList
      // );
      // console.log("initConfigView", configTemplateRef);
    }
    function saveInModuleNodeAtOnce(node, isConfigChanged) {
      // console.log("saveInModuleNodeAtOnce", node, isConfigChanged);
      let changedNode = graph.getCellById(node.id);
      changedNode.setData(node.data, { overwrite: true });
      // console.log("changed node", changedNode);
      setTimeout(() => {
        updateSuccessorsNode(changedNode, {
          resetStatus: isConfigChanged
        });
      }, 0);
    }
    // ==================== module列表 ============
    //  --展开项
    const activeModuleTypes = ref({});
    MODULE_LIST.forEach((module, mIndex) => {
      activeModuleTypes.value[mIndex] = true;
    });
    function openModuleGroup(index) {
      // if (activeModuleTypes.value == index) {
      activeModuleTypes.value[index] = !activeModuleTypes.value[index];
      // } else {
      //   activeModuleTypes.value = index;
      // }
    }
    //  --module list
    const moduleList = ref(MODULE_LIST);
    function startDrag(m, e) {
      // 获取module信息，并作为新生成的module模板
      if (
        ifDisabled.value ||
        modelStatus.value == 1 ||
        props.modelInfo.readOnly
      ) {
        ElMessage.warning("处于进行中状态的模型无法添加任务节点");
        return;
      }

      isChanged.value = true;
      const module = JSON.parse(JSON.stringify(moduleInfo[m.name]));
      // 计算新生成的moduleIndex
      const moduleIndex = getNextModuleIndex(graph);
      // 遍历input和output生成链接桩
      const itemsOfPorts = [];
      for (let i in module.input) {
        let input = module.input[i];
        const newPort = {
          id: moduleIndex + "-in-" + input.name,
          group: input.group,
          requiredType: input.requiredType,
          portName: input.name,
          portLabel: input.label
        };
        itemsOfPorts.push(newPort);
      }
      for (let i in module.output) {
        let output = module.output[i];
        const newPort = {
          id: moduleIndex + "-out-" + output.name,
          group: output.group,
          outputType: output.outputType,
          portName: output.name,
          portLabel: output.label
        };
        itemsOfPorts.push(newPort);
      }
      const node = graph.createNode({
        id: moduleIndex,
        // x: graph.pageToLocal(ORIGIN_X, ORIGIN_Y).x,
        // y: graph.pageToLocal(ORIGIN_X, ORIGIN_Y).y,
        width: 210,
        height: 130,
        shape: NODE_SHAPE,
        label: moduleIndex.toString() + "-" + module.label,
        data: {
          moduleIndex: moduleIndex,
          ...module,
          moduleType: m.value,
          moduleName: m.name
        },
        attrs: {
          body: {
            width: 20,
            height: 20,
            // stroke: "grey",
            fill: "white"
          }
        },
        component: "module",

        ports: {
          groups: GROUPS_OF_PORTS,
          items: itemsOfPorts
        }
      });
      const dnd = new Addon.Dnd({
        target: graph,
        getDragNode: (node) => {
          return node;
        },
        getDropNode: (node) => {
          // window.removeEventListener('mousemove',this.listenMouseMove)

          return node.clone({ keepId: true });
        }
      });
      dnd.start(node, e);
    }
    // 新建模块
    function addModule(m) {
      // 获取module信息，并作为新生成的module模板
      if (
        ifDisabled.value ||
        modelStatus.value == 1 ||
        props.modelInfo.readOnly
      ) {
        return;
      }

      isChanged.value = true;
      const module = JSON.parse(JSON.stringify(moduleInfo[m.name]));
      // console.log("new moduleinfo", module, m);

      // 计算新生成的moduleIndex
      const moduleIndex = getNextModuleIndex(graph);
      // 遍历input和output生成链接桩
      const itemsOfPorts = [];
      for (let i in module.input) {
        let input = module.input[i];
        const newPort = {
          id: moduleIndex + "-in-" + input.name,
          group: input.group,
          requiredType: input.requiredType,
          portName: input.name,
          portLabel: input.label
        };
        itemsOfPorts.push(newPort);
      }
      for (let i in module.output) {
        let output = module.output[i];
        const newPort = {
          id: moduleIndex + "-out-" + output.name,
          group: output.group,
          outputType: output.outputType,
          portName: output.name,
          portLabel: output.label
        };
        itemsOfPorts.push(newPort);
      }
      const ORIGIN_X = 600;
      const ORIGIN_Y = 500;

      graph.addNode({
        id: moduleIndex,
        x: graph.pageToLocal(ORIGIN_X, ORIGIN_Y).x,
        y: graph.pageToLocal(ORIGIN_X, ORIGIN_Y).y,
        width: 210,
        height: 130,
        shape: NODE_SHAPE,
        label: moduleIndex.toString() + "-" + module.label,
        data: {
          moduleIndex: moduleIndex,
          ...module,
          moduleType: m.value,
          moduleName: m.name
        },
        attrs: {
          body: {
            width: 20,
            height: 20,
            // stroke: "grey",
            fill: "white"
          }
        },
        component: "module",

        ports: {
          groups: GROUPS_OF_PORTS,
          items: itemsOfPorts
        }
      });
    }
    const moduleListState = reactive({
      activeModuleTypes,
      moduleList,
      addModule
    });
    // ====================Graph===================f
    // 载入图数据
    // const graphData = {
    //   nodes: [],
    //   edges: []
    // };
    // const data = {
    //   // 节点
    //   nodes: [
    //     {
    //       id: "node1", // String，可选，节点的唯一标识
    //       x: 40, // Number，必选，节点位置的 x 值
    //       y: 40, // Number，必选，节点位置的 y 值
    //       width: 160, // Number，可选，节点大小的 width 值
    //       height: 100, // Number，可选，节点大小的 height 值
    //       label: "hello", // String，节点标签
    //       ports: {
    //         groups: {
    //           group1: {
    //             attrs: {
    //               ellipse: {
    //                 rx: 8,
    //                 ry: 6,
    //                 magnet: true,
    //                 stroke: "#31d0c6",
    //                 strokeWidth: 2,
    //                 fill: "#fff"
    //               }
    //             }
    //           }
    //         },
    //         items: [
    //           {
    //             id: "port1",
    //             group: "group1" // 指定分组名称
    //           },
    //           {
    //             id: "port2",
    //             group: "group1" // 指定分组名称
    //           },
    //           {
    //             id: "port3",
    //             group: "group1" // 指定分组名称
    //           }
    //         ]
    //       }
    //     },
    //     {
    //       id: "node2", // String，节点的唯一标识
    //       x: 160, // Number，必选，节点位置的 x 值
    //       y: 180, // Number，必选，节点位置的 y 值
    //       width: 80, // Number，可选，节点大小的 width 值
    //       height: 40, // Number，可选，节点大小的 height 值
    //       label: "world", // String，节点标签
    //       ports: {
    //         groups: {
    //           group1: {
    //             attrs: {
    //               circle: {
    //                 r: 6,
    //                 magnet: true,
    //                 stroke: "#31d0c6",
    //                 strokeWidth: 2,
    //                 fill: "#fff"
    //               }
    //             }
    //           }
    //         },
    //         items: [
    //           {
    //             id: "port4",
    //             group: "group1" // 指定分组名称
    //           },
    //           {
    //             id: "port5",
    //             group: "group1" // 指定分组名称
    //           },
    //           {
    //             id: "port6",
    //             group: "group1" // 指定分组名称
    //           }
    //         ]
    //       }
    //     }
    //   ],
    //   // 边
    //   edges: [
    //     {
    //       source: "node1", // String，必须，起始节点 id
    //       target: "node2" // String，必须，目标节点 id
    //     }
    //   ]
    // };
    const getNextModuleIndex = (graph) => {
      const graphNodes = graph.getNodes();
      if (graphNodes.length == 0) {
        return 1;
      }
      return (
        Math.max(
          ...graphNodes.map((item) => {
            return item.data.moduleIndex ? item.data.moduleIndex : 0;
          })
        ) + 1
      );
    };
    function saveGraphDataOnConfigView() {
      for (let ct of configTemplateRef.value) {
        let isValid = ct.validateConfigs();
        // console.log("save,isvalid", isValid,ct);
        if (!isValid) {
          ElMessage.warning("存在未配置项，无法保存");
          return;
        }
      }
      saveGraphData();
    }
    const restoreGraphData = Debounce(() => {
      confirmDeleteBox("是否将模型还原到上次成功运行的状态？").then(
        async () => {
          httpPost(`/model/restore/${currentAnalysisId.value}`).then((res) => {
            if (res.code == 0) {
              getGraphData();
              ElMessage.success("还原成功");
            }
          });
        }
      );
    });
    function saveGraphData() {
      let graphData = graph.toJSON({ deep: true }).cells;
      // console.log("save graph data", graphData);

      httpPost("/model/save", {
        analysisId: currentAnalysisId.value,
        cellList: graphData,
        moduleInfoVersion: moduleInfoVersion
      }).then((res) => {
        if (res.code == 0) {
          ElMessage.success("保存成功");
          isChanged.value = false;
          updateGraphData();
        }
      });
    }
    //检查版本并更新配置模板
    function checkModuleVersion(cellList) {
      // console.log("check module version");

      for (let index = 0; index < cellList.length; index++) {
        let cell = cellList[index];
        if (cell.shape != "edge") {
          let oldData = cell.data;
          let moduleName = oldData.moduleName;
          let moduleTemplate = moduleInfo[moduleName];
          // 检查并更新configs
          _reviewConfigs(cell, moduleTemplate);
          // 检查并更新fields
          _reviewFields(cell, moduleTemplate);
          //检查并更新input
          //检查并更新output
          //检查并更新baseInput
          if ("baseInput" in moduleTemplate) {
            oldData.baseInput = moduleTemplate.baseInput;
          }
          // console.log("node", cell);
        }
      }
      // console.log("after check", cellList);
      // 检查并更新configs中属性，并新增配置项
      function _reviewConfigs(node, moduleTemplate) {
        console.log("🚀 ~ _reviewConfigs ~ moduleTemplate:", moduleTemplate);
        let configAttrs = ["value", "sampleType", "topicRadio", "options"]; // 用于记录需要从原模型中保留的config属性，一般都是用户可以操作的属性
        let newConfigs = JSON.parse(JSON.stringify(moduleTemplate)).configs;
        console.log("🚀 ~ _reviewConfigs ~ newConfigs:", newConfigs);
        let oldConfigs = node.data.configs;
        for (let key in newConfigs) {
          console.log("🚀 ~ _reviewConfigs ~ key:", key);
          let oldConfig = oldConfigs[key];
          let newConfig = newConfigs[key];
          if (oldConfig) {
            // 如果原有模型的配置项存在，则将原模型的configAttrs迁移过去
            configAttrs.forEach((attr) => {
              if (attr in newConfig && attr in oldConfig) {
                newConfig[attr] = oldConfig[attr];
              }
              if (attr == "options") {
                newConfig[attr] = oldConfig[attr];
              }
            });
          }
          // 如果该配置项为文本库，则保留source
          if (key == "textLibraryId") {
            newConfig.source = oldConfig.source;
          }
          if(key == "tokenizeDictionaryObjectId"){
            newConfig.value = newConfig.value|| moduleTemplate.configs.tokenizeDictionaryObjectId.value;
            console.log("🚀 ~ _reviewConfigs ~ newConfig:", newConfig);
          }
        }
        node.data.configs = newConfigs;
        console.log("review configs", node.data);
      }
      // 检查并更新fields
      function _reviewFields(node, moduleTemplate) {
        let newFields = JSON.parse(JSON.stringify(moduleTemplate.fields));
        node.data.fields = newFields;
      }
    }

    function getGraphData() {
      httpPost("/model/get/" + currentAnalysisId.value).then((res) => {
        if (res.code == 0) {
          // console.log("get graph data", res.data);
          modelStatus.value = res.data.modelStatus;
          ifDisabled.value = false;
          // setTimeout(() => {
          // if (res.data.moduleInfoVersion != moduleInfoVersion) {
          checkModuleVersion(res.data.cellList);
          // }
          graph.fromJSON({ cells: res.data.cellList });
          // if (res.data.moduleInfoVersion != moduleInfoVersion) {
          updateGraphByTopologyOrder();
          // }

          // graph.fromJSON(res.data.modelMap);
          // graph.fromJSON(data);
          // }, 0);
        }
      });
    }
    function clearGraphData() {
      ifDisabled.value = true;
      isChanged.value = true;
      httpPost("/model/save", {
        analysisId: currentAnalysisId.value,
        cellList: [],
        moduleInfoVersion: moduleInfoVersion
      }).then((res) => {
        if (res.code == 0) {
          graph.clearCells();

          setTimeout(() => {
            // console.log("clear end");
            ifDisabled.value = false;
            isChanged.value = false;
          }, 1000);
        }
      });
    }
    function runModelOnConfigView() {
      for (let ct of configTemplateRef.value) {
        let isValid = ct.validateConfigs();
        if (!isValid) {
          ElMessage.warning("存在未配置项，无法运行");
          return;
        }
      }
      runModel();
    }
    // 运行模型
    function runModel() {
      if (!isGraphValid()) {
        ElMessage.warning("存在未配置模块，请配置后重试！");
        return;
      }

      ifDisabled.value = true;
      isChanged.value = false;
      let graphData = graph.toJSON({ deep: true }).cells;
      httpPost("/model/run", {
        analysisId: currentAnalysisId.value,
        cellList: graphData,
        moduleInfoVersion: moduleInfoVersion
      }).then((res) => {
        if (res.code == 0) {
          updateGraphData();

          ElMessage.success("开始运行！");
        }
        // console.log("run model", res);
        // modelStatus.value = 1;

        setTimeout(() => {
          ifDisabled.value = false;
        }, 1000);
      });
    }
    // 停止运行模型
    function terminateModel() {
      ifDisabled.value = true;

      httpPost("/model/terminate/" + currentAnalysisId.value).then((res) => {
        // console.log("terminate model", res);
        if (res.code == 0) {
          updateGraphData();
          setTimeout(() => {
            ifDisabled.value = false;
          }, 1000);
        }
      });
    }
    // 渲染画布
    var graph;
    try {
      Graph.registerPortLayout("topOffset", (portsPositionArgs, elemBBox) => {
        return portsPositionArgs.map((_, index) => {
          const step = elemBBox.width / (portsPositionArgs.length + 1);
          const y = 19;
          const x = step * (index + 1);
          return {
            position: {
              x: x,
              y: y
            },
            angle: 0
          };
        });
      });
      Graph.registerPortLayout(
        "bottomOffset",
        (portsPositionArgs, elemBBox) => {
          return portsPositionArgs.map((_, index) => {
            const step = elemBBox.width / (portsPositionArgs.length + 1);
            const y = 20 + elemBBox.height;
            const x = step * (index + 1);
            return {
              position: {
                x: x,
                y: y
              },
              angle: 0
            };
          });
        }
      );
    } catch (error) {
      // console.log("error");
    }

    onMounted(() => {
      bus.on("updateSuccessorsNode", (node) => {
        // console.log("receive emit", node);
        let startNode = graph.getCellById(node);
        // console.log("receive emit", startNode);

        updateSuccessorsNode(startNode, { resetStatus: false });
      });
      const container = document.getElementById(
        "moduleGraph" + props.modelInfo.id
      );
      // insertCss(`
      //   .module-graph-container{
      //     // width: calc(100% - 140px);
      //   }
      // `);

      graph = new Graph({
        container: container,
        // width: 1200,
        height: 700,
        grid: { visible: true },
        // background: { color: "#f0f0f0" },
        // 高亮样式
        highlighting: {
          magnetAvailable: {
            name: "stroke",
            args: {
              attrs: {
                fill: "#fff",
                stroke: "#00E3E3"
              }
            }
          },
          magnetAdsorbed: {
            name: "stroke",
            args: {
              attrs: {
                fill: "#fff",
                // stroke: "#31d0c6"
                stroke: "	#52c41a"
              }
            }
          }
        },

        //创建port后的回调
        onPortRendered(args) {
          const container = args.container;
          // 为链接桩添加提示框
          container.firstChild.innerHTML =
            `<title>` + args.port.portLabel + `</title>`;
        },
        // // 拖拽画布
        // panning: {
        //   enabled: false
        // },
        // 滚动
        scroller: {
          enabled: true,
          pannable: true,
          minVisibleWidth: 1000,
          minVisibleHeight: 800,
          className: "graph-scroller"
        },
        // 滚轮缩放
        mousewheel: {
          enabled: true,
          modifiers: ["ctrl", "meta"]
        },
        // 配置连线规则
        connecting: {
          snap: {
            radius: 50 // 距离50px时会自动吸附
          },
          allowBlank: false, // 不准连接到空白画布
          allowMulti: "withPort", // 起始和终止节点之间可以创建多条边，但必须要要链接在不同的链接桩上
          allowLoop: false, // 不允许同一节点自连
          allowNode: false, // 不允许连接到节点上
          allowEdge: false, // 不允许连接到边上
          allowPort: true, // 允许连接到链接桩上
          connector: "rounded",
          connectionPoint: "boundary",
          highlight: true,
          router: {
            name: "er",
            args: {
              direction: "V"
            }
          },
          // connector: {
          //   name: "smooth",
          //   args: {
          //     direction: "V"
          //   }

          // },

          //定制样式
          createEdge() {
            return new Shape.Edge({
              attrs: {
                line: {
                  stroke: "grey",
                  strokeWidth: 1.5,
                  targetMarker: {
                    name: "classic",
                    size: 7
                  }
                }
              }
            });
          },
          // 连接前校验
          validateConnection({
            edge,
            sourcePort,
            targetPort,
            sourceCell,
            targetCell,
            sourceMagnet,
            targetMagnet
          }) {
            // edge.attr("line", { stroke: "grey", strokeWidth: 1.5 });
            let sourcePortObj = sourceCell.getPort(sourcePort);
            let targetPortObj = targetCell.getPort(targetPort);
            // 检验连线的起点和终点的端口类型是否正确
            if (
              sourcePortObj.group == SINGLE_INPUT_GROUP ||
              targetPortObj.group == MULTI_OUTPUT_GROUP
            ) {
              return false;
            }
            // 检验连接终点是否已经被链接
            if (checkPortIsConnected(targetCell, targetPort)) {
              return false;
            }
            // 检验连接是否成环
            if (graph.isSuccessor(targetCell, sourceCell)) {
              return false;
            }
            // 检验连线的终点和起点的类型是否匹配
            if (
              !checkConnectType(sourceCell, sourcePort, targetCell, targetPort)
            ) {
              return false;
            }
            if (
              sourceCell.getData().moduleType == 101 &&
              targetCell.getData().moduleType == 401
            ) {
              // 不允许文本库与表格直接相连
              return false;
            }

            return true;
          }
        }
      });
      // 注册节点vue组件
      Graph.registerVueComponent(
        "module",
        {
          template: `<node-component @updateModuleNode="updateSuccessorsNode"></node-component>`,
          methods: {
            updateSuccessorsNode: (node) => {
              // console.log("emit update", node);
              bus.emit("updateSuccessorsNode", node);
            }
          },
          components: {
            nodeComponent
          }
        },
        true
      );

      graph.on("node:click", ({ e, node }) => {
        console.log("click node", node.data);
      });
      graph.on("edge:click", ({ e, edge }) => {
        // console.log("click edge", edge, e);
      });
      graph.on("node:dblclick", ({ node }) => {
        dialogState.title = node.data.label;
        dialogState.formerNodes = getFormerNeighborsMap(node);
        let visible = true;
        // let singleInputPorts = node.getPortsByGroup(SINGLE_INPUT_GROUP);
        // let multiInputPorts = node.getPortsByGroup(MULTI_INPUT_GROUP);
        let inputPorts = getAllInputPort(node);
        // console.log("input ports", inputPorts);
        if (!node.data.configs && !node.data.configSource) {
          visible = false;
        }
        if (JSON.stringify(node.data.configs) == "{}") {
          visible = false;
          ElMessage.info("该模块无需配置");
        }
        // 判断是否连接完成
        if (visible) {
          // 检查singleInput连接
          for (var i in inputPorts) {
            let portName = inputPorts[i].portName;
            let formerNodes = dialogState.formerNodes[portName];
            // console.log("==========", formerNodes);

            if (formerNodes.length == 0) {
              //如果存在链接桩的连接数为0，则存在未连接的输入口
              visible = false;
              ElMessage({
                message: "存在未连接的输入端口，请连接后再进行配置!",
                type: "warning",
                duration: 1000
              });
              break;
            } else {
              // console.log("==========", formerNodes);
              for (var j in formerNodes) {
                let formerNode = formerNodes[j];
                // console.log("jjjj", formerNode);

                if (!formerNode.data.isValid) {
                  visible = false;
                  ElMessage({
                    message: "存在未配置的前置节点，请配置后再进行配置！",
                    type: "warning",
                    duration: 1000
                  });
                }
              }
            }
          }
        }

        dialogState.visible = visible;
        dialogState.node = node;
      });
      // 鼠标移入开启删除按钮
      graph.on("cell:mouseenter", ({ cell }) => {
        if (cell.isNode()) {
          cell.addTools([
            {
              name: "button",
              args: {
                markup: [
                  {
                    tagName: "circle",
                    selector: "button",
                    attrs: {
                      r: 8,
                      stroke: "red",
                      "stroke-width": 2,
                      fill: "red",
                      cursor: "pointer"
                    }
                  },
                  {
                    tagName: "text",
                    textContent: "X",
                    selector: "icon",
                    attrs: {
                      fill: "white",
                      "font-size": 10,
                      "text-anchor": "middle",
                      "pointer-events": "none",
                      y: "0.35em"
                    }
                  }
                ],
                x: "95%",
                y: "10%",
                offset: { x: 0, y: 0 },
                onClick(args) {
                  // console.log("delete node", args);
                  if (modelStatus.value == 1) {
                    ElMessage.warning("运行期间无法删除！");
                    return;
                  }
                  const deleteNode = args.cell;
                  const successorNodes = graph.getSuccessors(deleteNode);
                  // console.log("delete successors", successorNodes);
                  successorNodes.forEach((node) => {
                    clearModuleNode(node);
                  });
                  graph.removeConnectedEdges(deleteNode);
                  graph.removeNode(deleteNode);
                  isChanged.value = true;
                }
              }
            }
          ]);
        }
        if (cell.isEdge()) {
          cell.addTools([
            {
              name: "button",
              args: {
                markup: [
                  {
                    tagName: "circle",
                    selector: "button",
                    attrs: {
                      r: 8,
                      stroke: "red",
                      "stroke-width": 2,
                      fill: "red",
                      cursor: "pointer"
                    }
                  },
                  {
                    tagName: "text",
                    textContent: "X",
                    selector: "icon",
                    attrs: {
                      fill: "white",
                      "font-size": 10,
                      "text-anchor": "middle",
                      "pointer-events": "none",
                      y: "0.35em"
                    }
                  }
                ],
                distance: "50%",
                offset: { x: 0, y: 0 },
                onClick(args) {
                  // console.log("delete node", args);
                  if (modelStatus.value == 1) {
                    ElMessage.warning("运行期间无法删除！");
                    return;
                  }
                  const deleteEdge = args.cell;
                  const deleteNode = deleteEdge.getTargetCell();
                  const successorNodes = graph.getSuccessors(deleteNode);
                  successorNodes.push(deleteNode);
                  successorNodes.forEach((node) => {
                    clearModuleNode(node);
                  });
                  graph.removeEdge(deleteEdge);
                  isChanged.value = true;
                  // graph.removeConnectedEdges(deleteNode);
                }
              }
            }
          ]);
        }
      });
      // 鼠标移出时关闭删除按钮
      graph.on("cell:mouseleave", ({ cell }) => {
        cell.removeTools();
      });
      // 连接完成后对后续节点进行更新
      graph.on("edge:connected", ({ isNew, edge }) => {
        // edge.attr("line", { stroke: "grey", strokeWidth: 1.5 });
        // console.log("connected", isNew, edge);
        const startNode = edge.getSourceNode();
        const targetNode = edge.getTargetNode();
        setTimeout(() => {
          updateSuccessorsNode(targetNode);
        }, 0);
      });

      // 等待graph渲染完成再读入数据
      // setTimeout(() => {
      //   getGraphData();
      //   ifDisabled.value = false;
      // }, 2500);
      nextTick(() => {
        // console.log("nextTick", ifDisabled);
        getGraphData();
        window.onresize = () => {
          nextTick(() => {
            let width =
              document.getElementsByClassName("design-view")[0].clientWidth;
            console.log("resize", width);
            graph.resize(width);
            graph.centerContent();
          });
        };
      });

      //定时请求模型
    });
    const timer = setInterval(() => {
      // console.log("interval", modelStatus.value, currentAnalysisId.value);
      if (modelStatus.value == 1) {
        updateGraphData();
      }
    }, 5000);
    // 离开页面时销毁计时器
    onUnmounted(() => {
      // console.log("unmounted");
      clearInterval(timer);
    });
    function checkPortIsConnected(node, port) {
      let incomingEdgesList = graph.getIncomingEdges(node);

      for (let i in incomingEdgesList) {
        let edge = incomingEdgesList[i];
        let targetPortId = edge.getTargetPortId();
        let targetPort = node.getPort(targetPortId);
        if (targetPort.group == SINGLE_INPUT_GROUP && targetPortId == port) {
          return true;
        }
      }
      return false;
    }
    function checkConnectType(sourceNode, sourcePort, targetNode, targetPort) {
      let sourcePortObj = sourceNode.getPort(sourcePort);
      let targetPortObj = targetNode.getPort(targetPort);
      // console.log(
      //   "check type",
      //   sourcePortObj.outputType,
      //   targetPortObj.requiredType
      // );
      if (targetPortObj.requiredType.indexOf(sourcePortObj.outputType) < 0) {
        return false;
      }
      return true;
    }
    function getAllInputPort(node) {
      const singleInputPorts = node.getPortsByGroup(SINGLE_INPUT_GROUP);
      // 获取输入multiInputPort的列表
      const multiInputPorts = node.getPortsByGroup(MULTI_INPUT_GROUP);
      return [...singleInputPorts, ...multiInputPorts];
    }

    function getFormerNeighborsMap(node) {
      // 获取输入边的列表
      const incomingEdgesList = graph.getIncomingEdges(node);
      const result = {};
      const inputPorts = getAllInputPort(node);
      for (let i in inputPorts) {
        //遍历输入链接桩
        let portName = inputPorts[i].portName;
        result[portName] = [];
        for (let j in incomingEdgesList) {
          //遍历所有的边去比对，如果变得输出链接桩等于输入链接桩，则装入数组
          let edge = incomingEdgesList[j];
          let targetPort = edge.getTargetPortId();
          if (targetPort == inputPorts[i].id) {
            result[portName].push(graph.getCell(edge.getSource().cell));
          }
        }
      }
      return result;
    }
    // 弹窗 ----------------------------begin--------------
    const configAndReviewRef = ref(null);
    const dialogState = reactive({
      title: "",
      visible: false,
      selectedConfig: "configAndReview",
      config: {}
    });
    // 点击应用按钮
    function clickApplyConfigs() {
      if (modelStatus.value == 1 || props.modelInfo.readOnly) {
        return;
      }
      configAndReviewRef.value.applyConfigs(modelStatus);
    }
    // 更新后续节点配置和状态
    async function updateSuccessorsNode(
      startNode,
      params = { resetStatus: true }
    ) {
      // 获取需更新的子图中的节点
      const nodesOfSubGraph = [startNode, ...graph.getSuccessors(startNode)];
      const nodesOrderByTopoloy = getTopologyOrderOfGraph(nodesOfSubGraph);
      for (let i in nodesOrderByTopoloy) {
        // console.log("update node i", i);

        let id = nodesOrderByTopoloy[i];
        let node = graph.getCellById(id);
        let formerNodes = getFormerNeighborsMap(node);
        await updateModuleNode(node, formerNodes, params);
      }
      initConfigView();
    }
    async function updateGraphByTopologyOrder() {
      let nodes = graph.getNodes();
      let nodesOrderByTopoloy = getTopologyOrderOfGraph(nodes);
      for (let i in nodesOrderByTopoloy) {
        // console.log("update node i", i);

        let id = nodesOrderByTopoloy[i];
        let node = graph.getCellById(id);
        let formerNodes = getFormerNeighborsMap(node);
        await updateModuleNode(node, formerNodes, { resetStatus: false });
      }
      initConfigView();
    }
    // 获取nodesOfSubGraph的拓扑排序，参数未子图中node的合集
    function getTopologyOrderOfGraph(nodesOfSubGraph) {
      // 计算入度
      const inDegreeMap = getInDegreeOfSubGraph(nodesOfSubGraph);
      const result = [];
      for (let i = 0; i < nodesOfSubGraph.length; i++) {
        inDegreeMap.sort((a, b) => {
          return a.inDegree - b.inDegree;
        });
        let current = inDegreeMap.shift();
        result.push(current.id);
        let currentNode = graph.getCellById(current.id);
        inDegreeMap.forEach((item) => {
          let node = graph.getCellById(item.id);
          if (graph.isNeighbor(currentNode, node, { outgoing: true })) {
            item.inDegree--;
          }
        });
      }
      return result;
    }
    function getInDegreeOfSubGraph(nodes) {
      let inDegreeMap = [];
      for (let i in nodes) {
        let node = nodes[i];
        // 获取每个节点的前置节点（仅计算子图中的节点）
        let formerNodes = graph
          .getNeighbors(node, { incoming: true })
          .filter((item) => {
            for (let j in nodes) {
              if (item.id == nodes[j].id) {
                return item;
              }
            }
          });
        inDegreeMap.push({
          id: node.id,
          inDegree: formerNodes.length
        });
      }
      return inDegreeMap;
    }
    // 将子组件更新后的node中的属性赋值给父组件对应的node
    function saveInModuleNode(node, isConfigChanged) {
      console.log("save in module node", node.data, node.data.isValid);
      dialogState.node.setData(
        { ...node.data, isValid: node.data.isValid },
        { overwrite: true }
      );

      dialogState.visible = false;
      setTimeout(() => {
        updateSuccessorsNode(dialogState.node, {
          resetStatus: isConfigChanged
        });
      }, 0);
    }
    function clearModuleNode(node) {
      let formerNodesMap = getFormerNeighborsMap(node);
      // console.log("clear node", node);

      let data = node.getData();
      let configs;
      if (data.moduleType == 502) {
        // 水平布局
        configs = getConfigs(node, formerNodesMap);
      } else {
        configs = JSON.parse(
          JSON.stringify(moduleInfo[data.moduleName].configs)
        );
      }

      // console.log("1", configs);
      let fields = JSON.parse(
        JSON.stringify(moduleInfo[data.moduleName].fields)
      );
      let configNum = 0;
      for (let c in configs) {
        configNum++;
      }
      let isValid = !configNum;

      node.setData(
        {
          ...data,
          isValid: isValid,
          configs: configs,
          fields: fields,
          progress: 0,
          moduleStatus: 0
        },
        { overwrite: true }
      );
      // console.log("set data", node.getData());
    }
    // 更新/获取最新的configs
    function getConfigs(node, formerNodes) {
      const configSource = node.data.configSource;
      const configFunction = configSourceFunctionMap[configSource];
      if (!configSource) {
        //没有configsource则直接根据configs进行赋值
        return node.data.configs;
      } else {
        //有configsource则根据configs进行赋值
        return configFunction({
          node: node,
          formerNodes: formerNodes
        });
      }
    }
    // TODO：需要重构
    async function updateModuleNode(node, formerNodes, params) {
      console.log("update module", node, formerNodes);
      const configs = getConfigs(node, formerNodes); //获取当前节点的配置
      const data = JSON.parse(JSON.stringify(node.getData())); //获取当前节点的data

      // 先更新配置项（configs），检查是否合法
      let result = await getOptionsBySource();
      node.setData(
        {
          ...data,
          configs: result.configs,
          isValid: result.isValid
        },
        { overwrite: true }
      );

      //必须等待节点中的data更新了configs之后，才可以去更新fields
      let newFields = await getFields(node);
      if (newFields) {
        node.setData(
          {
            ...data,
            configs: result.configs,
            fields: newFields,
            isValid: result.isValid
          },
          { overwrite: true }
        );
      }

      // 若当前节点不合法，则更新后面邻居节点为isValid：false
      // if (!result.isValid) {
      //   graph.getNeighbors(node, { outgoing: true }).forEach((node) => {
      //     let data = node.getData();
      //     node.setData({ ...data, isValid: false }, { overwrite: true });
      //   });
      // }
      // 如果resetStatus为true，则重置节点运行状态，
      if (params.resetStatus) {
        let lastData = node.getData();
        node.setData(
          { ...lastData, progress: 0, moduleStatus: 0 },
          { overwrite: true }
        );
      }
      // 获取新的option并检验原有value是否合法，并更新isValid的状态
      async function getOptionsBySource() {
        let isValid = node.getData().isValid;
        // console.log("origin isvalid", isValid);
        let configNum = 0; //记录配置数量，如果配置数量为0，则将isValid设为true
        for (let c in configs) {
          const ifShowFunc = ifShowFunctionMap[configs[c].ifShow];

          let configMap = {};
          for (let key in configs) {
            configMap[key] = configs[key].value;
          }
          if (ifShowFunc && !ifShowFunc(configMap)) {
            console.log("break");
            break;
          }
          configNum++;

          const sourceFunc = optionSourceFunctionMap[configs[c].source];
          if (sourceFunc) {
            // 获取最新的options
            configs[c].options = await sourceFunc({
              node: node,
              formerNodes: formerNodes,
              analysisId: currentAnalysisId.value
            });
            let componentType = configs[c].componentType; //当前配置项的类型
            let value = configs[c].value; //当前配置项的值
            let options = configs[c].options;
            if (["filterGenerator"].indexOf(componentType) > -1) {
              value.forEach((expression, eIndex) => {
                if (
                  !configs[c].options.find((option) => {
                    return expression.field == option.value;
                  })
                ) {
                  // console.log("not found expression field");
                  configs[c].value = [
                    {
                      field: null,
                      type: null,
                      op: null,
                      value: null,
                      compound_op: null
                    }
                  ];
                }
              });
              continue;
            } else if (["multiSelect"].indexOf(componentType) > -1) {
              // 检查多选类型的value是否合法
              let notExistValue = [];

              //判断多选是否存在值
              if (configs[c].value) {
                configs[c].value.forEach((val, index) => {
                  if (
                    !configs[c].options.find((option) => {
                      return val == option.value;
                    })
                  ) {
                    // console.log("not found", configs[c].options, val);
                    notExistValue.push(val);
                    isValid = false;
                  }
                  // console.log("config", c, configs[c].options);
                });
                // 去除不存在的val
                configs[c].value = configs[c].value.filter((val) => {
                  if (notExistValue.indexOf(val) > -1) {
                    // console.log("not exist", val);
                    return false;
                  } else {
                    // console.log(" exist", val);

                    return true;
                  }
                });
                console.log("multi select", configs[c]);
              }
              if (
                (!configs[c].value || configs[c].value.length == 0) &&
                !configs[c].clearable
              ) {
                // console.log("not found", configs[c].options, configs[c].value);
                configs[c].value = null;
                isValid = false;
              }
            } else if (["singleSelect", "radio"].indexOf(componentType) > -1) {
              //value只是一个值或null
              if (
                !configs[c].options.find((item) => {
                  return item.value == configs[c].value;
                })
              ) {
                // 检查是否为可以为空的选项;
                if (configs[c].clearable && configs[c].value == null) {
                  //可以为空的选项则跳过
                  // console.log("可以为空的选项");
                } else {
                  //不可以为空的value和不合法的value则重置
                  // console.log(
                  //   "not found",
                  //   configs[c].options,
                  //   configs[c].value
                  // );
                  configs[c].value = null;
                  isValid = false;
                }
              }
            } else if (["pictureSelect"].indexOf(componentType) > -1) {
              if (
                !configs[c].options.find((item) => {
                  //检测是否为正常选项，或词云图的objectID是否存在于options中
                  return item.objectId == configs[c].value;
                })
              ) {
                // debugger;
                if (configs[c].clearable && configs[c].value == null) {
                  // console.log("可以为空的选项");
                } else {
                  // console.log(
                  //   "not found",
                  //   configs[c].options,
                  //   configs[c].value
                  // );
                  configs[c].value = null;
                  isValid = false;
                }
              }
            } else if (["draggableList"].indexOf(componentType) > -1) {
              // console.log("check sortlist");
              for (let i = 0; i < value.length; i++) {
                let item = value[i];
                let isExist = options.findIndex((option) => {
                  // console.log(option, "==", item);
                  return option.id == item.id && option.label == item.label;
                });
                // console.log("isExist", isExist);
                if (isExist < 0) {
                  value.splice(i, 1);
                  i--;
                }
              }

              options.forEach((option) => {
                if (
                  value.findIndex((item) => {
                    return option.id == item.id && option.label == item.label;
                  }) < 0
                ) {
                  value.push(option);
                }
              });
            }
          }
        }
        // console.log("configs", configs, isValid);
        isValid = configNum == 0 ? true : isValid;

        return { isValid, configs };
      }
      async function getFields(node) {
        let source = node.data.fields.source;
        if (!source) {
          return;
        }
        let fieldsSourceFunc = fieldsSourceFunctionMap[node.data.fields.source];
        await fieldsSourceFunc({ node: node, formerNodes: formerNodes });
        // console.log("get fields finish", node.getData().fields);
        return node.getData().fields;
      }

      // console.log("----------------------------------------", node.getData());
    }
    // 更改isChange
    function setIsChange(boolVal) {
      isChanged.value = boolVal;
      // console.log("isChanged", isChanged);
    }
    function updateGraphData() {
      httpPost("/model/get/started/" + currentAnalysisId.value).then((res) => {
        if (res.code == 0) {
          // console.log("update graph data", res.data);
          let cellList = res.data.moduleMap;
          modelStatus.value = res.data.modelStatus;
          bus.emit("updateStatus", modelStatus.value);

          for (let mIndex in cellList) {
            let cell = cellList[mIndex];
            let updateData = cell;
            let updateId = cell.moduleIndex;
            let updateNode = graph.getCellById(updateId);
            updateNode.setData({ ...updateData }, { deep: true });
          }

          // cellList.forEach((cell) => {
          //   if (cell.shape != "edge") {
          //   }
          // });

          // setTimeout(() => {
          // graph.fromJSON({ cells: res.data.cellList });

          // graph.fromJSON(res.data.modelMap);
          // graph.fromJSON(data);
          // }, 0);
        }
      });
    }
    function isGraphValid() {
      let nodes = graph.getNodes();
      let isGraphValid = true;
      nodes.forEach((node) => {
        let data = node.getData();
        if (!data.isValid) {
          // debugger;
          isGraphValid = false;
        }
      });
      return isGraphValid;
    }
    function changeIfPersistent(val, index, singleConfig) {
      configTemplateRef.value[index].changeIfPersistent(val);

      // console.log("change if persistent", singleConfig, val);
      let id = singleConfig.node.id;
      let node = graph.getCellById(id);
      node.setData({ ifPersistent: val });
    }

    return {
      props,
      moduleKeyword,
      ...toRefs(moduleListState),
      configAndReviewRef,
      saveGraphData,
      clearGraphData,
      runModel,
      dialogState,
      clickApplyConfigs,
      saveInModuleNode,
      ifDisabled,
      isChanged,
      setIsChange,
      modelStatus,
      terminateModel,
      viewTab,
      changeModelView,
      ...toRefs(configViewState),
      saveInModuleNodeAtOnce,
      configTemplateRef,
      saveGraphDataOnConfigView,
      runModelOnConfigView,
      changeIfPersistent,
      openModuleGroup,
      startDrag,
      restoreGraphData,
      ifSystem
    };
  }
};
</script>

<style lang="scss" scoped>
#analysisDesign {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  overflow: auto;
  // min-width: 1100px;

  .module-collapse {
    width: 280px;
    height: 744px;
    padding: 12px 20px;
    border: 1px solid #dcdfe6;
    border-right: 0px;
    overflow: scroll;

    .title {
      margin-bottom: 15px;
    }
    .search-bar {
      margin-bottom: 15px;
    }
    .module-group {
      // margin-bottom: 15px;
      user-select: none;
      .module-group-title {
        margin-bottom: 15px;
        cursor: pointer;
        span {
          vertical-align: middle;
        }
        color: #6c6c6c;
        .arrow-icon {
          position: relative;
          top: 2px;
        }
      }
      .module-tag {
        cursor: move;
        height: 32px;
        margin-bottom: 15px;
        display: flex;
        width: 100%;
        // padding:0 5px 0 6px;
        padding-left: 6px;
        border-radius: 4px;
        // margin-right: 15px;
        border: 1px solid rgba(206, 206, 206, 0.75);
        // justify-content: space-between;
        align-items: center;
        font-size: 14px;
        .icon-module {
          display: block;
          width: 16px;
          margin-right: 6px;
        }
      }
    }

    .icon-type {
      color: #bd3124;
      font-size: 24px;
      margin: 0px 10px 0px 0px;
    }
    // .icon-module {
    //   margin: 15px;
    // }
    // .module-type-title {
    //   font-size: 15px;
    // }
    // .module-title {
    //   margin-top: 8px;
    // }
  }
  .design-view {
    border: #dcdfe6 solid 1px;
    // margin-left: 5px;
    width: calc(100% - 280px);

    .module-graph-container {
      // border: #b9b5b5 solid 1px;
      margin-left: 5px;
      width: 100%;
    }
    .top-bar {
      text-align: right;
      height: 42px;
      line-height: 42px;
      // border: #b9b5b5 solid 1px;
      border-bottom: #dcdfe6 solid 1px;
      // margin-left: 5px;
      .button {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }
  .dialog {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  .graph-scroller {
    // color: red;
  }
}
#analysisConfig {
  display: flex;
  margin-top: 30px;
  border: #dcdfe6 solid 1px;
  .moduleNav {
    width: 220px;
    border-right: #dcdfe6 solid 1px;
    .title {
      height: 42px;
      line-height: 40px;
      text-align: center;
      font-size: 15px;
      background-color: #efefef;
      border-bottom: #dcdfe6 solid 1px;
    }
    .content {
      li {
        text-align: left;
        line-height: 20px;
        font-size: 14px;
        position: relative;
        left: 25px;
        margin: 20px 0;
        .icon-type {
          margin: 0px 18px 0px 10px;
        }
        span {
          color: #666666;
        }
      }
    }
  }
  .moduleConfigList {
    flex: 1;
    .top-bar {
      text-align: right;
      height: 42px;
      line-height: 42px;
      // border: #b9b5b5 solid 1px;
      border-bottom: #dcdfe6 solid 1px;
      // margin-left: 5px;
      .button {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: auto;
        margin-bottom: auto;
      }
    }
    .config {
      margin: 24px 36px;
      .el-switch {
        --el-switch-on-color: #13ce66;
      }
      .config-body {
        background: #f9f9f9;
        // margin: 0px 36px;
        padding: 24px 0px 12px;
      }
      .config-bar {
        line-height: 24px;
        display: flex;
        justify-content: space-between;
        margin: 0 0px 12px;

        .label {
          font-size: 16px;
          color: #333333;
        }
        .swith {
          font-size: 14px;
          color: #666666;
        }
      }
    }
  }
}
</style>
