<template>
  <div ref="refPieChart"></div>
</template>

<script>
import { onMounted, toRefs, reactive, ref, nextTick } from "vue";
import { Chart, Util } from "@antv/g2";
import { toDataURL, downloadImage } from "@/utils/exportG2";

export default {
  name: "PieChart",
  props: {
    chartInfo: {
      type: Object
    }
  },
  setup(props) {
    // setTimeout(() => {
    //   console.log("propskyy", props);
    // }, 8000);
    //数据
    let refPieChart = ref(null);
    // let state = reactive({
    //   chart: null
    // });
    let chart;
    //初始化图形
    function initChart() {
      // 获取数据
      const data = props.chartInfo.data;

      chart = new Chart({
        container: refPieChart.value,
        autoFit: true,
        height: 350
      });

      chart.data(data);

      chart.coordinate("theta", {
        radius: 0.75
      });

      chart.tooltip({
        showMarkers: false
      });

      chart
        .interval()
        .adjust("stack")
        .position("yAxisValue")
        .color("xAxisValue")
        .style({ opacity: 0.4 })
        .state({
          active: {
            style: (element) => {
              const shape = element.shape;
              return {
                matrix: Util.zoom(shape, 1.1)
              };
            }
          }
        })
        .label("xAxisValue", (val) => {
          const opacity = val === "四线及以下" ? 1 : 0.5;
          // console.log("label", val);
          return {
            // offset: -30,
            // style: {
            //   opacity,
            //   fill: "white",
            //   fontSize: 12,
            //   shadowBlur: 2,
            //   shadowColor: "rgba(0, 0, 0, .45)"
            // },
            layout: { type: "pie-spider" },
            labelHeight: 500,
            offset: 20,
            labelLine: {
              style: {
                lineWidth: 1
              }
            },
            content: (obj) => {
              return obj.xAxisValue + "\n" + obj.yAxisValue + "";
            }
          };
        });

      chart.interaction("element-single-selected");
      chart.render();
    }
    //更新数据
    function resizeChart() {
      const data = props.chartInfo.data;
      chart.changeData(data);
    }
    function exportResult(type) {
      switch (type) {
        case "PNG":
          downloadImage(chart, props.chartInfo.title);

          break;

        default:
          break;
      }
    }
    onMounted(() => {
      nextTick(() => {
        initChart();
      });
    });
    return {
      refPieChart,

      initChart,
      resizeChart,
      exportResult
    };
  }
};
</script>

<style lang="scss" scoped></style>
