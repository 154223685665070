<template>
  <el-table
    :cell-style="{ tableRowStyle }"
    :header-cell-style="{ tableHeaderColor }"
    :data="contactTopicList"
  >
    <el-table-column fixed="left" label="" width="200">
      <template v-slot="scope">
        <el-button @click="analysisResultList(scope.$index, 1)" type="text">
          {{ scope.row.topic }}
        </el-button>
      </template>
    </el-table-column>
    <el-table-column fixed="right" label="" width="750">
      <template v-slot="scope">
        <span class="removeBorder">
          <el-table
            :data="scope.row.topicContact"
            :show-header="false"
            :cell-style="{ tableRowStyle }"
            :row-style="{ tableRowStyle2 }"
          >
            <el-table-column prop="contact1"></el-table-column>
            <el-table-column prop="contact2"></el-table-column>
            <el-table-column prop="contact3"></el-table-column>
            <el-table-column prop="contact4"></el-table-column>
            <el-table-column prop="contact5"></el-table-column>
          </el-table>
        </span>
      </template>
    </el-table-column>
  </el-table>
  <!--分页-->
  <el-pagination
    @current-change="toChangePage"
    :value="tableData.currentPageNumber"
    :page-size="pageSize"
    layout="total, prev, pager, next"
    style="margin-top: 10px"
    :total="tableData.totalNumber"
  ></el-pagination>
</template>

<script>
import { ref, reactive, onMounted } from "vue";
import { httpPost } from "@/api/httpService";

export default {
  props: {
    tableData: Object,
    modelInfo: Object
  },
  setup(props) {
    // console.log("topic table list", props);

    // 获取表格内容
    let tableContent = reactive(props.tableData.tableContent);
    let tableTitles = reactive(props.tableData.tableTitles);
    const contactTopicList = ref([]);
    let pageSize = ref(10);
    function tableRowStyle({ row, rowIndex }) {
      return "{text-align: center;}";
    }
    function tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color: #f5f7f9; font-weight: bold; text-align: center;";
      }
    }
    function analysisResultList(index, page) {
      // console.log("analysis result list", index, page);
    }
    function tableRowStyle2({ row, rowIndex }) {
      return "text-align: center;height: 20px;border-color: white !important";
    }
    function setTableData(currentPage) {
      let topicsList = tableContent;
      let tableTitlesLeft = tableTitles;
      let page = currentPage;
      let size = pageSize.value;
      let resultList = [];

      let num = (page - 1) * size + 1;
      for (let i = 0; i < topicsList.length; i++) {
        if (topicsList[i] != null) {
          let item = {};
          item.id = num;
          // debugger;
          item.topic = tableTitlesLeft[num + i - 1];
          item.topicContact = [];
          let keys = Object.keys(topicsList[i]);
          for (let k = 0; k < keys.length; k = k + 5) {
            let itemWord = {};
            if (keys[k] != undefined)
              itemWord.contact1 =
                [keys[k]] + "(" + topicsList[i][keys[k]].toFixed(4) + ")";
            if (keys[k + 1] != undefined)
              itemWord.contact2 =
                [keys[k + 1]] +
                "(" +
                topicsList[i][keys[k + 1]].toFixed(4) +
                ")";
            if (keys[k + 2] != undefined)
              itemWord.contact3 =
                [keys[k + 2]] +
                "(" +
                topicsList[i][keys[k + 2]].toFixed(4) +
                ")";
            if (keys[k + 3] != undefined)
              itemWord.contact4 =
                [keys[k + 3]] +
                "(" +
                topicsList[i][keys[k + 3]].toFixed(4) +
                ")";
            if (keys[k + 4] != undefined)
              itemWord.contact5 =
                [keys[k + 4]] +
                "(" +
                topicsList[i][keys[k + 4]].toFixed(4) +
                ")";
            item.topicContact.push(itemWord);
          }
          resultList.push(item);
        }
      }
      contactTopicList.value = resultList;
      // console.log("contactTopicList", contactTopicList);
    }
    function toChangePage(currentPage) {
      // console.log("tochangepage", currentPage);
      const postData = {
        analysisId: props.modelInfo.analysisId,
        moduleIndex: props.tableData.moduleIndex,
        pageNum: currentPage,
        pageSize: pageSize.value,
        tableType: props.tableData.tableType
      };
      httpPost("/module/get/result", postData).then((res) => {
        tableContent = reactive(res.data.result.tableContentList);

        setTableData(currentPage);
      });
    }
    onMounted(() => {
      setTableData(1);
    });
    return {
      tableRowStyle,
      tableHeaderColor,
      analysisResultList,
      tableRowStyle2,
      toChangePage,
      contactTopicList,
      pageSize
    };
  }
};
</script>

<style></style>
