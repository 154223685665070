<!-- 分析模型界面，结果切换 -->
<template>
  <div class="component-tab">
    <div class="top-filter">
      <!-- 左侧tab切换栏 -->
      <div class="item-left">
        <el-radio-group
          v-model="tabInfo.tab"
          size="small"
          text-color="white"
          fill="#e94743"
          @change="changeLeftTab"
        >
          <el-radio-button label="0">
            <span class="iconfont icon-control"></span>
            分析模型
          </el-radio-button>
          <el-radio-button label="1">
            <span class="iconfont icon-shuzhuangtu"></span>
            分析结果
          </el-radio-button>
          <el-radio-button label="2">
            <span class="iconfont icon-time"></span>
            分析日志
          </el-radio-button>
        </el-radio-group>
      </div>
      <!-- 右侧功能区 ------begin-------->
      <!-- 分析模型界面切换视图 -->
      <div class="item-right" v-if="tabInfo.tab == 0">
        <el-radio-group
          v-model="tabInfo.modelView"
          size="small"
          text-color="white"
          fill="#e94743"
          @change="changeModelView"
        >
          <el-radio-button label="0">
            <span class="iconfont icon-editor"></span>
            配置视图
          </el-radio-button>
          <el-radio-button label="1">
            <span class="iconfont icon-a-structure7-line"></span>
            设计视图
          </el-radio-button>
        </el-radio-group>
      </div>
      <!-- 分析结果功能按钮 -->
      <!-- <div class="item-right" v-if="tabInfo.tab == 1">
        <el-button type="text" size="small" @click="downloadResult">
          <span class="iconfont icon-xiazai"></span>
          下载
        </el-button>
        <el-button type="text" size="small" @click="shareResult">
          <span class="iconfont icon-fenxiang"></span>
          分享
        </el-button>
      </div> -->
      <!-- 右侧功能区 ------end-------->

      <!-- <analysisModuleCollapse></analysisModuleCollapse> -->
    </div>
    <!-- 右侧视图 ---------begin -->
    <div>
      <analysis-model-design
        :modelInfo="props.modelInfo"
        ref="modelDesignView"
        v-if="tabInfo.tab == 0"
      ></analysis-model-design>
      <analysis-result
        :key="analysisResultKey"
        :modelInfo="props.modelInfo"
        v-if="tabInfo.tab == 1"
      ></analysis-result>
      <analysis-log
        :modelInfo="props.modelInfo"
        v-if="tabInfo.tab == 2"
      ></analysis-log>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  reactive,
  toRefs,
  getCurrentInstance,
  watch,
  defineProps
} from "vue";
// import AnalysisModelDesign from "./AnalysisModelDesign.vue";
import analysisModelDesign from "./AnalysisModelDesign";
import analysisResult from "./AnalysisResult";
import AnalysisLog from "./AnalysisLog.vue";
import { useRoute } from "vue-router";

// export default {
//   name: "componentTab",
//   components: {
//     analysisModelDesign,
//     analysisResult,
//     AnalysisLog
//   },
const props = defineProps(["modelInfo"]);
const route = useRoute();
// setup(props) {
// console.log("component tab:modelinfo", props.modelInfo);
// 管理tab信息,tab为左侧栏，modelView为右侧
const tabInfo = reactive({
  tab: ["0", "1", "2"].includes(route.query.type) ? route.query.type : 0,
  modelView: 1
});
//父级调用更改Tab
function setTab(tab) {
  tabInfo.tab = tab;
}
const modelDesignView = ref(null);
const test = reactive({
  attr1: "1",
  attr2: "2"
});
let analysisResultKey = ref(0);
watch(tabInfo, (val) => {});
// tab=1时分析结果页面，顶部右侧的功能-----------start---------------
// 下载分析结果
function downloadResult() {}
// 分享分析结果
function shareResult() {}
// tab=1时分析结果页面，顶部右侧的功能-----------end---------------
// 点击按钮对模型进行保存
function clickSave() {
  // console.log("save graph data");
  modelDesignView.value.saveGraphData();
}
function changeLeftTab(tab) {
  // console.log("change left tab", tab);

  if (tab == 0) {
    // console.log("change left tab", tab);

    tabInfo.modelView = 1;
  }
}
// 切换配置视图/设计视图
function changeModelView(viewTab) {
  // console.log("change model view", viewTab);
  modelDesignView.value.changeModelView(viewTab);
}

// 切换tab时resize结果视图
function resizeContainer() {
  if (tabInfo.tab == 1) {
    analysisResultKey.value++;
  }
}

// return {
//   tabInfo,
//   setTab,
//   props,
//   modelDesignView,
//   downloadResult,
//   shareResult,
//   clickSave,
//   test,
//   changeLeftTab,
//   changeModelView,
//   resizeContainer,
//   analysisResultKey
// };
// }
// };
</script>

<style></style>
<style lang="scss" scoped>
.component-tab {
}
.top-filter {
  display: flex;

  .item-left {
    flex-grow: 1;
    .mr1 {
      margin-right: 15px;
    }
    .mr {
      margin-right: 8px;
    }
  }
  item-right {
    justify-content: flex-end;
  }
}
</style>
