<template>
  <div ref="refBarChart"></div>
</template>

<script>
import { onMounted, toRefs, reactive, ref, nextTick } from "vue";
import { Chart } from "@antv/g2";
import { toDataURL, downloadImage } from "@/utils/exportG2";

export default {
  name: "BarChart",
  props: {
    chartInfo: {
      type: Object
    }
  },
  setup(props) {
    // setTimeout(() => {
    //   console.log("propskyy", props);
    // }, 8000);
    //数据
    let refBarChart = ref(null);
    let state = reactive({
      chart: null
    });
    //初始化图形
    function initChart() {
      // 获取数据
      const data = props.chartInfo.data;

      state.chart = new Chart({
        container: refBarChart.value,
        autoFit: true,
        height: 350,
        padding: [50, 20, 50, 20]
      });

      state.chart.data(data);

      state.chart.tooltip({
        showMarkers: false,
        shared: true
      });

      state.chart.interaction("active-region");

      state.chart.axis("xAxisValue", false);

      state.chart
        .interval()
        .position("xAxisValue*yAxisValue")
        .color(props.chartInfo.color)
        .adjust(props.chartInfo.adjust);

      // 柱状图or条状图
      state.chart.coordinate({
        actions: props.chartInfo.coordinate.actions
      });

      // 添加文本标注
      data.forEach((item) => {
        console.log(item);
        state.chart.annotation().text({
          position: [item.xAxisValue, item.yAxisValue],
          content: item.yAxisValue,
          style: {
            textAlign: "center"
          },
          offsetY: -12
        });
      });

      state.chart.render();
    }
    //更新数据
    function resizeChart() {
      const data = props.chartInfo.data;
      state.chart.changeData(data);
    }
    onMounted(() => {
      nextTick(() => {
        initChart();
      });
    });
    // 导出
    function exportResult(type) {
      // console.log(props.chartInfo);
      // let dataUrl = toDataURL(chart);
      switch (type) {
        case "PNG":
          downloadImage(state.chart, props.chartInfo.title);

          break;

        default:
          break;
      }
      // console.log("export wordcloud", dataUrl);
    }
    return {
      refBarChart,
      ...toRefs(state),
      initChart,
      resizeChart,
      exportResult
    };
  }
};
</script>

<style lang="scss" scoped></style>
